@charset "utf-8";

.Write_MissionPage {

    span.explain {
        color: #ed3d3d;
        margin-top: 10px;
    }


    .comm_write_wrap .cont-wrap .cont-box {
        min-height: 50px;
        align-items: center;
    }

    .comm_write_wrap .cont-box .select-box {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .comm_write_wrap .cont-wrap input[type="text"].figure {
        width: calc(100% - 100px) !important;
        margin-right: 5px !important;
    }

    .comm_write_wrap .cont-wrap input[type="text"].readonly {
        background-color: #f5f5f5 !important;
    }

    .village_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 15px;
    }

    .village_list li label {
        display: flex;
        align-items: center;
    }

    .village_list li label input[type=radio] {
        width: 25px;
        height: 25px;
        padding: 0;
        margin-right: 5px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #e8e8e8;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;
    }

    .village_list li label input[type=radio]:checked {
        border: 8px solid #258cef;
    }

    .village_list li label p {
        color: #666;
        font-size: 0.9rem;
    }

    .village_list li label input[type=radio]:checked~p {
        color: #000;
    }



    /* 미디어쿼리 600 */
    @media (max-width: 600px) {

        .comm_write_wrap .cont-wrap .cont-box {
            min-height: inherit;
        }

    }
}