@charset "utf-8";

.AddMissionDialog {

	.comm_popup .comm_popup_wrap {
		max-width: 800px;
	}

	/* title */
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all 0.2s;
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	/* energy_table */
	.energy_table {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.energy_table .thead {
		width: 100%;
		display: flex;
		align-items: center;
		border-top: 2px solid #555;
		border-bottom: 1px solid #dedede;
	}

	.energy_table .thead li {
		float: left;
		position: relative;
		text-align: center;
		color: #666;
		padding-left: 10px;
		padding-right: 10px;
	}

	.energy_table .tbody {
		width: 100%;
		display: inline-block;
	}

	.energy_table .tbody li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		border-bottom: 1px solid #dedede;
	}

	.energy_table .tbody li p {
		position: relative;
		color: #000;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
	}

	.energy_table .tbody li p.title {
		font-size: 1.15rem;
		text-align: start;
	}

	.energy_table .tbody li label {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.energy_table .tbody li label input[type='checkbox'] {
		width: 25px;
		height: 25px;
		padding: 0;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #e8e8e8;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s;
	}

	.energy_table .tbody li label input[type='checkbox']:checked {
		background: url(../../assets/ic_check.png) 50% 50% no-repeat;
		background-size: 20px;
		border-color: #258cef;
		background-color: #258cef;
	}

	.energy_table .tbody li label p {
		color: #666666;
	}

	.energy_table .tbody li label input[type='checkbox']:checked~p {
		color: #000;
	}

	/* energy_table set */
	.energy_table .thead li:first-of-type,
	.energy_table .tbody li p:first-of-type {
		width: 15%;
	}

	.energy_table .thead li:nth-of-type(2),
	.energy_table .tbody li p:nth-of-type(2) {
		width: 35%;
	}

	.energy_table .thead li:nth-of-type(3),
	.energy_table .tbody li p:nth-of-type(3) {
		width: 23%;
	}

	.energy_table .thead li:nth-of-type(4),
	.energy_table .tbody li p:nth-of-type(4) {
		width: 27%;
	}

	// 미디어쿼리 ~1024 시작
	@media (max-width: 1024px) {
		.comm_popup .comm_popup_wrap {
			width: 90%;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media (max-width: 600px) {
		.comm_write_wrap .cont-wrap .btn_address {
			width: 80px;
			height: 40px;
		}

		.comm_write_wrap .cont-wrap input.address {
			width: calc(100% - 90px);
		}

		.energy_table .thead li {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
	// 미디어쿼리 ~600 끝
}