@charset "utf-8";
.MissionYardPage {
	#content{
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	/* energy_box */
	.energy_box {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
	}
	.energy_box .energy_activit {
		display: flex;
		align-items: center;
	}
	.energy_box .energy_activit2 {
		float: left;
	}

	/*energy_box2 */
	.energy_box2 {
		width: 1000px;
		margin: 0 auto;
		background-color: #fafafa;
		border-radius: 15px;
	}
	.energy_box2 .energy_title2 {
		font-size: 1.15rem;
		display: flex;
		align-items: center;
	}
	.energy_box2 .energy_title2 h2 {
		font-weight: 500;
	}
	.energy_box2 .energy_title2 .mypage_btn {
		margin-left: auto;
		text-align: center;
	}
	.energy_box2 .energy_title2 .mypage_btn button {
		width: 100px;
		height: 30px;
		padding: 5px 10px;
		border: 1px solid #1d5d9b;
		border-radius: 5px;
		color: #1d5d9b;
		background-color: #fff;
	}
	.energy_box2 .character_box {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.energy_box2 .character_box .mypoint {
		margin-left: 30px;
	}
	.energy_box2 .character_box .mypoint h2 {
		position: relative;
		font-size: 1rem;
		color: #666;
		line-height: 1.5;
	}
	.energy_box2 .character_box .mypoint h2 span {
		font-size: 1.15rem;
		font-weight: 500;
		padding-left: 20px;
	}
	.energy_box2 .character_box .mypoint h2::after {
		content: '';
		display: inline-block;
		width: 25px;
		height: 25px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-bottom: -4px;
		margin-left: 5px;
	}
	.energy_box2 .character_box .mypoint h3 {
		position: relative;
		font-size: 1rem;
		color: #666;
		line-height: 1.5;
	}
	.energy_box2 .character_box .mypoint h3 span {
		padding-left: 20px;
		color: #666;
	}
	.energy_box2 .character_box .mypoint h3::after {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-bottom: -4px;
		margin-left: 5px;
	}
	.energy_box2 .character_box .character img {
		width: 90px;
		height: 90px;
	}

	/* energy_box circle */
	.energy_box .energy_activit1 {
		float: left;
		display: flex;
	}
	.energy_box .energy_activit2 .circle {
		position: relative;
		display: flex;
		border-radius: 50%;
		border: 1px solid #1d5d9b;
		width: 160px;
		height: 160px;
		align-items: center;
		justify-content: center;
	}
	.energy_box .energy_activit2 .circle::before {
		content: '';
		display: flex;
		position: absolute;
		border-radius: 50%;
		border: 1px solid #ccc;
		width: 150px;
		height: 150px;
	}
	.energy_box .energy_activit2 .circle .circle_txt {
		text-align: center;
	}

	/* energy_box circle_txt*/
	.energy_box .energy_activit2 .circle .circle_txt.blue h2,
	.circle_txt.blue p {
		color: #258cef;
		font-size: 1.15rem;
		font-weight: 500;
	}
	.energy_box .energy_activit2 .circle .circle_txt.blue span {
		color: #258cef;
		font-weight: normal;
	}
	.energy_box .energy_activit2 .circle .circle_txt.blue h1,
	.circle_txt.green h1,
	.circle_txt.red h1 {
		margin-top: 10px;
	}
	.energy_box .energy_activit2 .circle .circle_txt.green h2,
	.circle_txt.green p {
		color: #30a966;
		font-size: 1.15rem;
		font-weight: 500;
	}
	.energy_box .energy_activit2 .circle .circle_txt.green span {
		color: #30a966;
		font-weight: normal;
	}
	.energy_box .energy_activit2 .circle .circle_txt.red p {
		color: #ed3d3d;
		font-size: 1.15rem;
		font-weight: 500;
	}
	.energy_box .energy_activit2 .circle .circle_txt.red span {
		color: #ed3d3d;
		font-weight: normal;
	}

	.energy_box .ic_plus {
		width: 50px;
		display: flex;
		justify-content: center;
		float: left;
		position: relative;
	}
	.energy_box .ic_plus img {
		width: 30px;
		height: 30px;
		position: relative;
		transform: translateY(200%);
	}
	.energy_box .ic_plus1 {
		width: 50px;
		display: flex;
		justify-content: center;
		float: left;
		position: relative;
	}
	.energy_box .ic_plus1 img {
		width: 30px;
		height: 30px;
		position: relative;
		transform: translateY(200%);
	}

	/* energy_activit select_box */
	.energy_activit .select_box {
		width: 50%;
	}
	.energy_activit .select_box select {
		width: 90%;
		height: 40px;
		box-sizing: border-box;
		line-height: 50px;
		color: #888;
		border: 1px solid #eee;
		border-radius: 5px;
		background-color: #f5f5f5;
		font-size: inherit;
	}

	/* energy_activit energy_title */
	.energy_activit .energy_title {
		width: 50%;
	}
	.energy_activit .energy_title h2 {
		font-weight: 500;
		font-size: 1.15rem;
		padding-bottom: 8px;
	}
	.energy_activit .energy_title p {
		color: #888;
		font-size: 0.8rem;
		line-height: 1.5;
	}

	/* table_inner */
	.table_inner {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;
	}
	.table_inner .thead {
		width: 100%;
		display: flex;
		align-items: center;
		border-top: 2px solid #555;
		border-bottom: 1px solid #dedede;
	}
	.table_inner .thead li {
		float: left;
		position: relative;
		text-align: center;
		color: #666;
		padding-left: 10px;
		padding-right: 10px;
	}

	.table_inner .tbody {
		width: 100%;
		display: inline-block;
	}
	.table_inner .tbody li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		border-bottom: 1px solid #dedede;
	}
	.table_inner .tbody li p {
		position: relative;
		color: #000;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.15rem;
		font-weight: 600;
	}
	.table_inner .tbody li p.red,
	.table_inner .tbody li p.red span {
		color: #ed3d3d;
	}
	.table_inner .tbody li p.blue,
	.table_inner .tbody li p.blue span {
		color: #1d5d9b;
	}
	.table_inner .tbody li p.green,
	.table_inner .tbody li p.green span {
		color: #30a966;
	}

	.table_inner .tbody li p span {
		font-weight: normal;
	}

	.table_inner .thead li:first-of-type,
	.table_inner .tbody li p:first-of-type {
		width: calc(100% / 3);
	}
	.table_inner .thead li:nth-of-type(2),
	.table_inner .tbody li p:nth-of-type(2) {
		width: calc(100% / 3);
	}
	.table_inner .thead li:nth-of-type(3),
	.table_inner .tbody li p:nth-of-type(3) {
		width: calc(100% / 3);
	}

	/* mission_list_wrap */
	.mission_list_wrap {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
		gap: 60px;
	}
	.mission_list_box {
		width: calc(100% / 2);
	}

	.mission_cont_title,
	.mission_cont_content {
		position: relative;
		width: 100%;
	}

	/* mission_cont_title */
	.mission_cont_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mission_cont_title h3::before {
		content: '';
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #258cef;
		margin-right: 5px;
	}
	.mission_cont_title h3 span {
		font-size: 1.25rem;
		font-weight: 600;
		color: #258cef;
	}

	/* mission_cont_set */
	.mission_cont_set {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mission_cont_set ul {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.mission_cont_set ul li {
		position: relative;
		cursor: pointer;
	}
	.mission_cont_set ul li:not(:last-child)::after {
		content: '';
		position: absolute;
		top: 50%;
		right: -10px;
		transform: translateY(-50%);
		width: 1px;
		height: 14px;
		border-right: 1px dashed #e5e5e5;
	}
	.mission_cont_set ul li p.on {
		font-weight: 600;
	}

	.mission_cont_set select {
		width: 160px;
		height: 40px;
		box-sizing: border-box;
		line-height: 40px;
		color: #000;
		border: 1px solid #e8e8e8;
		resize: none;
		padding: 0 30px 0 15px;
		border-radius: 5px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		background: url(../../assets/ic_select.png) right 8px center no-repeat;
	}

	/* mission_cont_content */
	.mission_cont_content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.mission_cont_content > p {
		font-size: 1.15rem;
	}
	.mission_cont_content > img {
		width: 50%;
	}
	.mission_cont_content > a {
		font-size: 1.15rem;
		text-decoration: underline;
		color: #1d5d9b;
	}
	.mission_cont_content span.update {
		color: #666;
		width: 100%;
		display: block;
		text-align: end;
	}

	/* ranking_list */
	.ranking_list {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.ranking_list p {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.ranking_list p span.num {
		font-size: 1.4rem;
		font-weight: 600;
		color: #1d5d9b;
		width: 40px;
		display: flex;
		justify-content: center;
	}
	.ranking_list p span.villname {
		font-size: 1.15rem;
		font-weight: 500;
		flex: 1 1 auto;
		padding: 0 20px;
	}
	.ranking_list p span.villname strong {
		font-size: 1rem;
		color: #666666;
		font-weight: 400;
	}
	.ranking_list p span.point {
		position: relative;
		font-size: 1.15rem;
		display: flex;
		align-items: center;
	}
	.ranking_list p span.point::before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-right: 5px;
	}

	.ranking_list p span.nickname {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.15rem;
		font-weight: 500;
		flex: 1 1 auto;
		padding: 0 20px;
	}
	.ranking_list p span.nickname::before {
		content: '';
		display: inline-block;
		width: 30px;
		height: 30px;
		margin-right: 7px;
	}
	.ranking_list p span.nickname.basic1::before {
		content: '';
		background: url(../../assets/character01.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.basic2::before {
		content: '';
		background: url(../../assets/character02.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv1::before {
		content: '';
		background: url(../../assets/character03.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv2::before {
		content: '';
		background: url(../../assets/character04.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv3::before {
		content: '';
		background: url(../../assets/character05.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv4::before {
		content: '';
		background: url(../../assets/character06.png) 50% 50% no-repeat;
		background-size: cover;
	}

	// 미디어쿼리 ~1140 시작
	@media screen and (max-width: 1140px){
		.energy_box{
			flex-direction: column;
		}
	}
	// 미디어쿼리 ~1140 끝

	// 미디어쿼리 ~1080 시작
	@media screen and (max-width: 1080px) {
		.energy_box,
		.energy_box2 {
			width: 100%;
		}

		.energy_box .energy_activit1 {
			width: auto;
			margin-top: 25px;
		}

		.energy_box .energy_activit {
			width: auto;
			margin: 0;
		}
	}
	// 미디어쿼리 ~1080 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		.energy_box {
			flex-direction: column;
		}

		/* mission_list_wrap */
		.mission_list_wrap {
			flex-direction: column;
		}
		.mission_list_box {
			width: 100%;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~670 시작
	@media screen and (max-width: 670px) {
		.energy_box{
			display: flex;
			flex-direction: column;
			gap: 5px;
			position: relative;
			width: 100%;
		}
		.energy_box .energy_activit {
			width: 100%;
			margin: 0;
			flex-direction: column-reverse;
		}

		.energy_box .energy_activit1,
		.energy_activit .energy_title {
			width: 100%;
		}

		.energy_box .energy_activit1 {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.energy_box .ic_plus1 {
			margin-left: -188px;
		}

		.energy_box .energy_activit2 .circle {
			width: 130px;
			height: 130px;
		}

		.energy_box .energy_activit2 .circle::before {
			width: 120px;
			height: 120px;
		}

		.energy_box .energy_activit .select_box {
			width: 100%;
			margin-top: 10px;
		}

		.energy_box .energy_activit .select_box select {
			width: 100%;
		}

		.energy_box2 .character_box {
			flex-direction: column;
		}

		/* mission_list_wrap */
		.mission_list_wrap {
			gap: clamp(25px, 5vw, 100px);
		}

		/* ranking_list */
		.ranking_list {
			gap: 15px;
		}
		.ranking_list p span.villname {
			display: flex;
			flex-direction: column;
		}
		.ranking_list p span.nickname {
			padding: 0 10px;
		}

		.energy_box2 .character_box .mypoint {
			margin-left: 0;
		}
		.energy_box .ic_plus1 img {
			transform: translateY(272%);
		}
	}
	// 미디어쿼리 ~670 끝
}
