@charset "utf-8";
.List_MissionPage {
	/* 공통변경사항 */
	.comm_tool p {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.comm_tool p::after {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-left: 5px;
	}
	.comm_tool p span {
		color: #258cef;
		font-weight: 600;
	}

	.box_comm_write_button {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 10px;
		margin-left: auto;
	}
	.box_comm_write_button .comm_write_button.border {
		border: 1px solid #1d5d9b;
		background-color: #fff;
		color: #1d5d9b;
	}

	/* mission_basic_list */
	.mission_basic_list {
		position: relative;
		width: 100%;
	}
	.mission_basic_list ul {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		margin-right: -30px;
	}
	.mission_basic_list ul li {
		width: calc(100% / 3 - 30px);
	}

	.mission_basic_list ul li a {
		display: inline-block;
		width: 100%;
		border: 1px solid #e8e8e8;
		border-radius: 15px;
	}

	/* box_missionBasic */
	.box_missionBasic {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
	}

	.box_missionBasic .missionBasic_txt {
		width: calc(100% - 105px);
	}

	.box_missionBasic .missionBasic_txt p {
		position: relative;
		color: #666;
		margin-top: 3px;
	}
	.box_missionBasic .missionBasic_txt p.point {
		display: flex;
		align-items: center;
		color: #000;
	}
	.box_missionBasic .missionBasic_txt p.point::before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-right: 5px;
	}

	.box_missionBasic .missionBasic_txt .state {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
	}
	.box_missionBasic .missionBasic_txt .state span {
		display: inline-block;
		padding: 5px 10px;
		border-radius: 5px;
		border: 1px solid #666;
		color: #666;
		background-color: #fff;
	}
	.box_missionBasic .missionBasic_txt .state span.ing {
		border-color: #ed3d3d;
		color: #ed3d3d;
	}

	.box_missionBasic .missionBasic_txt .state span.state_number {
		border-color: #258cef;
		background-color: #258cef;
		color: #fff;
	}

	.box_missionBasic .missionBasic_img {
		position: relative;
		width: 80px;
		height: 80px;
		overflow: hidden;
		border-radius: 5px;
		margin-left: 25px;
	}
	.box_missionBasic .missionBasic_img img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		min-height: 100%;
	}

	/* noimg */
	.box_missionBasic.noimg .missionBasic_txt {
		width: 100% !important;
	}
	.box_missionBasic.noimg .missionBasic_img {
		display: none !important;
	}

	/* non */
	.non .box_missionBasic .missionBasic_txt h4,
	.non .box_missionBasic .missionBasic_txt p,
	.non .box_missionBasic .missionBasic_txt p span {
		color: #666;
	}
	.non .box_missionBasic .missionBasic_txt h4::before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url(../../assets/ic_non.png) 50% 50% no-repeat;
		margin-bottom: -3px;
		margin-right: 5px;
	}

	// 미디어쿼리 ~1280 시작
	@media screen and (max-width: 1280px) {
		/* mission_basic_list */
		.mission_basic_list ul {
			gap: 20px;
			margin-right: -20px;
		}
		.mission_basic_list ul li {
			width: calc(100% / 2 - 20px);
		}
	}
	// 미디어쿼리 ~1280 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		/* mission_basic_list */
		.mission_basic_list ul {
			gap: 0px;
			margin-right: -0px;
		}
		.mission_basic_list ul li {
			width: 100%;
		}
		.mission_basic_list ul li:not(:last-child) {
			margin-bottom: 25px;
		}

		/* comm_tool */
		.comm_tool {
			flex-wrap: wrap;
			gap: 15px;
		}

		#content {
			padding-top: 40px !important;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		#content {
			padding-top: clamp(30px, 5vw, 100px) !important;
		}
	}
	// 미디어쿼리 ~600 끝
}
