@charset "utf-8";

.Main_ChallengePage {
	/* challenge_info_wrap */
	.challenge_info_wrap {
		position: relative;
		display: flex;
		align-items: center;
		gap: 40px;
		justify-content: center;
	}

	.challenge_info_wrap .info_content {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	.challenge_info_wrap .info_content h3 {
		font-size: 1.25rem;
		font-weight: 600;
	}
	.challenge_info_wrap .info_content p {
		color: #666;
	}
	.challenge_info_wrap .info_content button {
		display: inline-block;
		background-color: #1d5d9b;
		height: 50px;
		padding: 0 20px;
		border-radius: 5px;
		font-size: 1.25rem;
		color: #fff;
	}

	/* myList_title */
	.myList_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.myList_title ul {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.myList_title ul li {
		position: relative;
		cursor: pointer;
	}
	.myList_title ul li:not(:last-child)::after {
		content: '';
		position: absolute;
		top: 50%;
		right: -10px;
		transform: translateY(-50%);
		width: 1px;
		height: 14px;
		border-right: 1px dashed #e5e5e5;
	}
	.myList_title ul li p.on {
		font-weight: 600;
	}

	/* myList_list */
	.myList_list {
		position: relative;
		width: 100%;
	}
	.myList_list ul {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		margin-right: -30px;
	}
	.myList_list ul li {
		width: calc(100% / 3 - 30px);
	}

	.myList_list ul li a {
		display: inline-block;
		width: 100%;
		border: 1px solid #e8e8e8;
		border-radius: 15px;
	}

	/* box_challenge */
	.box_challenge > p {
		color: #666;
	}
	.box_challenge .state {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
	}
	.box_challenge .state span {
		display: inline-block;
		padding: 5px 10px;
		border-radius: 5px;
		border: 1px solid #666;
		color: #666;
		background-color: #fff;
	}
	.box_challenge .state span.ing {
		border-color: #ed3d3d;
		color: #ed3d3d;
	}
	.box_challenge .state span.state_number {
		border-color: #258cef;
		background-color: #258cef;
		color: #fff;
	}

	.box_challenge .myRanking {
		position: relative;
		width: 100%;
		border-radius: 5px;
		background-color: #f5f5f5;
		text-align: center;
	}
	.box_challenge .myRanking p {
		color: #666;
	}
	.box_challenge .myRanking p span {
		font-size: 1.15rem;
		font-weight: 600;
		color: #000;
	}
	.box_challenge .myRanking p span.txt_blue {
		color: #1d5d9b;
	}

	// 미디어쿼리 ~1280 시작
	@media screen and (max-width: 1280px) {
		/* myList_list */
		.myList_list ul {
			gap: 20px;
			margin-right: -20px;
		}
		.myList_list ul li {
			width: calc(100% / 2 - 20px);
		}
		.challenge_info_wrap .info_content {
			gap: 10px;
		}
	}
	// 미디어쿼리 ~1280 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		/* challenge_info_wrap */
		.challenge_info_wrap {
			flex-direction: column;
			width: 100%;
			align-items: center;
			text-align: center;
			gap: 30px;
		}
		.challenge_info_wrap .info_content {
			gap: 10px;
			width: 100%;
		}
		.info_content button{
			margin-top: 10px;
		}

		/* myList_list */
		.myList_list ul {
			gap: 0px;
			margin-right: -0px;
		}
		.myList_list ul li {
			width: 100%;
		}
		.myList_list ul li:not(:last-child) {
			margin-bottom: 25px;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		/* challenge_info_wrap */
		.challenge_info_wrap img {
			display: block;
			width: 60%;
		}
	}
	// 미디어쿼리 ~600 끝
}
