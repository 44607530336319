.Write_VillageCommuPage {

    .fileBox,
    .filelistBox {
        position: relative;
        width: 100%;
    }

    /* 파일 */
    .fileBox label {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        text-align: center;
        border: 1px solid #258cef;
        color: #258cef;
        background-color: #fff;
        font-weight: 600;
        border-radius: 5px;
    }
    .fileBox input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }

    /* 파일리스트 */
    .filelistBox {
        position: relative;
        width: 100%;
    }
    .filelistBox ul {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .filelistBox ul li {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding: 20px 0;
    }

    .filelistBox ul li p {
        padding: 0 10px;
        width: 100px;
        text-align: center;
    }
    .filelistBox ul li p:nth-of-type(1) {
        width: calc(100% - 100px);
        text-align: start;
    }
    .filelistBox ul li p.delet button {
        padding: 5px 15px;
        border-radius: 5px;
        border: 1px solid #ed3d3d;
        font-size: 0.9rem;
        font-weight: 600;
        background-color: #fff;
        margin: 0 3px;
        color: #ed3d3d;
    }

    // 미디어쿼리 ~600 시작
    @media (max-width: 600px) {

        .filelistBox ul li {
            padding: 15px 0;
        }
        .filelistBox ul li p {
            padding: 0;
            width: 80px;
            text-align: center;
        }
        .filelistBox ul li p:nth-of-type(1) {
            width: calc(100% - 80px);
            text-align: start;
        }

    }
    // 미디어쿼리 ~600 끝
}