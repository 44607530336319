.Modify_PopupManagement {

    .buttonDiv {
        margin-top: 20px;
        margin-left: 20px;
    }

    #cancleButton {
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 10px;
        width: 120px;
        height: 45px;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 5px;
        border: 1px solid #e8e9eb;
        color: #000;
        background: #fff;
    }

    .insertButton.on {
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 10px;
        width: 120px;
        height: 45px;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 5px;
        border: 1px solid #1d5d9b;
        color: #fff;
        background: #1d5d9b;
    }

    .insertButton {
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 10px;
        width: 120px;
        height: 45px;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        color: #949799;
        background: #f5f5f5;
    }

    .comm_manage_wrap .imgInput .imglistBox {
        // width: calc(100% - 135px);
    }

    .comm_manage_wrap .imgInput .imglistBox ul {
        height: 100%;
        font-size: 0;
        box-sizing: border-box;
        overflow-x: auto;
        overflow-y: hidden;
        background: #fff;
        white-space: nowrap;
    }

    .comm_manage_wrap .imgInput .imglistBox ul li {
        position: relative;
        box-sizing: border-box;
        width: 120px;
        height: 120px;
        display: inline-block;
        border: 1px solid #e8e9eb;
        overflow: hidden;
        border-radius: 5px;
    }

    .comm_manage_wrap .imgInput .imglistBox ul li:not(:first-child) {
        margin-left: 15px;
    }

    .comm_manage_wrap .imgInput .imglistBox ul li img {
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .comm_manage_wrap .imgInput .imglistBox ul li button {
        position: absolute;
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        background: url(../../assets/ic_close.png) center no-repeat;
        background-size: cover;
        top: 10px;
        right: 10px;
        background-color: #fff;
        border-radius: 50%;
    }

    .imgContainer {
        display: flex;
    }

    .comm_manage_wrap {
        display: flex;
        height: 100%;
        gap: 10px;
        padding-left: 230px;
    }

    .comm_manage_wrap .imgInput {
        // /* 배경색 */
        // background-color: #f0f0f0;
        // /* 테두리 */
        // border: 1px solid #ccc;
    }

    // #chek {
    //     -webkit-appearance: none !important;
    //     appearance: auto !important;
    // }
    input[type='checkbox'] {
        vertical-align: middle;
        appearance: none;
        border: 1px solid #dedede;
        background: #fff;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        transition: border 0.2s ease-in-out;
        margin-right: 5px;
        cursor: pointer;
    }

    input[type='checkbox']:checked {
        border: 1px solid #2d8dee;
        background: url(../../assets/ic_check.png) 50% 50% no-repeat;
        background-size: cover;
        background-color: #2d8dee;
    }

    .textBox {
        margin-top: 10px;
        width: 300px;
        height: 300px;
        background: #fff;
        box-sizing: border-box;
        border: 2px solid #e8e9eb;
        border-radius: 5px;
    }

    .preview {
        width: 400px;
        height: 600px;
        border: 2px solid #e8e9eb;
        margin-left: 100px;
    }

    .preview p {
        margin: 5px;
        height: 80px;
        overflow-y: auto;
    }

    .previewImg {
        width: 100%;
        height: 500px;
        border-bottom: 2px solid #e8e9eb;
    }

    .comm_manage_wrap .imgInput .imgBox {
        width: 120px;
        height: 120px;
        margin-right: 15px;
    }

    .comm_manage_wrap .imgInput .imgBox .upload-photo {
        display: none;
    }

    .comm_manage_wrap .imgInput .imgBox input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }

    .comm_manage_wrap .imgInput .imgBox label {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        color: #949799;
        vertical-align: middle;
        background-color: #fff;
        cursor: pointer;
        height: 120px;
        width: 120px;
        border: 2px dashed #e8e9eb;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 5px;
    }

    .comm_manage_wrap .imgInput .imgBox label img {
        margin-bottom: 5px;
    }

    .comm_manage_wrap .imgInput .imgBox label span {
        color: #949799;
        font-size: 0.8rem;
    }

    // 미디어쿼리 ~1024 시작
    @media (max-width: 1024px) {
        .comm_manage_wrap {
            display: block;
            height: 100%;
            gap: 10px;
            padding-left: 10%;
        }

        .preview {
            width: 300px;
            height: 600px;
            border: 2px solid #e8e9eb;
            margin-left: -10px;
            margin-top: 10px;
        }

        .imgInput {
            margin-left: -10px;
        }

        .buttonDiv {
            margin-left: 10px;
        }
    }

    // 미디어쿼리 ~600 시작
    @media screen and (max-width: 600px) {
        .comm_manage_wrap {
            display: block;
            height: 100%;
            gap: 10px;
            padding-left: 10%;
        }

        .preview {
            width: 400px;
            height: 600px;
            border: 2px solid #e8e9eb;
            // margin-left: 100px;
        }
    }

}