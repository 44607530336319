@charset "utf-8";
.Main_MissionPage {
	/* mission_link_wrap */
	.mission_link_wrap {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 30px;
	}
	.mission_link_wrap a {
		display: flex;
		align-items: center;
		gap: 20px;
		width: calc(100% / 2);
		background-color: #fafafa;
		border-radius: 10px;
	}
	.mission_link_wrap a::after {
		content: '';
		display: block;
		width: 25px;
		height: 35px;
		background: url(../../assets/ic_left.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.mission_link_wrap a::before {
		content: '';
		display: block;
		width: 50px;
		height: 50px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
	}

	.mission_link_wrap a div {
		position: relative;
		width: calc(100% - 115px);
	}
	.mission_link_wrap a div h3 {
		font-size: 1.25rem;
		font-weight: 600;
	}
	.mission_link_wrap a div p {
		color: #666;
		padding-right: 100px;
	}

	/* mission_info_wrap */
	.mission_info_wrap {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.mission_info_wrap > h3 {
		font-size: 1.25rem;
		font-weight: 600;
	}
	.mission_info_wrap > p {
		color: #666;
	}

	.mission_info_wrap ul {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		gap: 10px;
	}
	.mission_info_wrap ul li div {
		width: 100px;
		padding: 20px;
		text-align: center;
		background-color: #1d5d9b;
	}
	.mission_info_wrap ul li:nth-of-type(1) div {
		padding: 80px 20px;
	}
	.mission_info_wrap ul li:nth-of-type(2) div {
		padding: 60px 20px;
	}
	.mission_info_wrap ul li:nth-of-type(3) div {
		padding: 40px 20px;
	}
	.mission_info_wrap ul li div p,
	.mission_info_wrap ul li div span {
		color: #fff;
	}
	.mission_info_wrap ul li div span {
		font-weight: 600;
	}

	/* mission_list_wrap */
	.mission_list_wrap {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
		gap: 60px;
	}
	.mission_list_box {
		width: calc(100% / 2);
	}

	.mission_cont_title,
	.mission_cont_content {
		position: relative;
		width: 100%;
	}

	/* mission_cont_title */
	.mission_cont_title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mission_cont_title h3::before {
		content: '';
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #258cef;
		margin-right: 5px;
	}
	.mission_cont_title h3 span {
		font-size: 1.25rem;
		font-weight: 600;
		color: #258cef;
	}

	/* mission_cont_set */
	.mission_cont_set {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.mission_cont_set ul {
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.mission_cont_set ul li {
		position: relative;
		cursor: pointer;
	}
	.mission_cont_set ul li:not(:last-child)::after {
		content: '';
		position: absolute;
		top: 50%;
		right: -10px;
		transform: translateY(-50%);
		width: 1px;
		height: 14px;
		border-right: 1px dashed #e5e5e5;
	}
	.mission_cont_set ul li p.on {
		font-weight: 600;
	}

	.mission_cont_set select {
		width: 160px;
		height: 40px;
		box-sizing: border-box;
		line-height: 40px;
		color: #000;
		border: 1px solid #e8e8e8;
		resize: none;
		padding: 0 30px 0 15px;
		border-radius: 5px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		background: url(../../assets/ic_select.png) right 8px center no-repeat;
	}

	/* mission_cont_content */
	.mission_cont_content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.mission_cont_content > p {
		font-size: 1.15rem;
	}
	.mission_cont_content > img {
		width: 50%;
	}
	.mission_cont_content > a {
		font-size: 1.15rem;
		text-decoration: underline;
		color: #1d5d9b;
	}
	.mission_cont_content span.update {
		color: #666;
		width: 100%;
		display: block;
		text-align: end;
	}

	/* ranking_list */
	.ranking_list {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.ranking_list p {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.ranking_list p span.num {
		font-size: 1.4rem;
		font-weight: 600;
		color: #1d5d9b;
		width: 40px;
		display: flex;
		justify-content: center;
	}
	.ranking_list p span.villname {
		font-size: 1.15rem;
		font-weight: 500;
		flex: 1 1 auto;
		padding: 0 20px;
	}
	.ranking_list p span.villname strong {
		font-size: 1rem;
		color: #666666;
		font-weight: 400;
	}
	.ranking_list p span.point {
		position: relative;
		font-size: 1.15rem;
		display: flex;
		align-items: center;
	}
	.ranking_list p span.point::before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-right: 5px;
	}

	.ranking_list p span.nickname {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.15rem;
		font-weight: 500;
		flex: 1 1 auto;
		padding: 0 20px;
	}
	.ranking_list p span.nickname::before {
		content: '';
		display: inline-block;
		width: 30px;
		height: 30px;
		margin-right: 7px;
	}
	.ranking_list p span.nickname.basic1::before {
		content: '';
		background: url(../../assets/character01.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.basic2::before {
		content: '';
		background: url(../../assets/character02.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv1::before {
		content: '';
		background: url(../../assets/character03.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv2::before {
		content: '';
		background: url(../../assets/character04.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv3::before {
		content: '';
		background: url(../../assets/character05.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.ranking_list p span.nickname.Lv4::before {
		content: '';
		background: url(../../assets/character06.png) 50% 50% no-repeat;
		background-size: cover;
	}

	// 미디어쿼리 ~1500 시작
	@media screen and (max-width: 1500px) {
		/* mission_list_wrap */
		.mission_list_wrap {
			gap: 40px;
		}
		.mission_link_wrap a div p {
			color: #666;
			padding-right: 0;
		}
	}
	// 미디어쿼리 ~1500 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		/* mission_link_wrap */
		.mission_link_wrap {
			flex-direction: column;
		}
		.mission_link_wrap a {
			width: 100%;
		}

		/* mission_list_wrap */
		.mission_list_wrap {
			flex-direction: column;
		}
		.mission_list_box {
			width: 100%;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		/* mission_info_wrap */
		.mission_info_wrap ul {
			width: calc(100% - 30px);
		}
		.mission_info_wrap ul li {
			width: calc(100% / 4);
		}
		.mission_info_wrap ul li div {
			padding: 0;
			width: 100%;
		}
		.mission_info_wrap ul li:nth-of-type(1) div {
			padding: 55px 0;
		}
		.mission_info_wrap ul li:nth-of-type(2) div {
			padding: 40px 0;
		}
		.mission_info_wrap ul li:nth-of-type(3) div {
			padding: 25px 0;
		}
		.mission_info_wrap ul li:nth-of-type(4) div {
			padding: 10px 0;
		}

		/* mission_list_wrap */
		.mission_list_wrap {
			gap: clamp(25px, 5vw, 100px);
		}

		/* ranking_list */
		.ranking_list {
			gap: 15px;
		}
		.ranking_list p span.villname {
			display: flex;
			flex-direction: column;
		}
		.ranking_list p span.nickname {
			padding: 0 10px;
		}
	}
	// 미디어쿼리 ~600 끝
}
