@charset "utf-8";
.Write_MapMissionPage {
	/* 공통 변경사항 */
	.comm_write_wrap .cont-wrap .partmake input[type='text'] {
		flex: 1 0;
		flex-basis: 0;
	}

	.listSelect {
		margin: 12px 0;
	}
	.listSelect label {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.listSelect [type='checkbox'] {
		vertical-align: middle;
		appearance: none;
		border: 1px solid #dedede;
		border-radius: 3px;
		width: 20px;
		height: 20px;
		transition: border 0.2s ease-in-out;
		cursor: pointer;
		margin-right: 8px;
	}
	.listSelect [type='checkbox']:checked {
		border: 1px solid #258cef;
		background: url(../../assets/ic_check.png) 50% 50% no-repeat;
		background-size: cover;
		background-color: #258cef;
	}
	.listSelect span {
		font-size: 1.2rem;
		color: #555;
	}
	.listSelect [type='checkbox']:checked ~ span {
		color: #000;
	}

	/* 테이블 */
	.color_table {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.color_table .thead {
		width: 100%;
		display: inline-block;
		border-top: 2px solid #555;
		border-bottom: 1px solid #dedede;
	}
	.color_table .thead li {
		float: left;
		position: relative;
		text-align: center;
		color: #666;
		font-weight: 600;
		padding-left: 10px;
		padding-right: 10px;
	}

	.color_table .tbody {
		width: 100%;
		display: inline-block;
	}
	.color_table .tbody li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		border-bottom: 1px solid #dedede;
	}

	.color_table .thead li:nth-of-type(1),
	.color_table .tbody li p:nth-of-type(1) {
		width: 25%;
	}
	.color_table .thead li:nth-of-type(2),
	.color_table .tbody li p:nth-of-type(2) {
		width: 45%;
	}
	.color_table .thead li:nth-of-type(3),
	.color_table .tbody li p:nth-of-type(3) {
		width: 30%;
		text-align: center;
	}

	.color_table .tbody li p span {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 3px;
		margin: 0 auto;
	}

	.color_table .tbody li p button.btn_red {
		border-color: #ed3d3d;
		color: #ed3d3d;
	}
	.color_table .tbody li p button {
		padding: 5px 15px;
		border-radius: 5px;
		border: 1px solid #000;
		font-size: 0.9rem;
		font-weight: 600;
		background-color: #fff;
		margin: 0 3px;
	}

	/* 분류생성 */
	.partmake {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.partmake button {
		margin-left: 10px;
	}
	.partmake .btn_color {
		border-radius: 5px;
		border: 1px solid #dedede;
		background-color: #fff;
	}
	.partmake .btn_color span {
		display: block;
		width: 30px;
		height: 30px;
		margin: 7px;
		background-color: #000;
		border-radius: 5px;
	}

	.partmake .color_popup {
		position: absolute;
		top: 55px;
		left: calc(80% - 70px);
		width: 150px;
		padding: 10px;
		border: 1px solid #dedede;
		background-color: #fff;
		z-index: 100;
	}
	.partmake .color_popup ul {
		position: relative;
		width: 100%;
	}
	.partmake .color_popup ul li {
		width: calc(100% / 5);
		float: left;
		border: 1px solid #fff;
	}
	.partmake .color_popup ul li p {
		position: relative;
		width: 100%;
		padding-top: 100%;
	}
	.partmake .color_popup ul li p input[type='radio'] {
		display: none;
	}
	.partmake .color_popup ul li p label {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
	}

	.partmake .btn_black {
		width: 80px;
		height: 44px;
		line-height: 44px;
		cursor: pointer;
		margin-left: 10px;
		text-align: center;
		border: 1px solid #258cef;
		color: #258cef;
		background-color: #fff;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 5px;
	}

	/* 컬러 */
	.partmake .btn_color span.color01,
	.partmake .color_popup label.color01,
	.color_table .tbody li p span.color01 {
		background-color: #ed463a;
	}
	.partmake .btn_color span.color02,
	.partmake .color_popup label.color02,
	.color_table .tbody li p span.color02 {
		background-color: #e6613e;
	}
	.partmake .btn_color span.color03,
	.partmake .color_popup label.color03,
	.color_table .tbody li p span.color03 {
		background-color: #f5bd00;
	}
	.partmake .btn_color span.color04,
	.partmake .color_popup label.color04,
	.color_table .tbody li p span.color04 {
		background-color: #c09b7f;
	}
	.partmake .btn_color span.color05,
	.partmake .color_popup label.color05,
	.color_table .tbody li p span.color05 {
		background-color: #866a55;
	}
	.partmake .btn_color span.color06,
	.partmake .color_popup label.color06,
	.color_table .tbody li p span.color06 {
		background-color: #79b543;
	}
	.partmake .btn_color span.color07,
	.partmake .color_popup label.color07,
	.color_table .tbody li p span.color07 {
		background-color: #0b8a0b;
	}
	.partmake .btn_color span.color08,
	.partmake .color_popup label.color08,
	.color_table .tbody li p span.color08 {
		background-color: #1fdcc8;
	}
	.partmake .btn_color span.color09,
	.partmake .color_popup label.color09,
	.color_table .tbody li p span.color09 {
		background-color: #2cbce0;
	}
	.partmake .btn_color span.color10,
	.partmake .color_popup label.color10,
	.color_table .tbody li p span.color10 {
		background-color: #2a8aeb;
	}
	.partmake .btn_color span.color11,
	.partmake .color_popup label.color11,
	.color_table .tbody li p span.color11 {
		background-color: #334ed8;
	}
	.partmake .btn_color span.color12,
	.partmake .color_popup label.color12,
	.color_table .tbody li p span.color12 {
		background-color: #a3a7f8;
	}
	.partmake .btn_color span.color13,
	.partmake .color_popup label.color13,
	.color_table .tbody li p span.color13 {
		background-color: #9a6afc;
	}
	.partmake .btn_color span.color14,
	.partmake .color_popup label.color14,
	.color_table .tbody li p span.color14 {
		background-color: #fe9ef2;
	}
	.partmake .btn_color span.color15,
	.partmake .color_popup label.color15,
	.color_table .tbody li p span.color15 {
		background-color: #f63498;
	}

	span.explain {
		color: #ed3d3d;
		margin-top: 10px;
	}

	.comm_write_wrap .cont-box .select-box {
		flex-direction: row;
		align-items: center;
		gap: 10px;
	}

	.village_list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 15px;
	}

	.village_list li label {
		display: flex;
		align-items: center;
	}

	.village_list li label input[type='radio'] {
		width: 25px;
		height: 25px;
		padding: 0;
		margin-right: 5px;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #e8e8e8;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s;
	}

	.village_list li label input[type='radio']:checked {
		border: 8px solid #258cef;
	}

	.village_list li label p {
		color: #666666;
		font-size: 0.9rem;
	}

	// 미디어쿼리 ~1024 시작
	@media (max-width: 1024px) {
		.partmake .color_popup {
			left: inherit;
			right: 90px;
		}

		/* color_table */
		.color_table .thead {
			padding: 0 !important;
			border-bottom: 0 !important;
		}
		.color_table .thead li {
			display: none;
		}

		.color_table .tbody li {
			flex-direction: column;
			align-items: start;
			gap: 2px;
		}
		.color_table .tbody li p {
			text-align: start;
			padding-left: 0;
			padding-right: 0;
		}
		.color_table .tbody li p:not(:last-child) {
			margin-bottom: 5px;
		}
		.color_table .tbody li p.menu {
			color: #1d5d9b;
			font-weight: 600;
		}
		.color_table .tbody li p span {
			margin: 0;
		}

		/* color_table set */
		.color_table .thead li:first-of-type .color_table .thead li:nth-of-type(2),
		.color_table .thead li:nth-of-type(3),
		.color_table .thead li:nth-of-type(4),
		.color_table .tbody li p:first-of-type,
		.color_table .tbody li p:nth-of-type(2),
		.color_table .tbody li p:nth-of-type(3),
		.color_table .tbody li p:nth-of-type(4) {
			width: 100%;
			text-align: start;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media (max-width: 600px) {
		/* partmake */
		.partmake .btn_black {
			height: 40px;
			line-height: 40px;
		}
		.partmake .btn_color span {
			width: 26px;
			height: 26px;
		}
	}
	// 미디어쿼리 ~600 끝
}
