@charset "utf-8";

#wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

/* header_alarm */
.header_alarm {
    position: absolute;
    top: -4px;
    left: -6px;
    font-size: .6rem;
    background-color: #e6613e;
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-weight: 600;
}

/* hedaer */
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;
}

#header {
    width: 1500px;
    max-width: 100%;
    margin: 0 auto;
}

#header .header_login {
    width: 100%;
    padding-top: 25px;
}

#header .header_login ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 40px;
    height: 100%;
}

#header .header_login ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

#header .header_login ul li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px dashed #e5e5e5;
}

#header .header_login ul li a {
    color: #666;
    display: flex;
    align-items: center;
}

#header .header_login ul li a.txt_blue {
    color: #1d5d9b;
}

#header .header_login ul li a img {
    margin-right: 7px;
}

#header .header_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
}

#header .header_menu .logo {
    width: 700px;
}

#header .header_menu .logo img {
    width: 100%;
    vertical-align: bottom;
}

#header .header_menu #gnb {
    height: 100%;
}

#header .header_menu #gnb .gnb_dep1 {
    display: flex;
    align-items: center;
    gap: 80px;
    height: 100%;
}

#header .header_menu #gnb .gnb_dep1 .gnb_dep1_li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#header .header_menu #gnb .gnb_dep1 .gnb_dep1_li>a {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    transition: .2s;
}

/* gnb_dep2 */
.header_menu #gnb .gnb_dep1 .gnb_dep1_li:hover .gnb_dep2 {
    display: block;
}

#header .gnb_dep2 {
    display: none;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    padding: 20px 0;
    width: 160px;
    border-radius: 10px;
    background-color: #0052c6;
    text-align: center;
}

#header .gnb_dep2::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #0052c6;
}

#header .gnb_dep2 li a {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
}

#header .gnb_dep2 li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: .2s;
}

#header .gnb_dep2 li a:hover:after {
    width: 100%;
}

/* header_menu 설정 */
.header_menu .header_back,
.header_menu .header_title,
.header_menu select,
.header_menu .mobile_ham {
    display: none;
}

/* header hover */

/* Poup_mobile_menu */
.Poup_mobile_menu {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0vw;
    width: 100%;
    height: 100%;
    z-index: 1500;
}

.Poup_mobile_menu.on {
    opacity: 1;
    visibility: visible;
}

.Poup_mobile_menu.on .mobile_menu_cont {
    left: 0;
}

.Poup_mobile_menu .mobile_menu_bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

.Poup_mobile_menu .mobile_menu_cont {
    position: absolute;
    top: 0;
    left: -100vw;
    width: 400px;
    height: 100vh;
    overflow-y: auto;
    transition: .6s;
    background-color: #fff;
}

/* mobile_title */
.Poup_mobile_menu .mobile_title {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 80px;
}

.Poup_mobile_menu .mobile_title a.logo {
    display: inline-block;
    width: calc(100% - 40px);
    margin-top: 5px;
}

.Poup_mobile_menu .mobile_title a.logo img {
    width: 100%;
}

.Poup_mobile_menu .mobile_title .mobile_close {
    position: relative;
    right: -1vw;
    width: clamp(20px, 4.9vw, 40px);
    height: clamp(20px, 4.9vw, 40px);
    text-indent: -9999px;
    background-color: #fff;
}

.Poup_mobile_menu .mobile_title .mobile_close::after,
.Poup_mobile_menu .mobile_title .mobile_close::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #000;
    border-radius: 3px;
}

.Poup_mobile_menu .mobile_title .mobile_close::after {
    width: 2px;
    height: clamp(17px, 5vw, 25px);
}

.Poup_mobile_menu .mobile_title .mobile_close::before {
    width: clamp(17px, 5vw, 25px);
    height: 2px;
}

/* mobile_login */
.Poup_mobile_menu .mobile_login {
    position: relative;
    width: 100%;
    padding: 30px 20px;
    border-bottom: 5px solid #f5f5f5;
}

.Poup_mobile_menu .mobile_login p {
    font-size: 1.6rem;
    margin-bottom: 20px;
}

.Poup_mobile_menu .mobile_login div {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Poup_mobile_menu .mobile_login div a {
    display: inline-block;
    padding: 10px 0;
    width: 120px;
    text-align: center;
    border-radius: 50px;
    border: 1px solid #1d5d9b;
    color: #1d5d9b;
    background-color: #fff;
    font-weight: 600;
}

.Poup_mobile_menu .mobile_login div a.txt_blue {
    background: linear-gradient(120deg, #1d5d9b 25%, #258cef);
    border: 0;
    color: #fff;
}

/* mobile_gnb */
.Poup_mobile_menu #mobile_gnb {
    position: relative;
    width: 100%;
    padding: 20px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li>a {
    display: block;
    width: 100%;
    padding: 12px 0;
    font-size: 1.4rem;
    font-weight: 500;
    color: #333;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li.mgd_li_link>a {
    background: url(../assets/mobile_left.png) no-repeat center right;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li.mgd_li_drop>a {
    background: url(../assets/mobile_down.png) no-repeat center right;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li.on.mgd_li_drop>a {
    background: url(../assets/mobile_up.png) no-repeat center right;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li>a::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    vertical-align: middle;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li:first-child>a::before {
    background: #f4f4f4 url(../assets/ic_footer11.png) no-repeat center / 18px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li:nth-child(2)>a::before {
    background: #f4f4f4 url(../assets/ic_footer12.png) no-repeat center / 18px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li:nth-child(3)>a::before {
    background: #f4f4f4 url(../assets/ic_footer01.png) no-repeat center / 18px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li:nth-child(4)>a::before {
    background: #f4f4f4 url(../assets/ic_footer02.png) no-repeat center / 18px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li:nth-child(5)>a::before {
    background: #f4f4f4 url(../assets/ic_footer04.png) no-repeat center / 18px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li:nth-child(6)>a::before {
    background: #f4f4f4 url(../assets/ic_footer13.png) no-repeat center / 18px;
}

/* mobile_gnb_dep2 */
.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep2 {
    width: 100%;
    padding-left: 45px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep2 li {
    width: 100%;
    margin: 15px;
}

.Poup_mobile_menu #mobile_gnb .mobile_gnb_dep2 li a {
    color: #666;
    font-weight: 600;
    font-size: 1.25rem;
}

/* mobile_info */
.Poup_mobile_menu .mobile_info {
    position: relative;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.Poup_mobile_menu .mobile_info a {
    font-size: 1.25rem;
    color: #666;
    font-weight: 600;
}

.Poup_mobile_menu .mobile_info a:not(:last-child) {
    margin-bottom: 20px;
}

.Poup_mobile_menu .mobile_info a.txt_blue {
    color: #258cef;
}

/* footer */
footer {
    position: relative;
    width: 100%;
    background-color: #333;
    padding: 40px 0;
}

#footer {
    width: 1500px;
    max-width: 100%;
    margin: 0 auto;
}

#footer p {
    color: #999;
}

#footer .footer_link {
    position: relative;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

#footer .footer_link a {
    color: #fff;
}

#footer .footer_link a.yellow {
    color: #ffc200;
}

/* footer_mobile */
#footer_mobile {
    display: none;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: #fff;
    z-index: 500;
    padding: 0 40px;
}

#footer_mobile ul {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer_mobile ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex: 1;
}

#footer_mobile ul li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 75px;
    padding: 3px 0;
    border-radius: 5px;
    background-color: #fff;
}

#footer_mobile ul li a p {
    font-size: 1rem;
    margin-top: 2px;
}

#footer_mobile ul li a div {
    position: relative;
    display: block;
    width: 33px;
}

#footer_mobile ul li a div::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

#footer_mobile ul li a div img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

#footer_mobile ul li a div img:last-child {
    display: none;
}

#footer_mobile ul li a.on {
    background-color: #1d5d9b;
}

#footer_mobile ul li a.on p {
    color: #fff;
}

#footer_mobile ul li a.on div img:first-child {
    display: none;
}

#footer_mobile ul li a.on div img:last-child {
    display: block;
}

/* main */
main {
    position: relative;
    width: 100%;
    flex: 1 0;
    flex-basis: 0;
    padding: 186px 0 60px 0;
}

main #container {
    width: 1500px;
    max-width: 100%;
    margin: 0 auto;
}

main #lnb {
    width: 1500px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

main #lnb .lnb_menu,
main #lnb .lnb_txt {
    display: flex;
    align-items: center;
    justify-content: center;

}

/* lnb_dep1 */
main #lnb .lnb_menu {
    margin-bottom: 20px;
}

main #lnb .lnb_menu .lnb_dep1 {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: center;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li {
    position: relative;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px solid #e5e5e5;
    rotate: 15deg;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li>a {
    color: #aaa;
    font-weight: 500;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li.end>a {
    color: #000;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:not(:last-child) a {
    position: relative;
    display: inline-block;
}

main #lnb .lnb_menu .lnb_dep1 li a.home img {
    display: block;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:not(:first-child)>a::after {
    content: "";
    display: inline-block;
    width: 14px;
    height: 9px;
    background: url(../assets/ic_select.png)50% 50% no-repeat;
    margin-left: 10px;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li.on>a {
    color: #1d5d9b;
    font-weight: 600;
}

main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li.on:not(:first-child)>a::after {
    content: "";
    background: url(../assets/ic_select_on.png)50% 50% no-repeat;
}

/* lnb_dep2 */
main #lnb .lnb_menu .lnb_dep2 {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #e9e9e9;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 0;
    overflow: hidden;
    white-space: nowrap;
    z-index: 20;
}

main #lnb .lnb_menu .lnb_dep2 li {
    display: block;
}

main #lnb .lnb_menu .lnb_dep2 li a {
    display: block;
    padding: 15px 30px;
}

main #lnb .lnb_menu .lnb_dep2 li a:hover {
    color: #1d5d9b;
}

main #lnb .lnb_menu .lnb_dep2 li.active a {
    color: #aaa;
    background-color: #fafafa;
}

/* lnb_txt */
main #lnb .lnb_txt {
    flex-direction: column;
}

main #lnb .lnb_txt h3 {
    font-size: 3.6rem;
    font-weight: 600;
}

main #lnb .lnb_txt select {
    font-size: 3.6rem;
    font-weight: 600;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 30px;
    background: url(../assets/ic_select_tit.png) 100% 50% no-repeat;
}

main #lnb .lnb_txt select option {
    font-size: 1.2rem;
    text-align: center;
    padding: 20px 0;
}

main #lnb .lnb_txt p {
    font-size: 1.25rem;
    margin-top: 15px;
    text-align: center;
}

/* main_wrapper */
#wrapper.main_wrapper main {
    padding: 126px 0 60px 0;
}

#wrapper.main_wrapper main #lnb {
    display: none;
}

/* sub_wrapper */
#wrapper.sub_wrapper main #lnb .lnb_txt select {
    display: none;
}

/* sub_village_wrapper */
#wrapper.sub_village_wrapper main #lnb .lnb_txt h3 {
    display: none;
}

/* write_wrapper */
#wrapper.write_wrapper main #container {
    width: 1000px;
}

#wrapper.write_wrapper main #lnb .lnb_menu,
#wrapper.write_wrapper main #lnb .lnb_txt select {
    display: none;
}

.only_mobile {
    display: none;
}

/* detail_wrapper */
#wrapper.detail_wrapper main #container {
    width: 1000px;
}

#wrapper.detail_wrapper main #lnb .lnb_txt select {
    display: none;
}


/* mobile_footer */
.Popup_mobile_footer {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0vw;
    width: 100%;
    height: 100%;
    z-index: 1500;
}

.Popup_mobile_footer.on {
    opacity: 1;
    visibility: visible;
}

.Popup_mobile_footer .mobile_footer_bg {
    position: fixed;
    top: 0;
    left: 0vw;
    width: 100%;
    height: 100%;
    z-index: 1500;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
}

.Popup_mobile_footer .mobile_footer_cont {
    position: absolute;
    width: 100%;
    left: 50%;
    bottom: -100vh;
    transition: .4s;
    z-index: 1501;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.Popup_mobile_footer.on .mobile_footer_cont {
    bottom: 0;
    transform: translateX(-50%);
}

.Popup_mobile_footer .footer_cont_tit h3 {
    width: 100%;
    text-align: center;
}

.Popup_mobile_footer .footer_cont_tit {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Popup_mobile_footer .footer_cont_tit button {
    position: relative;
    width: clamp(20px, 4.9vw, 40px);
    height: clamp(20px, 4.9vw, 40px);
    text-indent: -9999px;
    background-color: #fff;
}

.Popup_mobile_footer .footer_cont_tit button::after,
.Popup_mobile_footer .footer_cont_tit button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #000;
    border-radius: 3px;
}

.Popup_mobile_footer .footer_cont_tit button::after {
    width: 2px;
    height: clamp(17px, 5vw, 25px);
}

.Popup_mobile_footer .footer_cont_tit button::before {
    width: clamp(17px, 5vw, 25px);
    height: 2px;
}

.Popup_mobile_footer .footer_cont_list ul,
.Popup_mobile_footer .footer_cont_list ul li {
    position: relative;
    width: 100%;
}

.Popup_mobile_footer .footer_cont_list ul li:not(:last-child):before {
    content: '';
    width: calc(100% - 40px);
    height: 1px;
    background-color: #e8e8e8;
    position: absolute;
    bottom: 0;
    left: 20px;
}

.Popup_mobile_footer .footer_cont_list ul li a p {
    font-size: 1.15rem;
    font-weight: 600;
}

/* 미디어쿼리 1023~ 시작 */
@media screen and (min-width:1023px) {

    /* header */
    .header_menu #gnb .gnb_dep1 .gnb_dep1_li:hover>a {
        color: #1d5d9b !important;
    }
}
/* 미디어쿼리 1023~ 끝 */


/* 미디어쿼리 ~1500 시작*/
@media (max-width: 1500px) {
    #header .header_menu #gnb .gnb_dep1 {
        display: flex;
        align-items: center;
        gap: 40px;
        height: 100%;
    }

    #header,
    #footer,
    main #lnb,
    main #container {
        width: 100%;
        padding: 0 40px;
    }

    /* write_wrapper */
    #wrapper.write_wrapper main #container {
        width: 1000px;
        padding: 0;
    }

    /* detail_wrapper */
    #wrapper.detail_wrapper main #container {
        width: 1000px;
        padding: 0;
    }

}
/* 미디어쿼리 ~1500 끝*/

/* 미디어쿼리 ~1350 시작 */
@media (max-width: 1350px){
    #header .header_login,
    #header .header_menu #gnb {
        display: none;
    }

    .header_menu .mobile_ham {
        display: block;
        position: relative;
    }

    .header_menu .mobile_ham span,
    .header_menu .mobile_ham span::after,
    .header_menu .mobile_ham span::before {
        content: '';
        position: absolute;
        right: 0;
        width: 30px;
        height: 3px;
        height: 3px;
        background-color: #1d5d9b;
        border-radius: 3px;
        text-indent: -9999px;
    }

    .header_menu .mobile_ham span {
        position: absolute;
        top: 50%;
    }

    .header_menu .mobile_ham span::after {
        top: -10px;
    }

    .header_menu .mobile_ham span::before {
        bottom: -10px;
    }

    main {
        padding: 130px 0 60px 0;
    }
    
}
/* 미디어쿼리 ~1350 끝 */


/* 미디어쿼리 ~1260 시작 */
@media (max-width: 1260px){
    #header .header_menu img{
        width: 400px;
    }
    .MainPage .visual_off_wrap {
        background-size: 400px;
    }
}
/* 미디어쿼리 ~1260 끝 */


/* 미디어쿼리 ~1024 시작 */
@media (max-width: 1024px) {

    /* header_alarm */
    .header_alarm {
        width: 22px;
        height: 22px;
        line-height: 22px;
    }

    #header .header_menu a.logo img {
        width: 450px;
    }

    .MainPage .visual_off_wrap {
        background-size: 0;
    }

    footer {
        position: fixed;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0 -2px 15px 1px rgba(0, 0, 0, .05);
        padding: 0;
    }

    #footer {
        display: none;
    }

    #footer_mobile {
        display: flex;
    }

    main {
        padding: 120px 0;
    }

    main #lnb {
        margin-bottom: 5vw;
    }

    main #lnb .lnb_menu {
        display: none;
    }

    main #lnb .lnb_txt h3 {
        font-size: 2.25rem;
        font-weight: 600;
        margin-top: 0;
    }

    main #lnb .lnb_txt select {
        display: none;
    }

    main #lnb .lnb_txt p {
        margin-top: .8vw;
    }

    /* main_wrapper */
    #wrapper.main_wrapper main {
        padding: 80px 0;
    }

    /* sub_village_wrapper */
    #wrapper.sub_village_wrapper header {
        border-bottom: 0;
    }

    #wrapper.sub_village_wrapper #header .header_menu {
        justify-content: center;
    }

    #wrapper.sub_village_wrapper #header .header_menu a.logo {
        display: none;
    }

    #wrapper.sub_village_wrapper #header .header_menu .header_back {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        width: 16px;
    }

    #wrapper.sub_village_wrapper #header .header_menu .header_back img {
        width: 100%;
    }

    #wrapper.sub_village_wrapper #header .header_menu select {
        display: block;
        border: 0;
        font-size: 1.6rem;
        font-weight: 600;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding-right: 25px;
        background: url(../assets/ic_select_tit.png) 100% 50% no-repeat;
        background-size: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #wrapper.sub_village_wrapper #header .header_menu select option {
        font-size: 1.25rem;
        text-align: center;
    }

    #wrapper.sub_village_wrapper #header .header_menu .mobile_ham {
        margin-left: auto;
    }

    #wrapper.sub_village_wrapper footer {
        display: none;
    }

    #wrapper.sub_village_wrapper main {
        padding: 80px 0 0 0;
    }

    #wrapper.sub_village_wrapper main #lnb {
        display: none;
    }

    #wrapper.sub_village_wrapper main #content {
        padding-top: 80px;
        padding-bottom: 40px;
        /* margin-top: 100px; */
    }

    /* write_wrapper */
    #wrapper.write_wrapper main #container {
        width: 100%;
        padding: 0 40px;
    }

    #wrapper.write_wrapper #header .header_menu {
        justify-content: center;
    }

    #wrapper.write_wrapper #header .header_menu a.logo,
    #wrapper.write_wrapper #header .header_menu .mobile_ham {
        display: none;
    }

    #wrapper.write_wrapper #header .header_menu .header_back {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        width: 16px;
    }

    #wrapper.write_wrapper #header .header_menu .header_back img {
        width: 100%;
    }

    #wrapper.write_wrapper #header .header_menu .header_title {
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
    }

    #wrapper.write_wrapper footer {
        display: none;
    }

    #wrapper.write_wrapper main {
        padding: 120px 0 40px 0;
    }

    #wrapper.write_wrapper main #lnb {
        display: none;
    }

    .only_mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        text-align: center;
    }

    /* detail_wrapper */
    #wrapper.detail_wrapper main #container {
        width: 100%;
        padding: 0 40px;
    }

    #wrapper.detail_wrapper #header .header_menu {
        justify-content: center;
    }

    #wrapper.detail_wrapper #header .header_menu a.logo,
    #wrapper.detail_wrapper #header .header_menu .mobile_ham {
        display: none;
    }

    #wrapper.detail_wrapper #header .header_menu .header_back {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        width: 16px;
    }

    #wrapper.detail_wrapper #header .header_menu .header_back img {
        width: 100%;
    }

    #wrapper.detail_wrapper #header .header_menu .header_title {
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
    }

    #wrapper.detail_wrapper footer {
        display: none;
    }

    #wrapper.detail_wrapper main {
        padding: 120px 0 40px 0;
    }

    #wrapper.detail_wrapper main #lnb {
        display: none;
    }

}
/* 미디어쿼리 ~1024 끝 */


/* 미디어쿼리 ~600 시작 */
@media (max-width: 600px) {

    #header,
    #footer_mobile,
    main #lnb,
    main #container,
    #wrapper.write_wrapper main #container,
    #wrapper.detail_wrapper main #container {
        padding: 0 12px;
    }

    #header .header_menu {
        height: 12vw;
        min-height: 45px;
    }

    #header .header_menu a.logo img {
        width: calc(100% - 60px);
    }

    .header_menu .mobile_ham span {
        top: calc(50% - 2px);
    }

    .header_menu .mobile_ham span::after {
        top: -7px;
    }

    .header_menu .mobile_ham span::before {
        bottom: -7px;
    }

    #footer_mobile {
        height: 15vw;
        min-height: 75px;
    }

    #footer_mobile ul li a {
        width: 15vw;
        height: 12vw;
        padding: 0;
        min-height: 55px;
    }

    #footer_mobile ul li a div {
        width: 5vw;
        min-width: 25px;
    }

    main {
        padding: 0;
        padding-top: clamp(75px, 19vw, 115px);
        padding-bottom: clamp(105px, 22vw, 160px);
    }

    /* main_wrapper */
    #wrapper.main_wrapper main {
        padding: 0;
        padding-top: clamp(45px, 12vw, 75px);
        padding-bottom: clamp(75px, 15vw, 120px);
    }

    /* sub_village_wrapper */
    #wrapper.sub_village_wrapper #header .header_menu .header_back {
        left: 12px;
        width: 2.8vw;
    }

    #wrapper.sub_village_wrapper main {
        padding: 0;
        padding-top: clamp(45px, 12vw, 75px);
    }

    #wrapper.sub_village_wrapper main #content {
        /* padding-top: 0;
        padding-bottom: clamp(30px, 5vw, 100px); */
        /* margin-top: 150px; */
        padding-top: clamp(50px, 12vw, 105px);
        padding-bottom: clamp(30px, 5vw, 100px);
    }

    /* write_wrapper */
    #wrapper.write_wrapper #header .header_menu .header_back {
        left: 12px;
        width: 2.8vw;
    }

    #wrapper.write_wrapper main {
        padding: 0;
        padding-top: clamp(75px, 19vw, 115px);
        padding-bottom: 110px;
    }

    /* detail_wrapper */
    #wrapper.detail_wrapper #header .header_menu .header_back {
        left: 12px;
        width: 2.8vw;
    }

    #wrapper.detail_wrapper main {
        padding: 0;
        padding-top: clamp(45px, 12vw, 75px);
    }

    #wrapper.detail_wrapper main #content {
        padding-top: clamp(50px, 12vw, 105px);
        padding-bottom: clamp(30px, 5vw, 100px);
    }

}
/* 미디어쿼리 ~600 끝 */

/* 미디어쿼리 ~500 시작 */
@media (max-width: 500px) {
    #wrapper.sub_village_wrapper main #content {
        padding-top: clamp(50px, 12vw, 105px);
        padding-bottom: clamp(30px, 5vw, 100px);
        /* padding-top: 0;
        padding-bottom: clamp(30px, 5vw, 100px); */
        /* margin-top: 100px; */
    }
}

/* 미디어쿼리 ~500 시작 */
@media (max-width: 400px) {

    #footer_mobile ul li a {
        width: 100%;
    }

    .Poup_mobile_menu .mobile_login p {
        font-size: 1.4rem;
    }

    .Poup_mobile_menu .mobile_menu_cont {
        width: min(95vw, 336px);
    }

    .Poup_mobile_menu .mobile_login div a {
        width: 100px;
    }

    .Poup_mobile_menu #mobile_gnb .mobile_gnb_dep1_li>a {
        font-size: 1.25rem;
    }

    .Poup_mobile_menu .mobile_info a {
        font-size: inherit;
    }

    #wrapper.sub_village_wrapper main #content {
        /* padding-top: 0;
        padding-bottom: clamp(30px, 5vw, 100px);
        margin-top: 80px; */
        padding-top: clamp(50px, 12vw, 105px);
        padding-bottom: clamp(30px, 5vw, 100px);
    }

}
/* 미디어쿼리 ~400 끝 */