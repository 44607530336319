@charset "utf-8";

// :root {
// 	--swiper-navigation-size: 25px !important;
// }

.List_IntroMainPage {
	/* intro_wrap */
	.intro_wrap {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	/* intro_title */
	.intro_title {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.intro_title h3 {
		position: relative;
		font-size: 1.25rem;
		font-weight: 500;
		display: flex;
		align-items: center;
	}
	.intro_title h3::before {
		content: '';
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #258cef;
		margin-right: 5px;
	}
	.intro_title button {
		padding: 10px 20px;
		font-weight: 500;
		background-color: #1d5d9b;
		color: #fff;
		border-radius: 5px;
	}

	/* intro_content */
	.intro_content {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
	}
	.intro_content .intro_img {
		position: relative;
		width: 400px;
		height: 250px;
		margin-left: 40px;
		overflow: hidden;
		border-radius: 15px;
	}
	.intro_content .intro_img img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 100%;
		max-height: 100%;
	}

	/* intro_table */
	.intro_table {
		position: relative;
		width: calc(100% - 440px);
		border-top: 1px solid #e8e8e8;
	}
	.intro_table ul {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.intro_table ul li {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		background-color: #fafafa;
		border-bottom: 1px solid #e8e8e8;
	}
	.intro_table ul li h4 {
		color: #666;
		width: 200px;
		text-align: center;
	}

	.intro_table ul li div{
		position: relative;
		flex: 1 0;
		flex-basis: 0;
		background-color: #fff;
		font-size: 1.15rem;
		line-height: 1.3;
	}
	.intro_table ul li p {
		display: block;
		width: 100%;
		color: #666;
	}

	.intro_table ul li .box_map {
		position: relative;
		display: block;
		width: 100%;
		overflow: hidden;
		height: 200px;
		margin-top: 10px;
	}

	/* noimg */
	.noimg .intro_content .intro_img {
		display: none;
	}
	.noimg .intro_table {
		width: 100%;
	}

	/* case */
	.intro_content.case {
		flex-direction: inherit;
	}
	.intro_content.case > ul {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.intro_content.case > ul > li {
		position: relative;
		width: 100%;
	}

	/* box_case */
	.box_case {
		position: relative;
		width: 100%;
		border: 1px solid #e8e8e8;
		background-color: #fff;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.box_case h5 {
		width: 200px;
		font-size: 1.15rem;
		font-weight: 600;
	}

	.box_case .case_txt {
		flex: 1 0;
		flex-basis: 0;
		margin: 0 30px;
	}
	.box_case .case_txt p {
		font-size: 1.15rem;
	}

	.box_case .case_img {
		margin: 0 30px;
		position: relative;
		width: 200px;
		height: 125px;
		overflow: hidden;
		border-radius: 5px;
	}
	.box_case .case_img img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.box_case .case_img .swiper-wrapper .number {
		position: absolute;
		bottom: 0px;
		right: 0px;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top-left-radius: 3px;
		color: #fff;
		font-size: 0.85rem;
		background-color: rgba(0, 0, 0, 0.6);
	}
	.box_case.noimg .case_img {
		display: none;
	}

	.box_case .case_btn button {
		display: inline-block;
		padding: 5px 10px;
		background-color: #fff;
		border: 1px solid #e8e8e8;
		border-radius: 5px;
	}
	.box_case .case_btn button:not(:last-child) {
		margin-right: 5px;
	}

	/**/
	.swiper-button-next,
	.swiper-button-prev {
		width: 25px !important;
		height: 30px !important;
		transform: translateY(-50%);
		margin-top: 0 !important;
	}

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		/* intro_content */
		.intro_content {
			flex-direction: column;
		}

		.intro_content .intro_img {
			width: 100%;
			padding-top: 56.25%;
			margin: 0;
			margin-bottom: 25px;
			height: auto;
		}

		/* intro_table */
		.intro_table {
			width: 100%;
		}
		.intro_table ul li h4 {
			width: 120px;
		}

		/* box_case */
		.box_case {
			flex-direction: column;
			gap: 10px;
			align-items: start;
		}
		.box_case .case_txt,
		.box_case .case_img {
			margin: 0;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		/* intro_title */
		.intro_title button {
			padding: 10px 15px;
		}

		/* intro_content */
		.intro_content .intro_img {
			margin-bottom: clamp(15px, 3vw, 100px);
		}

		/* intro_table */
		.intro_table ul li h4 {
			width: 80px;
		}
		.intro_table ul li .box_map {
			height: 150px;
		}

		/* case */
		.intro_content.case ul {
			gap: clamp(12px, 2.5vw, 100px);
		}
	}
	// 미디어쿼리 ~600 끝
}
