@charset "utf-8";

.AdminPage_TodayList {
    /*activity_stat*/

    .activity_stat .act_txt .act_img {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .activity_stat .act_txt h2 {
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.5;
    }

    .activity_stat .act_txt span {
        font-size: 1.65rem;
        font-weight: 600;
        color: #0b325d;
    }

    .activity_stat .status_table .date {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .activity_stat .status_table .date select.category {
        width: 160px;
        margin-right: 10px;
        background: url(../../assets/ic_select.png) 90% 50% no-repeat;
    }

    .activity_stat .status_table .date h2 {
        margin-left: auto;
    }

    .activity_stat .status_table .date select {
        width: 100px;
        height: 50px;
        font-size: 1rem;
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0px 10px 0 10px;
        -webkit-appearance: none;
        background: url(../../assets/ic_select.png) 85% 50% no-repeat;
    }

    .activity_stat .status_table .date select.day {
        margin-left: 10px;
    }

    .activity_stat .status_table .date button {
        margin-left: 10px;
        background-color: #1d5d9b;
        width: 100px;
        height: 50px;
        font-weight: 500;
        border-radius: 5px;
        color: #fff;
    }

    /* status_table */
    .status_table h1 {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .status_table span {
        font-size: 1.1rem;
        color: #666;
        font-weight: normal;
    }

    .status_table ul.thead {
        position: relative;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #000;
    }

    .status_table ul.thead li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px;
        background-color: #eee;
        color: #666;
    }

    .status_table ul.tbody {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .status_table ul.tbody li {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #ccc;
    }

    .status_table ul.tbody li p {
        text-align: center;
        align-items: center;
        padding: 15px;
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 500;
        font-size: 0.9rem;
    }

    .status_table ul.thead li:nth-child(1),
    .status_table ul.tbody li p:nth-child(1) {
        border-right: 1px solid #ccc;
        font-weight: normal;
        font-size: 0.9rem;
    }

    .status_table .date button.btn_excel {
        background-color: #fff;
        border: 1px solid #0b325d;
        color: #0b325d;
        margin-left: auto;
    }

    /* act_tab */
    .act_tab ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .act_tab ul li a {
        display: inline-block;
        padding: 15px 30px;
        border: 1px solid #e8e8e8;
        border-radius: 50px;
        font-size: 1.15rem;
        color: #666;
    }

    .act_tab ul li a.on {
        border-color: #1d5d9b;
        background-color: #1d5d9b;
        color: #fff;
        font-weight: 500;
    }

    // 미디어쿼리 ~1024 시작
    @media screen and (max-width: 1024px) {
        .activity_stat .act_txt .act_img img {
            width: 400px;
        }

        .status_table {
            overflow-x: scroll;
        }

        .status_table ul.thead {
            position: relative;
            width: 840px;
            display: flex;
            border-bottom: 1px solid #ccc;
            border-top: 1px solid #000;
        }

        .status_table ul.tbody {
            width: 840px;
            align-items: center;
            justify-content: center;
        }

    }
    // 미디어쿼리 ~1024 끝

    // 미디어쿼리 ~600 시작
    @media screen and (max-width: 600px) {
        .activity_stat .act_txt .act_img img {
            width: 250px;
        }

        .status_table {
            overflow-x: scroll;
        }

        .status_table ul.thead {
            position: relative;
            width: 840px;
            display: flex;
            border-bottom: 1px solid #ccc;
            border-top: 1px solid #000;
        }

        .status_table ul.tbody {
            width: 840px;
            align-items: center;
            justify-content: center;
        }
    }
    // 미디어쿼리 ~600 끝
}