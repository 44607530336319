@charset "utf-8";

/* 등록 로딩css 20231102 추가*/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
}

.loading {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top: 5px solid #1d5d9b;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

form {
    position: relative;
    width: 100%;
}

.comm_write_wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid #000;
}

.comm_write_wrap .cont-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #e8e8e8;
}

.comm_write_wrap .cont-wrap h3,
.comm_write_wrap .cont-wrap .cont-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.comm_write_wrap .cont-wrap h3 {
    width: 150px;
    align-items: center;
    height: 50px;
}

.comm_write_wrap .cont-wrap h3.important::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    float: left;
    margin-right: 10px;
    background-color: #ed3d3d;
}

.comm_write_wrap .cont-wrap .cont-box {
    width: calc(100% - 150px);
}

/* 카테고리*/
.comm_write_wrap .cont-box .select-box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.comm_write_wrap .cont-box .select-box select {
    width: 30%;
    height: 50px;
    box-sizing: border-box;
    line-height: 50px;
    color: #000;
    border: 1px solid #e8e8e8;
    resize: none;
    padding: 0 40px 0 20px;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background: url(../assets/ic_select.png) right 20px center no-repeat;
    font-size: inherit;
}

.comm_write_wrap .cont-box .select-box select::-ms-expand {
    display: none;
}

.comm_write_wrap .cont-wrap input[type="text"] {
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    line-height: 50px;
    color: #000;
    border: 1px solid #e8e8e8;
    background: #fff;
    resize: none;
    padding: 20px;
    border-radius: 5px;
    font-size: inherit;
}

.comm_write_wrap .cont-wrap textarea {
    width: 100%;
    height: 300px;
    background: #fff;
    box-sizing: border-box;
    color: #000;
    border: 1px solid #e8e8e8;
    resize: none;
    padding: 20px;
    border-radius: 5px;
    font-size: inherit;
}


/* 첨부 이미지 */
.comm_write_wrap .cont-box .imgBox {
    width: 120px;
    height: 120px;
    margin-right: 15px;
}

.comm_write_wrap .cont-box .imgBox .upload-photo {
    display: none;
}

.comm_write_wrap .cont-box .imgBox input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}

.comm_write_wrap .cont-box .imgBox label {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    color: #999;
    vertical-align: middle;
    background-color: #fff;
    cursor: pointer;
    height: 120px;
    width: 120px;
    border: 2px dashed #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 5px;
}

.comm_write_wrap .cont-box .imgBox label img {
    margin-bottom: 5px;
}

.comm_write_wrap .cont-box .imgBox label span {
    color: #999;
    font-size: 0.8rem;
}

.comm_write_wrap .cont-box .imglistBox {
    width: calc(100% - 135px);
}

.comm_write_wrap .cont-box .imglistBox ul {
    width: 100%;
    height: 100%;
    font-size: 0;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    background: #fff;
    white-space: nowrap;
}

.comm_write_wrap .cont-box .imglistBox ul li {
    position: relative;
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    display: inline-block;
    border: 1px solid #e8e8e8;
    overflow: hidden;
    border-radius: 5px;
}

.comm_write_wrap .cont-box .imglistBox ul li:not(:first-child) {
    margin-left: 15px;
}

.comm_write_wrap .cont-box .imglistBox ul li img {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.comm_write_wrap .cont-box .imglistBox ul li button {
    position: absolute;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background: url(../assets/ic_close.png) center no-repeat;
    background-color: #fff;
    border-radius: 50%;
    top: 12px;
    right: 12px;
}

/* 날짜 */
.comm_write_wrap .cont-box .date_box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.comm_write_wrap .cont-box .date_box input[type="date"] {
    position: relative;
    width: 50%;
    height: 44px;
    box-sizing: border-box;
    line-height: 44px;
    font-size: 1rem;
    color: #000;
    border: 1px solid #dedede;
    resize: none;
    border-radius: 5px !important;
    padding: 0 15px;
}

/* 기간날짜 */
.comm_write_wrap .cont-box .date_box.period {
    flex-direction: row;
}

.comm_write_wrap .cont-box .date_box.period::before {
    content: '~';
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.comm_write_wrap .cont-box .date_box input[type="date"] {
    width: calc(25% - 10px);
}

.comm_write_wrap .cont-box .date_box input[type="date"].endDate {
    margin-left: 20px;
}

/* 시간 */
.comm_write_wrap .cont-box .time_box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.comm_write_wrap .cont-box .time_box::before {
    content: '~';
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.comm_write_wrap .cont-box .time_box input[type="time"] {
    position: relative;
    width: 50%;
    height: 44px;
    box-sizing: border-box;
    line-height: 44px;
    font-size: 1rem;
    color: #000;
    border: 1px solid #dedede;
    resize: none;
    border-radius: 5px !important;
    padding: 0 15px;
}

.comm_write_wrap .cont-box .time_box input[type="time"] {
    width: calc(25% - 10px);
}

.comm_write_wrap .cont-box .time_box input[type="time"].endTime {
    margin-left: 20px;
}


/* 미디어쿼리 1024 */
@media (max-width: 1024px) {

    .comm_write_wrap .cont-box .date_box input[type="date"],
    .comm_write_wrap .cont-box .time_box input[type="time"],
    .comm_write_wrap .cont-box .select_box select {
        width: 100%;
    }

    /* 기간날짜 */
    .comm_write_wrap .cont-box .date_box.period::before {
        left: 50%;
    }

    /* 시간 */
    .comm_write_wrap .cont-box .time_box::before {
        left: 50%;
    }

}


/* 미디어쿼리 600 */
@media (max-width: 600px) {
    .comm_write_wrap {
        border: none;
    }

    .comm_write_wrap .cont-wrap {
        border: none;
        padding: 0;
    }

    .comm_write_wrap .cont-wrap:not(:last-child) {
        margin-bottom: 20px;
    }

    .comm_write_wrap .cont-wrap h3 {
        width: 100%;
        padding-top: 0;
        padding-bottom: 10px;
        height: inherit;
    }

    .comm_write_wrap .cont-wrap .cont-box {
        width: 100%;
    }

    .comm_write_wrap .cont-box .select-box select,
    .comm_write_wrap .cont-wrap input[type="text"] {
        height: 40px;
        line-height: 40px;
    }

    .comm_write_wrap .cont-box .select-box select {
        padding: 0 30px 0 10px;
        background: url(../assets/ic_select.png) right 10px center no-repeat;
    }

    .comm_write_wrap .cont-wrap input[type="text"],
    .comm_write_wrap .cont-wrap textarea {
        padding: 10px;
    }

    .comm_write_wrap .cont-box .imgBox label img {
        width: 36px;
    }

    .comm_write_wrap .cont-box .imgBox label span {
        font-size: 0.7rem;
    }

    .comm_write_wrap .cont-box .imgBox,
    .comm_write_wrap .cont-box .imgBox label,
    .comm_write_wrap .cont-box .imglistBox ul li {
        width: 100px;
        height: 100px;
    }

    .comm_write_wrap .cont-box .imglistBox {
        width: calc(100% - 115px);
    }


}

/* 첨부 파일 */
.fileBox,
.filelistBox {
    position: relative;
    width: 100%;
}

.fileBox label {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #258cef;
    color: #258cef;
    background-color: #fff;
    font-weight: 600;
    border-radius: 5px;
}
.fileBox input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}

/* 파일리스트 */
.filelistBox {
    position: relative;
    width: 100%;
}
.filelistBox ul {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.filelistBox ul li {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 0;
}

.filelistBox ul li p {
    padding: 0 10px;
    width: 100px;
    text-align: center;
}
.filelistBox ul li p:nth-of-type(1) {
    width: calc(100% - 100px);
    text-align: start;
}
.filelistBox ul li p.delet button {
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #ed3d3d;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #fff;
    margin: 0 3px;
    color: #ed3d3d;
}


@media (max-width: 600px) {

    .filelistBox ul li {
        padding: 15px 0;
    }
    .filelistBox ul li p {
        padding: 0;
        width: 80px;
        text-align: center;
    }
    .filelistBox ul li p:nth-of-type(1) {
        width: calc(100% - 80px);
        text-align: start;
    }

}
