@charset "utf-8";

.List_VillageManagePage {
    .manage_number {
        position: relative;
        width: 100%;
    }

    .manage_number p {
        font-size: 1.15rem;
    }

    .manage_number span {
        font-size: 1.15rem;
        font-weight: 600;
        color: #258cef;
    }

    /* manage_table */
    .manage_table {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .manage_table .thead {
        width: 100%;
        display: inline-block;
        border-top: 2px solid #555;
        border-bottom: 1px solid #dedede;
    }

    .manage_table .thead li {
        float: left;
        position: relative;
        text-align: center;
        color: #666;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
    }

    .manage_table .tbody {
        width: 100%;
        display: inline-block;
    }

    .manage_table .tbody li {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        border-bottom: 1px solid #dedede;
    }

    .manage_table .tbody li p {
        position: relative;
        color: #000;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .manage_table .tbody li p button {
        display: inline-block;
        padding: 5px 10px;
        background-color: #fff;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
    }

    .manage_table .tbody li p button.btn_blue {
        background-color: #f5f8fa;
        border-color: #258cef;
        color: #258cef;
    }

    .manage_table .tbody li p button:not(:last-child) {
        margin-right: 5px;
    }

    /* manage_table set */
    .manage_table .thead li:first-of-type,
    .manage_table .tbody li p:first-of-type {
        width: 20%;
    }

    .manage_table .thead li:nth-of-type(2),
    .manage_table .tbody li p:nth-of-type(2) {
        width: 20%;
    }

    .manage_table .thead li:nth-of-type(3),
    .manage_table .tbody li p:nth-of-type(3) {
        width: 25%;
    }

    .manage_table .thead li:nth-of-type(4),
    .manage_table .tbody li p:nth-of-type(4) {
        width: 35%;
    }

    // 미디어쿼리 ~1024 시작
    @media screen and (max-width: 1024px) {

        /* manage_table */
        .manage_table .thead {
            padding: 0 !important;
            border-bottom: 0 !important;
        }

        .manage_table .thead li {
            display: none;
        }

        .manage_table .tbody li {
            flex-direction: column;
            align-items: start;
            gap: 2px;
        }

        .manage_table .tbody li p {
            text-align: start;
            padding-left: 0;
            padding-right: 0;
        }

        .manage_table .tbody li p.power {
            color: #1d5d9b;
            font-weight: 600;
        }

        /* manage_table set */
        .manage_table .thead li:first-of-type .manage_table .thead li:nth-of-type(2),
        .manage_table .thead li:nth-of-type(3),
        .manage_table .thead li:nth-of-type(4),
        .manage_table .tbody li p:first-of-type,
        .manage_table .tbody li p:nth-of-type(2),
        .manage_table .tbody li p:nth-of-type(3),
        .manage_table .tbody li p:nth-of-type(4) {
            width: 100%;
        }

    }
    // 미디어쿼리 ~1024 끝

}