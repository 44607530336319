@charset "utf-8";

.AdminPage_VillPointDialog {

    .comm_popup .comm_popup_wrap {
        max-width: 800px;
    }

    /* title */
    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }

    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
        vertical-align: baseline !important;
        transition: all .2s;
    }

    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
        transform: rotate(180deg);
    }


    .comm_write_wrap .cont-box .select-box select {
        width: 100% !important;
    }

    .comm_write_wrap .cont-wrap input[type="text"].readonly {
        background-color: #f5f5f5 !important;
    }

    // 미디어쿼리 ~1024 시작
    @media (max-width: 1024px) {

        .comm_popup .comm_popup_wrap {
            width: 90%;
        }

    }
    // 미디어쿼리 ~1024 끝
}