@charset "utf-8";

.Write_EnergyStatusDialog {

	.comm_popup .comm_popup_wrap {
		max-width: 800px;
	}

	/* title */
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all 0.2s;
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	/* content */
	.comm_write_wrap .cont-box .select-box select {
		width: 100%;
	}

	/* 주소 */
	.comm_write_wrap .cont-wrap input.address {
		width: calc(100% - 110px);
		margin-right: 10px;
	}

	.comm_write_wrap .cont-wrap .btn_address {
		width: 100px;
		height: 50px;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #258cef;
		color: #258cef;
		box-sizing: border-box;
		font-weight: 500;
	}

	.comm_write_wrap .cont-wrap input.detail_address {
		margin-top: 10px;
	}

	/* 라디오 */
	.comm_write_wrap .cont-wrap .cont-box {
		min-height: 50px;
		align-items: center;
	}

	.village_list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 15px;
	}

	.village_list li label {
		display: flex;
		align-items: center;
	}

	.village_list li label input[type='radio'] {
		width: 25px;
		height: 25px;
		padding: 0;
		margin-right: 5px;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #e8e8e8;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s;
	}

	.village_list li label input[type='radio']:checked {
		border: 8px solid #258cef;
	}

	.village_list li label p {
		color: #666;
		font-size: 0.9rem;
	}

	.village_list li label input[type='radio']:checked~p {
		color: #000;
	}

	.fileBox,
	.filelistBox {
		position: relative;
		width: 100%;
	}

	/* 파일 */
	.fileBox label {
		display: inline-block;
		padding: 10px 20px;
		cursor: pointer;
		text-align: center;
		border: 1px solid #258cef;
		color: #258cef;
		background-color: #fff;
		font-weight: 600;
		border-radius: 5px;
	}

	.fileBox input[type='file'] {
		position: absolute;
		width: 0;
		height: 0;
		padding: 0;
		overflow: hidden;
		border: 0;
	}

	/* 파일리스트 */
	.filelistBox {
		position: relative;
		width: 100%;
	}

	.filelistBox ul {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.filelistBox ul li {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid #e8e8e8;
		padding: 20px 0;
	}

	.filelistBox ul li p {
		padding: 0 10px;
		width: 100px;
		text-align: center;
	}

	.filelistBox ul li p:nth-of-type(1) {
		width: calc(100% - 100px);
		text-align: start;
	}

	.filelistBox ul li p.delet button {
		padding: 5px 15px;
		border-radius: 5px;
		border: 1px solid #ed3d3d;
		font-size: 0.9rem;
		font-weight: 600;
		background-color: #fff;
		margin: 0 3px;
		color: #ed3d3d;
	}

	// 미디어쿼리 ~1024 시작
	@media (max-width: 1024px) {
		.comm_popup .comm_popup_wrap {
			width: 90%;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media (max-width: 600px) {
		.comm_write_wrap .cont-wrap .btn_address {
			width: 80px;
			height: 40px;
		}

		.comm_write_wrap .cont-wrap input.address {
			width: calc(100% - 90px);
		}
	}
	// 미디어쿼리 ~600 끝
}