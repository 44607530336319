@charset "utf-8";
.MyEPointPage {
	/*my_point*/

	.my_point {
		width: 100%;
		margin: 0 auto;
		background-color: #1d5d9b;
		border-radius: 10px;
	}
	.my_point h4 {
		font-size: 1.25rem;
		font-weight: 500;
		color: #fff;
	}
	.my_point .point1 {
		width: 100%;
	}
	.my_point .point1 .point_btn {
		width: 15px;
		height: 15px;
		background: url(../../assets/ic_next_white.png) center no-repeat;
		margin-left: auto;
	}
	.my_point .line {
		width: 100%;
		height: 1px;
		background-color: #fff;
		margin: 0 auto;
		opacity: 0.4;
	}

	.my_point .point2 {
		font-size: 1rem;
		display: flex;
		align-items: center;
	}
	.my_point .point2 h2 {
		font-size: 1rem;
		opacity: 80%;
		display: flex;
		align-items: center;
		color: #fff;
	}
	.my_point .point2 p {
		margin-left: auto;
		padding-right: 10px;
		opacity: 80%;
		font-weight: normal;
		font-size: 1rem;
		opacity: 80%;
		display: flex;
		align-items: center;
		color: #fff;
	}
	.my_point .point2 img {
		width: 20px;
		height: 20px;
	}

	.my_point .point3 {
		font-size: 1rem;
		display: flex;
		align-items: center;
		padding-top: 0 !important;
	}
	.my_point .point3 h2 {
		color: #fff;
		font-size: 1rem;
		opacity: 80%;
	}
	.my_point .point3 p {
		margin-left: auto;
		padding-right: 10px;
		opacity: 80%;
		font-weight: normal;
		color: #fff;
		font-size: 1rem;
		opacity: 80%;
	}
	.my_point .point3 img {
		width: 20px;
		height: 20px;
	}

	.my_point .point4 {
		font-size: 1.15rem;
		display: flex;
		align-items: center;
		padding-top: 0 !important;
	}
	.my_point .point4 h2 {
		color: #fff;
		font-size: 1.25rem;
		font-weight: 500;
	}
	.my_point .point4 span {
		margin-left: auto;
		padding-right: 10px;
		color: #fff;
		font-size: 1.25rem;
		font-weight: 500;
	}
	.my_point .point4 img {
		width: 20px;
		height: 20px;
	}

	/*title*/
	.title {
		width: 100%;
	}
	.title h3 {
		font-size: 1.15rem;
		font-weight: 500;
	}

	/*.voucher_change*/
	.voucher_change {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 30px;
	}
	.voucher_change,
	.point_gift,
	.title {
		width: 100%;
		margin: 0 auto;
	}
	.voucher_change .btn01,
	.btn02 {
		width: 50%;
		border-radius: 10px;
		position: relative;
		background: #fff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	}
	.voucher_change .btn01 .img_box {
		margin: 0 auto;
		width: 100%;
		height: 100%;
		text-align: center;
	}
	.voucher_change .btn01 .img_box h3 {
		font-size: 1.15rem;
		font-weight: 500;
	}
	.voucher_change .btn02 .img_box {
		margin: 0 auto;
		width: 100%;
		height: 100%;
		text-align: center;
	}
	.voucher_change .btn02 .img_box h3 {
		font-size: 1.15rem;
		font-weight: 500;
	}

	/*point_gift*/
	.point_gift {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 30px;
	}
	.point_gift .btn01 {
		width: 50%;
		border-radius: 10px;
		position: relative;
		background: #fff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	}
	.point_gift .btn01 .img_box {
		margin: 0 auto;
		width: 100%;
		height: 100%;
		text-align: center;
	}
	.point_gift .btn01 .img_box h3 {
		font-size: 1.15rem;
		font-weight: 500;
	}
	.point_gift .btn02 .img_box {
		margin: 0 auto;
		width: 100%;
		height: 100%;
		text-align: center;
	}
	.point_gift .btn02 .img_box h3 {
		font-size: 1.15rem;
		font-weight: 500;
	}

	// 미디어쿼리 ~1080 시작
	@media screen and (max-width: 1080px) {
		.my_point,
		.voucher_change,
		.point_gift {
			width: 100%;
		}
		.my_point .line {
			width: 100%;
		}
	}

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		.my_point .point1 h1 {
			font-size: 1.15rem;
		}
		.my_point .point2,
		.point4 h2 p {
			font-size: 0.85rem;
		}
		.my_point .point4 h2 p {
			font-size: 1rem;
		}

		.voucher_change,
		.point_gift {
			flex-direction: column;
			gap: clamp(15px, 3vw, 100px);
		}
		.voucher_change .btn01,
		.btn02 {
			width: 100%;
			display: flex;
		}
		.voucher_change .btn02 {
			margin: 0;
		}

		.point_gift .btn01,
		.btn02 {
			width: 100%;
			display: flex;
		}
		.point_gift .btn02 {
			margin: 0;
		}
	}
}
