@charset "utf-8";

.Detail_ProgramPage {

    /* 공통 변경사항 */
    .comm_detail_cont p,
    .comm_detail_cont p span {
        font-size: 1.25rem;
        font-weight: 600;
    }

    .comm_detail_cont p span {
        color: #258cef;
    }

    .village_commu_detail{
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    /* program_impor_wrap */
    .program_impor_wrap {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 40px;
    }

    .program_impor_wrap .impor_img {
        position: relative;
        width: 55%;
        margin: 40px 40px 0 0;
        overflow: hidden;
        border-radius: 15px;
        height: 550px;
    }

    .program_impor_wrap .impor_img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
    }

    .program_impor_wrap .impor_txt {
        position: relative;
        width: calc(45% - 40px);
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    /* comm_program */
    .comm_program {
        position: relative;
        width: 100%;
        border-bottom: 1px solid #e8e8e8;
    }

    .comm_program .txt_info {
        color: #ed3d3d;
        margin: 10px 0;
    }

    .comm_program .box_program_state {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }

    /* comm_program form */
    .comm_program form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .comm_program .textbox {
        color: #000;
        width: 100%;
        border: none;
        padding: 55px 30px 30px;
        background: #f5f5f5;
        border-radius: 10px;
        font-size: 1.25rem;
    }

    .comm_program .floating-box {
        position: relative;
    }

    .comm_program .floating-box:before {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translate3d(0, -50%, 0);
        transition: all .15s linear 0s;
    }

    .comm_program .floating-box .floating {
        position: absolute;
        top: 50%;
        left: 30px;
        display: block;
        color: #666;
        transform: translate3d(0, -50%, 0);
        transition: all .15s linear 0s;
        font-size: 1.2rem;
    }

    .comm_program .floating-box .floating span {
        font-size: inherit;
        color: #1d5d9b;
    }

    .comm_program .floating-box.active .floating {
        top: 35%;
        font-size: 1rem;
    }

    .comm_program button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 60px;
        font-size: 1.15rem;
        font-weight: 600;
        border-radius: 5px;
        color: #666;
        width: 100%;
    }

    .comm_program button.on {
        border: 1px solid #1d5d9b;
        color: #fff;
        background: #1d5d9b;
    }

    .comm_program button.my {
        border: 1px solid #e8e8e8;
        color: #000;
        background-color: #fff;
    }

    /* program_info_wrap */
    .program_info_wrap {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .program_info_wrap h3 {
        position: relative;
        font-size: 1.25rem;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .program_info_wrap h3::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #258cef;
        margin-right: 5px;
    }

    /* info_table */
    .info_table {
        position: relative;
        width: 100%;
        border-top: 1px solid #e8e8e8;
    }

    .info_table ul {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .info_table ul li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: #fafafa;
        border-bottom: 1px solid #e8e8e8;
    }

    .info_table ul li h4 {
        color: #666;
        width: 200px;
        text-align: center;
    }

    .info_table ul li p {
        flex: 1 0;
        flex-basis: 0;
        background-color: #fff;
        font-size: 1.15rem;
    }

    /* info_place */
    .info_place {
        position: relative;
        width: 100%;
    }

    .info_place p {
        font-size: 1.15rem;
    }

    .info_place .place_map {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 300px;
    }

    /* info_tool */
    .info_tool ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .info_tool ul li {
        position: relative;
    }

    .info_tool ul li:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        border-right: 1px dashed #e5e5e5;
    }

    .info_tool ul li p.on {
        font-weight: 600;
    }

    /* info_manage */
    .info_manage {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .info_manage .thead {
        width: 100%;
        display: inline-block;
        border-top: 2px solid #555;
        border-bottom: 1px solid #dedede;
    }

    .info_manage .thead li {
        float: left;
        position: relative;
        text-align: center;
        color: #666;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
    }

    .info_manage .tbody {
        width: 100%;
        display: inline-block;
    }

    .info_manage .tbody li {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        border-bottom: 1px solid #dedede;
    }

    .info_manage .tbody li p {
        position: relative;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.15rem;
    }

    .info_manage .tbody li p button {
        border: 1px solid #e8e8e8;
        background-color: #fff;
        color: #000;
        padding: 5px 10px;
        border-radius: 5px;
    }

    .info_manage .tbody li p button.btn_blue {
        border-color: #258cef;
        color: #258cef;
    }

    .info_manage .tbody li p button.btn_red {
        border-color: #ed3d3d;
        color: #ed3d3d;
    }


    .info_manage .tbody li p:nth-of-type(5) {
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .info_manage .tbody li p:nth-of-type(5),
    .info_manage .tbody li p:nth-of-type(5) button {
        display: none;
    }

    .info_manage .tbody li.prev p:nth-of-type(5),
    .info_manage .tbody li.prev p:nth-of-type(5) button.btn_blue,
    .info_manage .tbody li.prev p:nth-of-type(5) button.btn_red {
        display: inline-block;
    }

    .info_manage .tbody li.fix p:nth-of-type(5),
    .info_manage .tbody li.fix p:nth-of-type(5) button.btn_black {
        display: inline-block;
    }

    /* info_manage set */
    .info_manage .thead li:first-of-type,
    .info_manage .tbody li p:first-of-type {
        width: 10%;
    }

    .info_manage .thead li:nth-of-type(2),
    .info_manage .tbody li p:nth-of-type(2) {
        width: 30%;
    }

    .info_manage .thead li:nth-of-type(3),
    .info_manage .tbody li p:nth-of-type(3) {
        width: 10%;
    }

    .info_manage .thead li:nth-of-type(4),
    .info_manage .tbody li p:nth-of-type(4) {
        width: 25%;
    }

    .info_manage .thead li:nth-of-type(5),
    .info_manage .tbody li p:nth-of-type(5) {
        width: 25%;
    }

    // 미디어쿼리 ~1400 시작
    @media screen and (max-width: 1400px){
        .village_commu_detail{
            gap: 40px;
        }
    }
    // 미디어쿼리 ~1400 끝

    // 미디어쿼리 ~1024 시작
    @media screen and (max-width: 1024px) {

        /* program_impor_wrap */
        .program_impor_wrap{
            flex-direction: column-reverse;
        }
        .program_impor_wrap .impor_img {
            width: 100%;
            margin: 0;
            height: 500px;
        }

        .program_impor_wrap .impor_txt {
            width: 100%;
        }

        /* info_table */
        .info_table ul li h4 {
            width: 120px;
        }

        /* info_manage */
        .info_manage .thead {
            padding: 0 !important;
            border-bottom: 0 !important;
        }

        .info_manage .thead li {
            display: none;
        }

        .info_manage .tbody li {
            flex-direction: column;
            align-items: start;
            gap: 2px;
        }

        .info_manage .tbody li p {
            text-align: start;
            padding-left: 0;
            padding-right: 0;
        }

        .info_manage .tbody li p:first-of-type {
            color: #666;
            font-weight: 600;
            font-size: 1rem;
        }

        .info_manage .tbody li.prev p:first-of-type {
            color: #ed3d3d;
        }

        .info_manage .tbody li.fix p:first-of-type {
            color: #1d5d9b;
        }

        /* info_manage set */
        .info_manage .thead li:first-of-type .info_manage .thead li:nth-of-type(2),
        .info_manage .thead li:nth-of-type(3),
        .info_manage .thead li:nth-of-type(4),
        .info_manage .thead li:nth-of-type(5),
        .info_manage .tbody li p:first-of-type,
        .info_manage .tbody li p:nth-of-type(2),
        .info_manage .tbody li p:nth-of-type(3),
        .info_manage .tbody li p:nth-of-type(4),
        .info_manage .tbody li p:nth-of-type(5) {
            width: 100%;
        }
    }
    // 미디어쿼리 ~1024 끝

    // 미디어쿼리 ~600 시작
    @media screen and (max-width: 600px) {

        .impor_img img{
            max-width: 100%;
            max-height: 100%;
        }

        /*comm_program*/
        .comm_program .box_program_state {
            gap: 10px;
        }

        .comm_program .textbox {
            padding: 40px 20px 20px;
        }

        .comm_program .floating-box .floating {
            left: 20px;
        }

        /* info_table */
        .info_table ul li h4 {
            width: 80px;
        }

        /* info_tool */
        .info_tool ul {
            gap: 10px;
        }

        .info_tool ul li:not(:last-child)::after {
            content: '';
            right: -5px;
        }

        /* info_place */
        .info_place .place_map {
            height: 200px;
        }
        .program_impor_wrap {
            position: relative;
            width: 100%;
        }
    }
    // 미디어쿼리 ~600 끝
}