@charset "utf-8";

.JoinInfoPage {

	form {
		position: relative;
		width: 100%;
	}

	.join_info {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 20px;
	}

	.join_info .info_img {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}

	.join_info .info_img li{
		position: relative;
	}

	.join_info .info_img li:not(:last-child) {
		margin-right: 40px;
	}

	.join_info .info_img li label {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.join_info .info_img li label input[type=radio] {
		width: 25px;
		height: 25px;
		padding: 0;
		margin-right: 15px;
		border-radius: 50%;
		background-color: #fff;
		border: 2px solid #e8e8e8;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all .2s;
	}

	.join_info .info_img li label input[type=radio]:checked {
		border: 8px solid #258cef;
	}

	.join_info .info_txt {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		margin-top: 30px;
	}

	.join_info .info_txt li{
		position: relative;
		width: 100%;
	}
	.join_info .info_txt li:not(:first-child) {
		margin-top: 20px;
	}

	.join_info p.txt_blue {
		color: #e6613e;
	}

	.btn_duplicate {
		position: absolute;
		top: 50%;
		right: 30px;
		transform: translate3d(0, -50%, 0);
		transition: all .15s linear 0s;
		width: 70px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		border: 1px solid #1d5d9b;
		border-radius: 5px;
		font-size: 0.85rem;
		color: #1d5d9b;
	}

	.floating-box{
		position: relative;
	}
	.floating-box:before{
		position: absolute;
		top: 50%;
		left: 30px;
		transform: translate3d(0, -50%, 0);
		transition: all .15s linear 0s;
	}
	.floating-box .floating{
		position: absolute;
		top: 50%;
		left: 30px;
		display: block;
		color: #666;
		transform: translate3d(0, -50%, 0);
		transition: all .15s linear 0s;
		font-size: 1.2rem;
	}
	.floating-box .floating span{
		font-size: inherit;
		color: #1d5d9b;
	}
	.floating-box.active .floating{
		top: 35%;
		font-size: 1rem;
	}

	.floating-box.active .btn_duplicate {
		top: auto;
		bottom: 30px;
		transform: translate3d(0, 0, 0);
	}


	.textbox{
		color: #000;
		width: 100%;
		border: none;
		padding: 55px 30px 30px;
		background: #f5f5f5;
		border-radius: 10px;
		font-size: 1.2rem;
	}

	/* 추가사항 */
	.info_txt .cont-wrap {
		position: relative;
		width: 100%;
	}
	.info_txt .cont-wrap h3,
	.info_txt .cont-wrap .cont-box {
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}
	.info_txt .cont-wrap h3 {
		color: #666;
		margin-bottom: 15px;
	}
	.info_txt .cont-wrap h3 span {
		color: #1d5d9b;
	}
	.info_txt .cont-wrap .cont-box {
		width: 100%;
	}

	/* 구, 동 */
	.info_txt .cont-box .select-box {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 20px;
	}
	.info_txt .cont-box .select-box select {
		width: calc(100% / 2 - 10px);
		height: 50px;
		box-sizing: border-box;
		line-height: 50px;
		color: #000;
		border: 1px solid #e8e8e8;
		resize: none;
		padding: 0 40px 0 20px;
		border-radius: 5px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		background: url(../../assets/ic_select.png) right 20px center no-repeat;
		font-size: 1.15rem;
	}

	/* 주소 */
	.info_txt .cont-wrap input[type="text"] {
		position: relative;
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		line-height: 50px;
		color: #000;
		border: 1px solid #e8e8e8;
		background: #fff;
		resize: none;
		padding: 20px;
		border-radius: 5px;
		font-size: inherit;
	}
	.info_txt .cont-wrap input.address {
		width: calc(100% - 100px);
		margin-right: 10px;
	}
	.info_txt .cont-wrap .btn_address {
		width: 90px;
		height: 50px;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #258cef;
		color: #258cef;
		box-sizing: border-box;
		font-weight: 500;
	}
	.info_txt .cont-wrap input.detail_address {
		margin-top: 10px;
	}

	/* 상세주소 check */
	.info_txt .cont-wrap .check-inner {
		position: relative;
		width: 100%;
		display: flex;
	}
	.info_txt .cont-wrap .check-inner li {
		width: auto;
	}
	.info_txt .cont-wrap .check-inner li:not(:first-child) {
		margin-top: 0px;
		margin-left: 20px;
	}

	.info_txt .cont-wrap .check-inner li label {
		font-size: 1.15rem;
	}
	.info_txt .cont-wrap .check-inner li label input[type=radio] {
		width: 25px;
		height: 25px;
		padding: 0;
		margin-right: 10px;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #e8e8e8;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all .2s;
	}
	.info_txt .cont-wrap .check-inner li label input[type=radio]:checked {
		border: 8px solid #258cef;
	}

	/* 상세주소 txt */
	.info_txt .cont-wrap .txt-inner {
		position: relative;
		width: 100%;
		margin-top: 15px;
		display: flex;
		flex-direction: column;
	}
	.info_txt .cont-wrap .txt-inner li {
		width: 100%;
		margin: 0 !important;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.info_txt .cont-wrap .txt-inner li input[type=text] {
		border-color: #f5f5f5;
		background-color: #f5f5f5;
		width: 50%;
	}
	.info_txt .cont-wrap .txt-inner li input[type=text].short {
		width: 85% !important;
		margin-right: 10px;
	}

	/* 미디어쿼리 1024 시작*/
	@media (max-width: 1024px){

		.join_info .info_img li label img{
			width: calc(100% - 40px);
		}

		/* 상세주소 txt */
		.info_txt .cont-wrap .txt-inner li input[type=text] {
			width: 100%;
		}

	}
	// 미디어쿼리 ~1024 끝

	/* 미디어쿼리 800 시작*/
	@media (max-width: 800px){

		.join_info .info_img li {
			width: calc(100%/3 - 80px/3);
		}

	}
	// 미디어쿼리 ~800 끝

	/* 미디어쿼리 600 */
	@media (max-width: 600px){

		.join_info .info_img li label img{
			width: 100%;
		}

		.btn_duplicate {
			right: 20px;
			font-size: 0.8rem;
			width: 64px;
			height: 24px;
		}

		.floating-box .floating {
			left: 20px;
		}

		.floating-box.active .floating{
			top: 35%;
			font-size: 0.85rem;
		}

		.floating-box.active .btn_duplicate {
			bottom: 20px;
		}

		.textbox {
			padding: 40px 20px 20px;
			font-size: 1rem;
		}

		/* 구, 동 */
		.info_txt .cont-box .select-box {
			gap: 10px;
		}
		/* 구, 동 */
		.info_txt .cont-box .select-box select {
			width: calc(100% / 2 - 5px);
		}

		/* 주소 */
		.info_txt .cont-wrap input.address {
			width: calc(100% - 85px);
		}
		.info_txt .cont-wrap .btn_address {
			width: 75px;
		}

	}


}