.ActiveMapComponent {
	position: relative;
	/* 부모 디브에 상대적 위치 설정 */
	width: 100vw;
	/* 화면 너비 100% */
	height: 90vh;
	/* 화면 높이 100% */
	overflow: hidden;
	/* 내용이 넘칠 경우 스크롤 표시하지 않음 */

	.map_inner {
		position: relative;
		width: 100%;
		/* 맵 디브가 부모 디브에 꽉 차게 설정 */
		height: 100%;
		/* 맵 디브가 부모 디브에 꽉 차게 설정 */
	}
	/* 지도 인포윈도우 */
	.map_inner .infoWindow_wrap {
		position: absolute;
		left: 0;
		bottom: 40px;
		width: 288px;
		height: 70px;
		margin-left: -60px;
		margin-bottom: -63px;
		text-align: left;
		overflow: hidden;
		z-index: 500;
		max-width: 250px;
		background: #fff;
		border-radius: 5px;
		padding: 15px;
		border: 1px solid #dedede;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
	}

	.map_inner .myPositionButton {
		height: 25px;
		margin-left: 4px;
		margin-top: 2px;
		position: absolute;
		width: 25px;
		z-index: 2;
	}

	.map_inner .myPositionButton:hover {
		background-color: rgba(128, 117, 117, 0.5);
	}

	.infowindow {
		margin-left: 10px;
	}
}
