@charset "utf-8";
.JoinVillagePage {
	form {
		position: relative;
		width: 100%;
	}

	.village_list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 20px;
	}

	.village_list li {
		width: calc(100% / 5 - 80px / 5);
		margin-right: 20px;
		margin-top: 30px;
	}

	.village_list li:nth-child(-n + 5) {
		margin-top: 0;
	}

	.village_list li:nth-of-type(5n) {
		margin-right: 0;
	}

	.village_list li label {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.village_list li label input[type='radio'] {
		width: 25px;
		height: 25px;
		padding: 0;
		margin-right: 15px;
		border-radius: 50%;
		background-color: #fff;
		border: 1px solid #e8e8e8;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s;
	}

	.village_list li label input[type='radio']:checked {
		border: 8px solid #258cef;
	}

	.village_list li label p {
		width: calc(100% - 40px);
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		color: #666666;
		font-size: 0.9rem;
	}

	.village_list li label p span {
		font-size: 1.2rem;
		margin-top: 2px;
		font-weight: 500;
	}

	/* 미디어쿼리 1024 */
	@media (max-width: 1024px) {
		.village_list li {
			width: calc(100% / 3 - 20px / 3);
			margin-top: 20px;
			margin-right: 10px;
		}

		.village_list li:nth-child(-n + 5) {
			margin-top: 20px;
		}

		.village_list li:nth-of-type(5n) {
			margin-right: 10px;
		}

		.village_list li:nth-child(-n + 3) {
			margin-top: 0px;
		}

		.village_list li:nth-of-type(3n) {
			margin-right: 0px;
		}
	}

	/* 미디어쿼리 600 */
	@media (max-width: 600px) {
		.village_list li {
			width: calc(100% / 2 - 10px / 2);
			margin-top: 20px;
			margin-right: 10px;
		}

		.village_list li:nth-child(-n + 3) {
			margin-top: 20px;
		}

		.village_list li:nth-of-type(3n) {
			margin-right: 10px;
		}

		.village_list li:nth-child(-n + 2) {
			margin-top: 0px;
		}

		.village_list li:nth-of-type(2n) {
			margin-right: 0px;
		}

		.village_list li label p {
			font-size: 0.85rem;
		}
	}
}
