@charset "utf-8";

.Write01_ActivePage {


    .listSelect {
        margin: 12px 0;
    }

    .listSelect label {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .listSelect [type="checkbox"] {
        vertical-align: middle;
        appearance: none;
        border: 1px solid #dedede;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        transition: border 0.2s ease-in-out;
        cursor: pointer;
        margin-right: 8px;
    }

    .listSelect [type="checkbox"]:checked {
        border: 1px solid #258cef;
        background: url(../../../assets/ic_check.png)50% 50% no-repeat;
        background-size: cover;
        background-color: #258cef;
    }

    .listSelect span {
        font-size: 1.2rem;
        color: #555;
    }

    .listSelect [type="checkbox"]:checked~span {
        color: #000;
    }
}