@charset "utf-8";
.PointGiftPage {
	.point_wrap {
		width: 100%;
		border-radius: 15px;
		background-color: #1d5d9b;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 30px;
	}
	.point_wrap p,
	.point_wrap p span {
		color: #fff;
		font-size: 1.15rem;
	}
	.point_wrap p span {
		font-weight: 600;
	}

	.explain_wrap {
		position: relative;
		width: 100%;
	}

	/* 닉네임 */
	.comm_write_wrap .cont-wrap input.address {
		width: calc(100% - 110px);
		margin-right: 10px;
	}
	.comm_write_wrap .cont-wrap .btn_address {
		width: 100px;
		height: 50px;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #258cef;
		color: #258cef;
		box-sizing: border-box;
		font-weight: 500;
	}
	.comm_write_wrap .cont-wrap input.detail_address {
		margin-top: 10px;
	}

	/* 버튼 */
	.comm_btn_wrap2 {
		margin-top: 40px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	.comm_btn_wrap2 button {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 0 10px;
		width: 120px;
		height: 45px;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 5px;
	}
	.comm_btn_wrap2 button.on {
		border: 1px solid #1d5d9b;
		color: #fff;
		background: #1d5d9b;
	}

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		.comm_write_wrap .cont-box .select-box select {
			width: 100%;
		}
	}

	/* 미디어쿼리 600 */
	@media (max-width: 600px) {
		main {
			padding-bottom: clamp(30px, 5vw, 100px) !important;
		}

		/* 닉네임 */
		.comm_write_wrap .cont-wrap .btn_address {
			width: 80px;
			height: 40px;
		}
		.comm_write_wrap .cont-wrap input.address {
			width: calc(100% - 90px);
		}

		/* 버튼 */
		.comm_btn_wrap2 button {
			width: 50%;
		}
	}
}
