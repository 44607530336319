@charset "utf-8";
.List_VillageCommuPage{

    .village_commu_list {
        position: relative;
        width: 100%;
    }
    .village_commu_list ul {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-right: -30px;
    }
    .village_commu_list ul li {
        width: calc(100% / 4 - 30px);
    }

    .village_commu_list ul li a {
        display: inline-block;
        width: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 15px;
    }

    .village_commu_list ul li.non .box_commu h4 {
        color: #666;
    }

    .village_commu_list ul li.non .box_commu h4::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url(../../../assets/ic_non.png)50% 50% no-repeat;
        margin-bottom: -3px;
        margin-right: 5px;
    }

    // 미디어쿼리 ~1500 시작
    @media screen and (max-width: 1500px) {

        .village_commu_list ul {
            gap: 30px;
            margin-right: -30px;
        }
        .village_commu_list ul li {
            width: calc(100% / 3 - 30px);
        }

    }
    // 미디어쿼리 ~1500 끝

    // 미디어쿼리 ~1280 시작
    @media screen and (max-width: 1280px) {

        .village_commu_list ul {
            gap: 20px;
            margin-right: -20px;
        }
        .village_commu_list ul li {
            width: calc(100% / 2 - 20px);
        }
        
    }
    // 미디어쿼리 ~1280 끝

    // 미디어쿼리 ~1024 시작
    @media screen and (max-width: 1024px) {

        .village_commu_list ul {
            gap: 0px;
            margin-right: -0px;
        }
        .village_commu_list ul li {
            width: 100%;
        }
        .village_commu_list ul li:not(:last-child) {
            margin-bottom: 25px;
        }
        
    }
    // 미디어쿼리 ~1024 끝

    // 미디어쿼리 ~600 시작
    @media screen and (max-width: 600px) {

        .village_commu_list ul li:not(:last-child) {
            margin-bottom: clamp(15px, 3vw, 100px) !important;
        }

    }
    // 미디어쿼리 ~600 끝
    
}