@charset "utf-8";


/* comm_popup */
.comm_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
}
.comm_popup .comm_popup_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    background: #fff;
    border-radius: 10px;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.comm_popup .comm_popup_wrap .comm_popup_title {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #000;
}
.comm_popup .comm_popup_wrap .comm_popup_title h3{
    font-size: 1.25rem;
    font-weight: 600;
}

.comm_popup .comm_popup_wrap .comm_popup_content {
    position: relative;
    width: 100%;
}
.comm_popup .comm_popup_wrap .comm_popup_content .explain {
    font-size: 1.15rem;
    color: #666;
}


/* comm_popup_btn */
.comm_popup_btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.comm_popup_btn button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 10px;
    width: 120px;
    height: 45px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
}

.comm_popup_btn button.btn_cancel {
    border: 1px solid #e8e8e8;
    color: #000;
    background: #fff;
}

.comm_popup_btn button.btn_check {
    border: 1px solid #1d5d9b;
    color: #fff;
    background: #1d5d9b;
}
.comm_popup_btn button.one {
    width: 260px;
}

/* 미디어쿼리 600 */
@media (max-width: 600px){
    
    .comm_popup .comm_popup_wrap {
        width: 90%;
    }

    .comm_popup_btn {
        padding: 0;
        justify-content: space-between;
        box-sizing: border-box;
        background: #fff;
    }
    .comm_popup_btn button {
        width: calc(50% - 5px);
        margin: 0;
    }
    .comm_popup_btn button.one {
        width: 100%;
    }

}