@charset "utf-8";

.EnergyConversionVillage {

    .villageListDiv {
        display: flex;
        justify-content: center;
        position: relative;
        height: 700px;
        /* 필요에 따라 높이 설정 */
        width: 100%;
        /* 필요에 따라 너비 설정 */
        color: #cccccc;
    }

    .villageListDiv .map_inner {
        border-radius: 15px;
    }

    .villageListDiv .villageListMap {
        width: 300px;
        height: 300px;
    }

    .villageListDiv .energyMapImg {
        width: 800px;
        height: 600px;
    }

    // .marker {
    //     position: absolute;
    //     width: 40px;
    //     height: 60px;
    //     left: 700px;
    //     top: 100px;
    // }
    .myPosition {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 150px;
        height: 50px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 1px 7px 15px 1px rgba(0, 0, 0, 0.12);
        top: 0px;
        padding: 15px 0px 0px 0px;
    }

    .myPosition .myPosition_txt {
        font-weight: 600;
    }

    h3 {
        text-align: center;
        margin-top: 40px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    // villageListDiv start
    .villageInfoListDiv {
        text-align: center;
        margin-top: 30px;
    }

    .villageInfoListDiv p {
        font-size: 1.2rem;
    }

    .villageInfoListDiv span {
        font-size: 1.25rem;
        color: #1d5d9b;
        font-weight: 600;
    }

    .villageInfoListDiv h3 {
        margin-top: 40px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .villageInfoListDiv .villageListUl {
        margin-top: 40px;
        border-top: 2px #1d5d9b solid;
        position: relative;
    }

    .villageInfoListDiv .villageListUl li {
        width: 100%;
        margin-top: 10px;
        border-bottom: 2px solid #7c7b7b;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        position: relative;
    }

    // .villageInfoListDiv .villageListUl li p.menuTitle {
    //     font-weight: 600;
    //     font-size: 20px;
    // }

    .villageInfoListDiv .villageListUl {
        width: 1000px;
        margin: 0 auto;
    }

    .mobileVillListDiv {
        width: 100%;
        border: 2px #1d5d9b solid;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 0px;
        height: 100%;
        overflow-y: auto;
    }

    .mobileVillListDiv .mobileVillListUl {
        width: 100%;
        margin-bottom: 10px;
    }

    .mobileVillListDiv .mobileVillListUl li {
        width: 100%;
        border-bottom: 1px #d3d3d3 solid;
        padding: 10px;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .mobileVillListDiv .mobileVillListUl li p {
        font-size: 0.8rem;
        font-weight: 600;
        color: #7c7b7b;
        margin-top: 5px;
    }

    // villageListDiv end
    // 미디어쿼리 ~1080 시작
    @media screen and (max-width: 1080px) {
        .villageInfoListDiv {
            text-align: center;
            overflow-x: auto;
            width: 100%;
        }

        .villageInfoListDiv .villageListUl {
            width: 100%;
            margin-left: 0;
            padding: 0 15px;
        }

    }

    .mobileVillListDiv {display: none;}

    @media (max-width: 1024px) {
        .villageListDiv .map_inner {display:none;}
        .mobileVillListDiv {display: block;}
    }
}