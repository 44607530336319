@charset "utf-8";
.Write_VillageHistoryDialog {
	.comm_popup .comm_popup_wrap {
		max-width: 800px;
	}

	/* title */
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all 0.2s;
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	// 미디어쿼리 ~1024 시작
	@media (max-width: 1024px) {
		.comm_popup .comm_popup_wrap {
			width: 90%;
		}
	}
	// 미디어쿼리 ~600 끝
}
