@charset "utf-8";
.InfoDialog {
	.comm_popup .comm_popup_wrap {
		max-width: 800px;
	}

	/* comm_popup_title */
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all 0.2s;
	}
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	/* comm_popup_content */
	.comm_popup_content p {
		font-size: 1.15rem;
	}
	.comm_popup_content p.time {
		font-size: 1rem;
		color: #666;
	}
	.comm_popup_content img {
		display: block;
		width: 100%;
	}

	@media (max-width: 1024px) {
		.comm_popup .comm_popup_wrap {
			width: 90%;
		}
	}
}
