@charset "utf-8";
.CarbonCalculatorPage {
	.pa20 ::placeholder {
		color: lightgray;
	}
	/* wrapper */
	.wrapper {
		width: 1000px;
		margin: 0 auto;
	}

	/* electric_gas*/
	.electric_gas {
		width: 100%;
		background: #104171;
		border-radius: 10px;
		display: flex;
		align-items: center;
		text-align: center;
		gap: 30px;
	}

	/* electric_box */
	.electric_box {
		width: 50%;
	}
	.electric_box h1 {
		font-size: 1.8rem;
		font-weight: 700;
		color: #fff;
	}

	/* blue_box */
	.blue_box {
		width: 100%;
		background: #e8f4ff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.blue_box p {
		font-size: 1.35rem;
		font-weight: 600;
		display: contents;
	}
	.blue_box input {
		width: calc(100% - 60px);
		border: 0;
		background: #fff;
		border-radius: 15px;
		font-size: 1.4rem;
		font-weight: 500;
		color: #666;
		text-align: end;
	}
	.blue_box h1 {
		font-size: 1.4rem;
		font-weight: 500;
		color: #000;
	}

	/* blue_box01 */
	.blue_box01 {
		width: 100%;
		background: #e8f4ff;
		border-radius: 10px;
	}
	.blue_box01 h1 {
		font-size: 1.4rem;
		color: #000;
		font-weight: 500;
	}
	.blue_box01 img {
		padding: 10px;
	}

	/* white_box */
	.white_box {
		width: 100%;
		background: #fff;
		border-radius: 15px;
	}
	.white_box h1 {
		font-size: 1.8rem;
		font-weight: 700;
	}

	/* img_box */
	.img_box {
		width: 100%;
		justify-content: center;
		display: flex;
	}

	// 미디어쿼리 ~1080 시작
	@media screen and (max-width: 1080px) {
		.wrapper {
			width: 100%;
		}
	}
	// 미디어쿼리 ~1080 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		.electric_gas {
			width: 100%;
			flex-direction: column;
		}

		.electric_box,
		.blue_box {
			width: 100%;
		}
	}
	// 미디어쿼리 ~1024 끝
}
