@charset "utf-8";

.EnergyAnalysisPage {

    .wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
    }

    /* search_box */
    .search_box {
        width: 400px;
        background-color: #fafafa;
        border-radius: 10px;
    }

    .search_box select {
        width: 170px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        -webkit-appearance: none;
        background: #fff url(../../assets/ic_select.png) right 20px bottom 50% no-repeat;
        padding-left: 10px;
    }

    .search_box h1 {
        color: #666;
    }

    .search_box .select01 select {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        -webkit-appearance: none;
        background: #fff url(../../assets/ic_select.png) right 20px bottom 50% no-repeat;
    }

    .search_box button {
        width: 360px;
        height: 50px;
        border-radius: 5px;
        background: #1d5d9b;
        color: #fff;
        font-weight: 500;
    }

    /* select_box */
    .select_box01 {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .select_box02 {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .select-box select {
        width: 50%;
    }

    /* tabs */
    .tab_box {
        width: calc(100% - 440px);
        margin-left: 40px;
    }

    .tabs {
        position: relative;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #e8e8e8;
        background-color: #f5f5f5;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tabs button {
        width: calc(100% / 3);
        height: 100%;
        border-radius: 25px;
        background-color: #f5f5f5;
    }

    .tabs button.tab_active {
        border: 1px solid #1d5d9b;
        background-color: #fff;
        color: #1d5d9b;
        font-weight: 600;
    }

    .tab_content.hidden {
        display: none;
    }

    .tab_content .txt {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;

    }

    .tab_content .txt p {
        font-size: 1.4rem;
    }

    .tab_content .txt .txt_blue {
        color: #258cef;
    }

    .tab_content .txt span {
        font-weight: 500;
        font-size: 1.4rem;
    }

    /* energy_consumption */

    .energy_consumption img {
        width: 200px;
        height: 215px;
    }

    .energy_consumption {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* graph_wrap */
    .graph_wrap {
        width: 100%;
        background: #fff;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
    }

    .graph_wrap h1 {
        font-size: 1.25rem;
        font-weight: 500;
    }

    .graph_wrap .img_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // 미디어쿼리 ~1024 시작
    @media screen and (max-width: 1024px) {

        .search_box {
            width: 100%;
            margin-bottom: 40px;
        }

        .search_box select {
            width: 100%;
            gap: 10px;
        }

        .search_box button {
            width: 100%;
        }

        .wrapper {
            width: auto;
            flex-direction: column;
        }

        .tab_box {
            width: 100%;
            margin: 0;
        }

        .tab_content {
            width: auto;
        }

        .energy_consumption img {
            width: auto;
        }

        .graph_wrap {
            width: auto;
        }

        .graph_wrap img {
            width: 100%;
            display: block;
        }


    }
    // 미디어쿼리 ~1024 끝

    // 미디어쿼리 ~600 시작
    @media screen and (max-width: 600px) {

        .graph_wrap h1 {
            font-size: 1rem;
        }

        .energy_consumption {
            flex-direction: column;

        }

    }
    // 미디어쿼리 ~600 끝
}