@charset "utf-8";

.List02_ActivePage {
	/* 공통 변경사항 */
	.comm_write_button {
		margin-left: auto;
	}
	.comm_search ul li {
		width: 100%;
	}
	.comm_search .comm_search_plus input {
		width: 100%;
	}

	.village_record_list {
		position: relative;
		width: 100%;
	}
	.village_record_list ul {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		margin-right: -30px;
	}
	.village_record_list ul li {
		width: calc(100% / 4 - 30px);
	}

	.village_record_list ul li a {
		display: inline-block;
		width: 100%;
		border: 1px solid #e8e8e8;
		border-radius: 15px;
	}

	.village_record_list ul li.non .box_record h4 {
		color: #666;
	}

	.village_record_list ul li.non .box_record h4::before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url(../../../assets/ic_non.png) 50% 50% no-repeat;
		margin-bottom: -3px;
		margin-right: 5px;
	}

	/* record_info */
	.record_info {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
	}
	.record_info a {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 25px;
		height: 30px;
	}
	.record_info a img {
		display: block;
		height: 15px;
	}

	.record_info .record_title_box {
		position: relative;
	}
	.record_info .record_title_box h3 {
		font-size: 1.25rem;
		font-weight: 600;
	}
	.record_info .record_title_box p {
		color: #666;
	}
	.record_info .record_title_box p.txt {
		color: #000;
	}
	.record_info .record_title_box img {
		display: block;
		width: 50%;
		border-radius: 5px;
	}
	.record_title_box_con{
		display: flex;
		width: 400px;
	}

	.record_info .record_btn_box {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	.record_info .record_btn_box button {
		width: 30px;
		height: 30px;
		background: #fff;
		border: 1px solid #e8e8e8;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}

	// 미디어쿼리 ~1500 시작
	@media screen and (max-width: 1500px) {
		.village_record_list ul li {
			width: calc(100% / 3 - 30px);
		}
	}
	// 미디어쿼리 ~1500 끝

	// 미디어쿼리 ~1280 시작
	@media screen and (max-width: 1280px) {
		.village_record_list ul {
			gap: 20px;
			margin-right: -20px;
		}
		.village_record_list ul li {
			width: calc(100% / 2 - 20px);
		}
	}
	// 미디어쿼리 ~1280 시작

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		.village_record_list ul {
			gap: 0px;
			margin-right: -0px;
		}
		.village_record_list ul li {
			width: 100%;
		}
		.village_record_list ul li:not(:last-child) {
			margin-bottom: 25px;
		}

		/* record_info */
		.record_info .record_title_box a {
			width: 35px;
			height: 35px;
			background: #fff;
			border: 1px solid #e8e8e8;
			border-radius: 50%;
			margin-right: 10px;
		}
		.record_info .record_title_box img {
			width: 100%;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		.village_record_list ul li:not(:last-child) {
			margin-bottom: clamp(15px, 3vw, 100px) !important;
		}

		.record_info .record_btn_box {
			position: absolute;
			left: -15px;
			top: 40px;
			flex-direction: column;
			gap: 10px;
		}
	}
	// 미디어쿼리 ~600 끝

	// 미디어쿼리 ~490 시작
	@media screen and (max-width: 490px) {
		.record_title_box{
			width: 300px;
		}
		.record_title_box_con{
			width: 300px;
		}
	}
	// 미디어쿼리 ~490 끝
}
