@charset "utf-8";

.ShareDialog {

	/*유튜브 전용*/
	.share_popup.youtube .white_box {
		padding: 0;
		border-radius: 0;
		background: none;
	}

	.share_popup.youtube .white_box .popup_title {
		display: flex;
		justify-content: end;
	}

	.share_popup.youtube .white_box .popup_title .popup_close {
		position: relative;
		transform: inherit;
	}

	.share_popup.youtube .white_box .popup_title .popup_close img {
		width: 35px;
	}

	.share_popup.youtube .white_box .popup_content {
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		overflow: hidden;
	}

	.share_popup.youtube .white_box .popup_content iframe {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		min-height: 100%;
		border: 0;
	}

	/* 공유하기 팝업 */
	.share_popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 2000;
	}

	.share_popup .white_box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		max-width: 400px;
		background: #fff;
		padding: 20px;
		border-radius: 10px;
	}

	.share_popup .white_box .popup_title {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.1rem;
		font-weight: 600;
		color: #000;
	}

	.share_popup .white_box .popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}

	.share_popup .white_box .popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all .2s;
	}

	.share_popup .white_box .popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	.share_popup .white_box .share_sns {
		padding-top: 20px;
		text-align: center;
		margin: 0 auto;
		font-size: 0;
	}

	.share_popup .white_box .share_sns>span {
		width: 60px;
		height: 60px;
		display: inline-block;
		margin: 0 15px;
		cursor: pointer;
		font-size: 0.8rem;
		text-align: center;
	}

	.share_popup .white_box .share_sns>span>a {
		display: inline-block;
	}

	.share_popup .white_box .share_sns>span>a img {
		width: 100%;
		height: 100%;
		display: block;
		padding-bottom: 5px;
		transition: all .2s;
	}

	.share_popup .white_box .share_sns>span>a img:hover {
		opacity: .9;
	}
}