@charset "utf-8";

/* 프리텐다드 */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css');

/* 노토산스 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url("https://fonts.googleapis.com/earlyaccess/notosanskr.css");

.popupCheckbox {
    -webkit-appearance: checkbox !important;
    margin-left: 5px;
    margin-right: 3px;
}

html,
body {
    width: 100%;
    height: 100%;
}

/* common */
* {
    margin: 0;
    padding: 0;
    font-family: 'Pretendard', sans-serif;
    box-sizing: border-box;
    word-break: break-all;
}

html,
body,
div,
span,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
a,
address,
pre,
cite,
form,
fieldset,
table,
th,
td,
legend,
label,
button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1rem;
    font-family: 'Pretendard', sans-serif;
    color: #000;
}

input,
textarea,
select {
    margin: 0;
    padding: 0;
    font-family: 'Pretendard', sans-serif;
}

input,
textarea {
    -webkit-appearance: none !important;
}

select::-ms-expand {
    display: none;
}

a,
button {
    cursor: pointer;
}

a,
a:link {
    text-decoration: none;
}

body {
    line-height: 120%;
    color: #222;
    letter-spacing: -.04em;
    -webkit-text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    line-height: 1.3em
}

fieldset,
img,
abbr,
acronym,
label {
    border: 0 none;
}

ol,
ul,
dl {
    list-style: none;
}

hr {
    display: none;
}

fieldset {
    border: 0
}

label,
select,
input,
textarea {
    vertical-align: middle
}

.notext {
    display: none;
}

.cf::after {
    content: '';
    clear: both;
    display: block;
}

.txt_cut1,
.txt_cut2,
.txt_cut3,
.txt_cut4,
.txt_cut5 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.txt_cut1 {
    -webkit-line-clamp: 1;
    white-space: nowrap;
    /* 라인수 */
    height: 1.3em;
    display: block;
}

.txt_cut2 {
    -webkit-line-clamp: 2;
    /* 라인수 */
    height: 2.6em;
}

.txt_cut3 {
    -webkit-line-clamp: 3;
    /* 라인수 */
    height: 3.9em;
}

.txt_cut4 {
    -webkit-line-clamp: 4;
    /* 라인수 */
    height: 5.2em;
}

.txt_cut5 {
    -webkit-line-clamp: 5;
    /* 라인수 */
    height: 6.5em;
}

select,
input {
    outline: none;
}

/* 페이징 형식 */

/* 1~5
    <div id="" class="">
        <p>
            <span class="numPN m_ar"><a  data-page="1" title="처음 페이지로 이동하기"><img src="image/ic_prev2.png" alt="처음 페이지"></a></span>
            <span class="numPN over left"><a  data-page="1" title="이전 페이지로 이동하기"><img src="image/ic_prev.png" alt="이전 페이지"></a></span>
            <span class="Present"><a class="on"  data-page="1">1</a></span>
            <span><a  data-page="2">2</a></span>
            <span><a  data-page="3">3</a></span>
            <span><a  data-page="4">4</a></span>
            <span><a  data-page="5">5</a></span>
            <span class="numPN  over right"><a  data-page="11" title="다음 페이지로 이동하기"><img src="image/ic_next.png" alt="다음 페이지"></a></span>
            <span class="numPN m_ar"><a  data-page="14" title="마지막 페이지로 이동하기"><img src="image/ic_next2.png" alt="마지막 페이지"></a></span>
        </p>
    </div>
*/


/* 페이징1 */
#paging1 {
    clear: both;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

#paging1 p {
    display: flex;
    align-items: center;
    justify-content: center;
}

#paging1 p span a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    line-height: 30px;
    margin: 0 2px;
    color: #666;
    font-weight: 400;
    text-decoration: none;
    transition: all .2s;
    background-color: #f5f5f5;
    border-radius: 5px;
}

#paging1 p span:first-child a {
    margin-left: 0;
}

#paging1 p span a:hover {
    color: #1d5d9b;
    font-weight: 700;
}

#paging1 .numPN a {
    width: 35px;
    height: 35px;
}

#paging1 .numPN a img {
    width: 10px;
    height: 10px;
}

#paging1 .Present a {
    color: #fff;
    font-weight: 600;
    background-color: #1d5d9b;
}

#paging1 .Present a:hover {
    color: #fff;
}

/* 페이징1 */
.paging1 {
    clear: both;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

.paging1 p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paging1 p span a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    line-height: 30px;
    margin: 0 2px;
    color: #666;
    font-weight: 400;
    text-decoration: none;
    transition: all .2s;
    background-color: #f5f5f5;
    border-radius: 5px;
}

.paging1 p span:first-child a {
    margin-left: 0;
}

.paging1 p span a:hover {
    color: #1d5d9b;
    font-weight: 700;
}

.paging1 .numPN a {
    width: 35px;
    height: 35px;
}

.paging1 .numPN a img {
    width: 10px;
    height: 10px;
}

.paging1 .Present a {
    color: #fff;
    font-weight: 600;
    background-color: #1d5d9b;
}

.paging1 .Present a:hover {
    color: #fff;
}

/* 페이징2 */
#paging2 {
    clear: both;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

#paging2 p {
    display: flex;
    align-items: center;
    justify-content: center;
}

#paging2 p span a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 2px;
    font-size: 0.85rem;
    color: #000;
    font-weight: 400;
    text-decoration: none;
    transition: all .2s;
}

#paging2 p span:first-child a {
    margin-left: 0;
}

#paging2 p span a:hover {
    color: #5ec556;
    font-weight: 700;
}

#paging2 .numPN a {
    width: 24px;
    height: 30px;
}

#paging2 .numPN a img {
    width: 10px;
    height: 10px;
}

#paging2 .Present a {
    color: #5ec556;
    font-weight: 700;
}

#paging2 .Present a:hover {
    color: #fff;
}


/* 페이징2 */
.paging2 {
    clear: both;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

.paging2 p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paging2 p span a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 2px;
    font-size: 0.85rem;
    color: #000;
    font-weight: 400;
    text-decoration: none;
    transition: all .2s;
}

.paging2 p span:first-child a {
    margin-left: 0;
}

.paging2 p span a:hover {
    color: #5ec556;
    font-weight: 700;
}

.paging2 .numPN a {
    width: 24px;
    height: 30px;
}

.paging2 .numPN a img {
    width: 10px;
    height: 10px;
}

.paging2 .Present a {
    color: #5ec556;
    font-weight: 700;
}

.paging2 .Present a:hover {
    color: #fff;
}

/*ie용 스크롤 바*/
html {
    scrollbar-arrow-color: none;
    scrollbar-Track-Color: #f0f0f0;
    scrollbar-base-color: #ccc;
    scrollbar-Face-Color: #ccc;
    scrollbar-3dLight-Color: none;
    scrollbar-DarkShadow-Color: none;
    scrollbar-Highlight-Color: #ccc;
    scrollbar-Shadow-Color: #ccc;
}

/* Chrome, Safari용 스크롤 바 */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.no-scroll {
    -ms-overflow-style: none;
    /* IE, Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scroll::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

@media screen and (max-width: 600px) {

    html,
    body,
    div,
    span,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote,
    p,
    a,
    address,
    pre,
    cite,
    form,
    fieldset,
    table,
    th,
    td,
    legend,
    label,
    button {
        font-size: 0.85rem;
    }

    #paging1 p span a,
    .paging1 p span a {
        width: 30px !important;
        height: 30px !important;
    }

}