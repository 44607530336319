@charset "utf-8";
.JoinAgreePage {
	form {
		position: relative;
		width: 100%;
	}

	.terms_box {
		position: relative;
		width: 100%;
		margin-top: 20px;
	}

	.terms_box ul {
		display: inline-block;
		width: 100%;
	}

	.terms_box ul li:not(:first-child) {
		margin-top: 40px;
		width: 100%;
	}

	.terms_box ul li > label {
		position: relative;
		display: flex;
		align-items: center;
	}

	.terms_box ul li.all_check > label {
		background: #f5f5f5;
		padding: 30px;
		border-radius: 10px;
		transition: all 0.2s;
	}

	.terms_box ul li.all_check > label:has(input[type='checkbox']:checked) {
		background: #258cef;
	}

	.terms_box ul li > label input[type='checkbox'] {
		background: url(../../assets/ic_check_gray.png) center no-repeat;
		background-size: cover;
		width: 16px;
		height: 13px;
		margin-right: 20px;
		transition: all 0.2s;
	}

	.terms_box ul li > label input[type='checkbox']:checked {
		background: url(../../assets/ic_check_blue.png) center no-repeat;
	}

	.terms_box ul li.all_check > label input[type='checkbox']:checked {
		background: url(../../assets/ic_check_white.png) center no-repeat;
	}

	.terms_box ul li > label span {
		font-size: 1.2rem;
		font-weight: 500;
		transition: all 0.2s;
	}

	.terms_box ul li.all_check > label input[type='checkbox']:checked + span {
		color: #fff;
		font-weight: 600;
	}

	.terms_box ul li > label span strong {
		font-size: inherit;
		color: #1d5d9b;
	}

	.terms_box .terms_cont {
		position: relative;
		width: 100%;
		height: 180px;
		padding: 30px;
		border-radius: 10px;
		background: #fff;
		overflow-x: hidden;
		overflow-y: auto;
		border: 1px solid #e8e8e8;
		margin-top: 20px;
	}

	.terms_box .terms_cont p {
		font-size: 1rem;
		color: #666;
		line-height: 1.5;
	}

	/* 미디어쿼리 600 시작*/
	@media (max-width: 600px) {
		.terms_box ul li:not(:first-child) {
			margin-top: 30px;
		}

		.terms_box ul li.all_check > label {
			padding: 20px;
		}

		.terms_box ul li > label span {
			font-size: 1rem;
		}

		.terms_box .terms_cont {
			padding: 20px;
		}
	}
	// 미디어쿼리 ~600 끝
}
