@charset "utf-8";

.mt40 {
    margin-top: 40px !important;
}

.mt25 {
    margin-top: 25px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mb25 {
    margin-bottom: 25px !important;
}

.mt5 {
    margin-top: 5px !important;
}

.pa30 {
    padding: 30px !important;
}

.pa20 {
    padding: 20px !important;
}

/* infoWindow_wrap */
.infoWindow_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 200px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 1px 7px 15px 1px rgba(0, 0, 0, .12);
}

.infoWindow_wrap::after {
    content: ' ';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -14px;
    border: 15px solid transparent;
    border-bottom-width: 0;
    border-top-color: #fff;
}

.infoWindow_wrap h3 {
    font-size: 1.15rem;
    font-weight: 500;
}

.infoWindow_wrap p {
    color: #666;
}

.infoWindow_wrap p.category {
    font-weight: 600;
    color: #1d5d9b;
}

/* comm_noList */
.comm_noList {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

.comm_noList p {
    color: #999;
    font-size: 1.15rem;
}

/* comm_write_button */
.comm_write_button {
    background-color: #1d5d9b;
    height: 50px;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 1.25rem;
    color: #fff;
}

.comm_write_button.small_white,
.comm_write_button.small_blue {
    height: 40px;
    font-size: inherit;
}

.comm_write_button.small_white {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    color: #000;
    margin-right: 5px;
}


/* comm_village */
.comm_village {
    position: relative;
    display: inline-block;
    color: #ed3d3d;
    border-radius: 5px;
    border: 1px solid #ed3d3d;
    padding: 5px 10px;
}

.comm_village.news::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../assets/ic_news.png);
    background-size: cover;
    margin-bottom: -3px;
    margin-right: 3px;
}

/* comm_title */
.comm_title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
}

/* ic_comm_heart, ic_comm_comment */
.ic_comm_heart,
.ic_comm_comment {
    position: relative;
    color: #666;
}

.ic_comm_heart::before,
.ic_comm_comment::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: -4px;
    margin-right: 5px;
}

.ic_comm_heart::before {
    background: url(../assets/ic_heart.png) 50% 50% no-repeat;
}

.ic_comm_comment::before {
    background: url(../assets/ic_comment.png) 50% 50% no-repeat;
}

/* comm_tab */
.comm_tab {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comm_tab ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 10px;
}

.comm_tab ul li a {
    display: inline-block;
    padding: 10px 2vw;
    border: 1px solid #e8e8e8;
    border-radius: 50px;
    font-size: 1.25rem;
    color: #666;
}

.comm_tab ul li a.on {
    border-color: #1d5d9b;
    background-color: #1d5d9b;
    color: #fff;
    font-weight: 500;
}

/* comm_search */
.comm_search {
    position: relative;
    width: 100%;
    padding: 30px 40px;
    background-color: #fbfbfb;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.comm_search ul {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}

.comm_search ul li {
    display: flex;
    align-items: center;
}

.comm_search ul li:nth-child(2) {
    flex: 1;
}

.comm_search input,
.comm_search select {
    font-size: 1rem;
}

.comm_search .comm_select01 {
    width: 150px;
    border: 1px solid #e8e8e8;
    height: 50px;
    border-radius: 5px;
    padding: 0 50px 0 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #fff url(../assets/ic_select.png) right 20px bottom 50% no-repeat;
}

.comm_search .comm_select02 {
    width: 150px;
    border: 0;
    border-right: 1px dashed #e8e8e8;
    height: 100%;
    border-radius: 5px;
    padding: 0 50px 0 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #fff url(../assets/ic_select.png) right 20px bottom 50% no-repeat;
}

.comm_search button {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background: #1d5d9b url(../assets/btn_search.png)50% 50% no-repeat;
    text-indent: -9999px;
    margin-left: 15px;
}

.comm_search .comm_search_plus {
    width: calc(100% - 50px);
    border: 1px solid #e8e8e8;
    background-color: #fff;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
}

.comm_search .comm_search_plus input {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0 20px;
}

/* comm_tool */
.comm_tool {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.comm_tool ul {
    display: flex;
    align-items: center;
    gap: 20px;
}

.comm_tool ul li {
    position: relative;
}

.comm_tool ul li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px dashed #e5e5e5;
}

.comm_tool ul li p.on {
    font-weight: 600;
}

.comm_btn_wrap {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


/* comm_btn_wrap */
.comm_btn_wrap button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 10px;
    width: 120px;
    height: 45px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
}

.comm_btn_wrap button.btn_cancel {
    border: 1px solid #e8e8e8;
    color: #000;
    background: #fff;
}

.comm_btn_wrap button.btn_next {
    border: 1px solid #f5f5f5;
    color: #666;
    background: #f5f5f5;
}

.comm_btn_wrap button.btn_next.on {
    border: 1px solid #1d5d9b;
    color: #fff;
    background: #1d5d9b;
}

/* info */
.info01,
.info02 {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.info01 p {
    position: relative;
    color: #666;
}

.info01 p:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px dashed #e5e5e5;
}

.info01 p.name {
    position: relative;
    display: flex;
    align-items: center;
}

.info01 p.name::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 7px;
}

.info01 p.name.basic1::before {
    content: '';
    background: url(../assets/character01.png)50% 50% no-repeat;
    background-size: cover;
}

.info01 p.name.basic2::before {
    content: '';
    background: url(../assets/character02.png)50% 50% no-repeat;
    background-size: cover;
}

.info01 p.name.Lv1::before {
    content: '';
    background: url(../assets/character03.png)50% 50% no-repeat;
    background-size: cover;
}

.info01 p.name.Lv2::before {
    content: '';
    background: url(../assets/character04.png)50% 50% no-repeat;
    background-size: cover;
}

.info01 p.name.Lv3::before {
    content: '';
    background: url(../assets/character05.png)50% 50% no-repeat;
    background-size: cover;
}

.info01 p.name.Lv4::before {
    content: '';
    background: url(../assets/character06.png)50% 50% no-repeat;
    background-size: cover;
}

.info01 a {
    position: relative;
}

.info01 a.txt_blue {
    color: #258cef;
}

.info01 a:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px dashed #e5e5e5;
}

/* replyCommentDiv */
.replyCommentDiv,
.replyCommentDiv {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.replyCommentDiv p {
    position: relative;
    color: #666;
}

.replyCommentDiv p:not(:last-child)::after {
    /* content: ''; */
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px dashed #e5e5e5;
}

.replyCommentDiv p.name {
    position: relative;
    display: flex;
    align-items: center;
}

.replyCommentDiv p.name::before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 7px;
}

.replyCommentDiv p.name.basic1::before {
    content: '';
    background: url(../assets/character01.png)50% 50% no-repeat;
    background-size: cover;
}

.replyCommentDiv p.name.basic2::before {
    content: '';
    background: url(../assets/character02.png)50% 50% no-repeat;
    background-size: cover;
}

.replyCommentDiv p.name.Lv1::before {
    content: '';
    background: url(../assets/character03.png)50% 50% no-repeat;
    background-size: cover;
}

.replyCommentDiv p.name.Lv2::before {
    content: '';
    background: url(../assets/character04.png)50% 50% no-repeat;
    background-size: cover;
}

.replyCommentDiv p.name.Lv3::before {
    content: '';
    background: url(../assets/character05.png)50% 50% no-repeat;
    background-size: cover;
}

.replyCommentDiv p.name.Lv4::before {
    content: '';
    background: url(../assets/character06.png)50% 50% no-repeat;
    background-size: cover;
}

.replyCommentDiv a {
    position: relative;
}

.replyCommentDiv a.txt_blue {
    color: #258cef;
}

.replyCommentDiv a:not(:last-child)::after {
    /* content: ''; */
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    border-right: 1px dashed #e5e5e5;
}

/* comm_detail_title */
.comm_detail_title {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.comm_btn_set {
    margin-left: auto;
}

.comm_btn_set button {
    display: inline-block;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
}

.comm_btn_set button:not(:last-child) {
    margin-right: 5px;
}

.comm_btn_set button.non {
    background-color: #f5f8fa;
    border-color: #258cef;
    color: #258cef;
}

.comm_btn_set button.non::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../assets/ic_non.png)50% 50% no-repeat;
    margin-bottom: -3px;
    margin-right: 5px;
}

.comm_btn_set button.pub {
    background-color: #f5f8fa;
    border-color: #258cef;
    color: #258cef;
}

.comm_btn_set button.pub::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../assets/ic_pub.png)50% 50% no-repeat;
    margin-bottom: -3px;
    margin-right: 5px;
}

/* comm_detail_cont */
.comm_detail_cont {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 2px solid #000;
    border-bottom: 1px solid #e8e8e8;
    font-size: 1.25rem;
}

.comm_detail_cont p {
    font-size: 1.25rem;
}

.comm_detail_cont img {
    display: block;
    max-width: 70%;
    margin: 0 auto;
}

.comm_detail_cont .fileList {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}

.comm_detail_cont .fileList a {
    position: relative;
    width: 100%;
    color: #1d5d9b;
    text-decoration: underline;
    font-size: 1.15rem;
}

.comm_detail_cont .fileList a::before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url(../assets/ic_download.png)50% 50% no-repeat;
    background-size: cover;
    margin-right: 10px;
    margin-bottom: -4px;
}

/* comm_detail_btn */
.comm_detail_btn {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.comm_detail_btn a,
.comm_detail_btn p {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background-color: #f5f5f5;
    border-radius: 50px;
}

.comm_detail_btn a.btn_list {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    margin-left: auto;
}

.comm_detail_btn a::before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    margin-bottom: -3px;
    width: 20px;
    height: 20px;
}

.comm_detail_btn a.btn_share::before {
    background: url(../assets/btn_share.png) 50% 50% no-repeat;
    background-size: cover;
}

.comm_detail_btn a.btn_list::before {
    background: url(../assets/btn_list.png) 50% 50% no-repeat;
    background-size: cover;
}

.comm_detail_btn p {
    margin-right: 10px;
}

.comm_detail_btn p input[type='checkbox'] {
    display: none;
}

.comm_detail_btn p input[type='checkbox']+label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.comm_detail_btn p input[type='checkbox']+label span {
    display: inline-block;
    margin-right: 5px;
    margin-top: -3px;
    width: 20px;
    height: 20px;
    background: url(../assets/btn_heart.png) 50% 50% no-repeat;
    background-size: cover;
    cursor: pointer;
}

.comm_detail_btn p input[type='checkbox']:checked+label span {
    background: url(../assets/btn_heart_on.png) 50% 50% no-repeat;
    background-size: cover;
}

/* comm_comment_write */
.comm_comment_write {
    position: relative;
    width: 100%;
}

.comm_comment_write h5 {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.comm_comment_write h5 span {
    color: #1d5d9b;
    font-weight: 500;
}

.comm_box_write {
    position: relative;
    width: 100%;
    border: 1px solid #e8e8e8;
    background-color: #fff;
}

.comm_box_write textarea {
    width: 100%;
    border: 0;
    resize: none;
    font-size: 1.15rem;
    height: clamp(60px, 14vw, 85px)
}

.comm_box_write .write_info {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.comm_box_write .write_info p {
    color: #666;
}

.replyComm_box_write {
    position: relative;
    width: 100%;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    margin: 5px 0px 5px 30px;
}

.replyComm_box_write textarea {
    width: 100%;
    border: 0;
    resize: none;
    font-size: 1.15rem;
    height: clamp(60px, 14vw, 30px)
}

.replyComm_box_write .write_info {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.replyComm_box_write .write_info p {
    color: #666;
}

/* 대댓글 비공개 공개 */
.rePlyNon {
    color: #666;
}

.rePlyNon::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../assets/ic_non.png)50% 50% no-repeat;
    margin-bottom: -3px;
    margin-right: 5px;
}

/* comm_comment_list */
.comm_comment_list,
.comm_comment_list ul,
.comm_comment_list ul li {
    position: relative;
    width: 100%;
}

.comm_comment_list .comm_box_list h5 {
    font-size: 1.15rem;
}

.comm_comment_list .non .comm_box_list h5 {
    color: #666;
}

.comm_comment_list .non .comm_box_list h5::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../assets/ic_non.png)50% 50% no-repeat;
    margin-bottom: -3px;
    margin-right: 5px;
}

@media screen and (max-width: 1024px) {
    .replyComm_box_write {
        position: relative;
        width: 100%;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        margin: 5px 0px 5px 0px;
    }

    /* comm_tab */
    .comm_tab {
        position: fixed;
        width: 100%;
        padding: 20px 40px;
        top: 80px;
        left: 0;
        background-color: #fff;
        border-bottom: 1px solid #e8e8e8;
        z-index: 100;
    }

    .comm_tab ul {
        width: 100%;
        gap: inherit;
        justify-content: space-between;
    }

    .comm_tab ul li a {
        padding: 10px 0;
        border: 0;
        font-size: 1.25rem;
    }

    .comm_tab ul li a.on {
        padding: 10px 15px;
    }

    /* comm_search */
    .comm_search {
        padding: 20px;
    }

    .comm_search ul {
        flex-direction: column;
        align-items: start;
    }

    .comm_search ul li {
        width: 100%;
    }

    .comm_search ul li:nth-child(2) {
        flex: auto;
    }

    .comm_search .comm_select01,
    .comm_search .comm_search_plus,
    .comm_search button {
        height: 40px;
    }

    .comm_search .comm_search_plus {
        width: calc(100% - 45px);
    }

    .comm_search .comm_select01 {
        width: 50%;
        padding: 0 20px 0 10px;
        background-position: right 10px bottom 50%;
        background-color: #fff;
    }

    .comm_search .comm_select02 {
        width: 22vw;
        min-width: 80px;
        padding: 0 20px 0 10px;
        background-position: right 10px bottom 50%;
        background-color: #fff;
    }

    .comm_search button {
        width: 40px;
        margin-left: 10px;
    }

    .comm_search .comm_search_plus input {
        padding: 0 10px;
        width: auto;
        min-width: calc(100% - 80px);
    }


}

@media screen and (max-width: 600px) {
    .replyComm_box_write {
        position: relative;
        width: 100%;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        margin: 5px 0px 5px 0px;
    }

    .mt40 {
        margin-top: clamp(25px, 5vw, 100px) !important;
    }

    .mt25 {
        margin-top: clamp(15px, 3vw, 100px) !important;
    }

    .mt10 {
        margin-top: clamp(6px, 1.5vw, 100px) !important;
    }

    .mb25 {
        margin-bottom: clamp(15px, 3vw, 100px) !important;
    }

    .pa30 {
        padding: clamp(20px, 4vw, 100px) !important;
    }

    .pa20 {
        padding: clamp(10px, 2vw, 100px) !important;
    }

    /* info */
    .info01 {
        flex-wrap: wrap;
        gap: 10px;
    }

    /* comm_write_button */
    .comm_write_button {
        height: 40px;
    }

    .comm_write_button.small_white,
    .comm_write_button.small_blue {
        height: 35px;
    }

    /* comm_tab */
    .comm_tab {
        top: clamp(45px, 12vw, 80px);
        padding: 12px;
        height: 12vw;
        min-height: 50px;
    }

    .comm_tab ul {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }

    .comm_tab ul li {
        display: inline-block;
    }

    .comm_tab ul li a {
        width: 90px;
        text-align: center;
    }

    .comm_tab ul li a.on {
        padding: 7px 15px;
    }

    /* comm_search */
    .comm_search {
        padding: 10px;
    }

    /* comm_btn_wrap */
    .comm_btn_wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 20px 12px;
        justify-content: space-between;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, .1);
    }

    .comm_btn_wrap button {
        width: calc(50% - 5px);
        margin: 0;
    }

    /* comm_detail_title */
    .comm_detail_title {
        flex-wrap: wrap;
        gap: 10px;
    }

    /* comm_detail_cont */
    .comm_detail_cont img {
        max-width: 100%;
    }

}