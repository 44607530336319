@charset "utf-8";
.Detail_MapMissionPage {
	.none {
		display: none !important;
	}

	.missionVillage_map_wrap {
		position: relative;
		width: 100%;
		height: 760px;
		border: 1px solid #e8e8e8;
		border-radius: 10px;
	}

	.missionVillage_map {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	.missionVillage_map .mobile_menu_inner {
		display: none;
	}

	.missionVillage_map .left_inner {
		position: relative;
		width: 350px;
		height: 100%;
		border-right: 1px solid #e8e8e8;
		transition: all 0.3s;
	}

	.missionVillage_map .left_inner .btn_left {
		display: inline-block;
		width: 25px;
		height: 50px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		position: absolute;
		top: 50%;
		right: -26px;
		transform: translateY(-50%);
		z-index: 101;
		background: url("data:image/svg+xml,%3Csvg id='' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.17 36.34'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000222;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='18.17 2 2 18.17 18.17 34.34'/%3E%3C/svg%3E")
			50% 50% no-repeat #fff;
		background-size: 6px;
		box-shadow: 6px 2px 7px 0 rgb(0 0 0 / 10%);
		transition: 0.5s;
	}

	.missionVillage_map .left_inner .btn_close {
		display: none;
		width: 40px;
		height: 40px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		position: absolute;
		top: 25px;
		left: 700px;
		z-index: 2;
		background: url("data:image/svg+xml,%3Csvg id='' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41.02 38.98'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23222;stroke:%23222;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M201.68,218a1.6,1.6,0,0,1-1.1,2.76,1.57,1.57,0,0,1-1.1-.44L182.17,204,164.86,220.3a1.6,1.6,0,0,1-2.26-.06,1.6,1.6,0,0,1,.06-2.26l17.18-16.23-17.18-16.23a1.6,1.6,0,0,1,2.2-2.32l17.31,16.35,17.31-16.35a1.6,1.6,0,1,1,2.2,2.32L184.5,201.75Z' transform='translate(-161.66 -182.26)'/%3E%3C/svg%3E")
			50% 50% no-repeat #fff;
		background-size: 13px;
		box-shadow: 6px 2px 7px 0 rgb(0 0 0 / 10%);
		transition: 0.5s;
	}

	.missionVillage_map .left_inner .left_box {
		position: relative;
		width: 350px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.missionVillage_map .left_detail_box {
		position: relative;
		width: 350px;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: #fff;
	}

	.missionVillage_map.on .left_inner {
		width: 700px;
		display: flex;
		flex-direction: row;
	}

	.missionVillage_map.on .map_inner {
		width: calc(100% - 700px);
	}
	// 231113 하영 do반장 lifemap scss 가져옴
	/* 마커리스트 */
	.missionVillage_map .map_inner .findMarkerList {
		background-color: white;
		position: absolute;
		border: 1px solid #e0e0e0;
		// border-top-right-radius: 5px;
		// border-top-left-radius: 5px;
		border-radius: 5px;
		z-index: 700;
		margin-left: 10px;
		width: 160px;
	}

	.missionVillage_map .map_inner .findMarkerList ul li {
		border-bottom: 1px solid #e0e0e0;
		padding: 7px;
		cursor: pointer;
	}

	.missionVillage_map .map_inner .findMarkerList ul li:last-child {
		border-bottom: 0;
	}

	.missionVillage_map .map_inner .findMarkerList ul li:hover {
		text-decoration: underline;
		color: #5ec556;
	}

	.missionVillage_map .map_inner .findMarkerList ul li p {
		width: 130px;
		overflow: hidden;
		/* 넘친 내용을 감춤 */
		white-space: nowrap;
		/* 줄 바꿈 없이 텍스트를 한 줄에 표시 */
		text-overflow: ellipsis;
		color: #000;
		font-size: 0.85rem;
	}

	.missionVillage_map .map_inner .findMarkerList ul li:hover p {
		color: #5ec556;
	}

	.missionVillage_map .map_inner .closeList {
		display: flex;
		background-color: #5ec556;
		padding: 5px 10px;
		flex-direction: row;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
	}

	.missionVillage_map .map_inner .closeList p {
		color: #fff;
		font-size: 0.85rem;
	}

	.missionVillage_map .map_inner .closeList a {
		color: #fff;
		margin-left: auto;
	}
	// 마커리스트 끝
	.missionVillage_map.on .left_inner .left_box {
		border-right: 1px solid #e8e8e8;
	}

	.missionVillage_map.on .left_inner .btn_close {
		display: inline-block;
	}

	.missionVillage_map.off .map_inner {
		width: 100%;
	}

	.missionVillage_map.off .left_inner {
		width: 0;
		border: none;
		transition: all 0.3s;
	}

	.missionVillage_map.off .left_inner .btn_close {
		display: none;
	}

	.missionVillage_map.off .left_inner .btn_left {
		background: url("data:image/svg+xml,%3Csvg id='' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.17 36.34'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23000222;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2 34.34 18.17 18.17 2 2'/%3E%3C/svg%3E")
			50% 50% no-repeat #fff;
		background-size: 6px;
		right: -25px;
	}

	.missionVillage_map.on.off .map_inner {
		width: 100%;
	}

	.missionVillage_map.on.off .left_inner {
		width: 0;
		border: none;
		transition: all 0.3s;
	}

	.missionVillage_map .map_inner {
		position: relative;
		width: calc(100% - 350px);
		height: 100%;
		transition: all 0.3s;
		// background: url(../../assets/map.jpg) 50% 50% no-repeat;
		background-size: cover;
	}

	/* 등록하기 버튼 */
	.missionVillage_map .btn_list {
		position: absolute;
		right: 30px;
		bottom: 30px;
		z-index: 11;
	}

	.missionVillage_map .btn_list > a {
		display: block;
		position: relative;
		padding: 12px 20px 12px 45px;
		border-radius: 50px;
		border: 1px solid #258cef;
		background-color: #fff;
		font-size: 1rem;
		box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
	}

	.missionVillage_map .btn_list > a.mobile_btn {
		display: none;
	}

	.missionVillage_map .btn_list > a.btn_maplist::after {
		content: '';
		width: 14px;
		height: 16px;
		overflow: hidden;
		background: url(../../assets/btn_maplist.png) center no-repeat;
		display: block;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);
	}

	.missionVillage_map .btn_list > a.btn_map::after {
		content: '';
		width: 15px;
		height: 18px;
		overflow: hidden;
		background: url(../../assets/btn_map.png) center no-repeat;
		display: block;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);
	}

	.missionVillage_map .btn_list > a.btn_write::after {
		content: '';
		width: 13px;
		height: 20px;
		overflow: hidden;
		background: url(../../assets/btn_write.png) center no-repeat;
		display: block;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);
	}

	/* 제목 */
	.left_box .title {
		position: relative;
		width: 100%;
		padding: 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.left_box .title .btn_back {
		width: 15px;
		height: 15px;
		background: url(../../assets/ic_back.png) center no-repeat;
		margin-right: 10px;
		background-size: contain;
	}

	.left_box .title h2 {
		display: inline-block;
		width: calc(100% - 120px);
		font-size: 1.2rem;
		font-weight: 600;
	}

	.left_box .map_btn_wrap {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	.left_box .map_btn_wrap button {
		width: 30px;
		height: 30px;
		margin-left: 8px;
		background: #fff;
		border: 1px solid #e8e8e8;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/* 검색 */
	.left_box form {
		position: relative;
		width: 100%;
	}

	.left_box form .category {
		padding: 0 20px;
		display: flex;
		flex-wrap: wrap;
	}

	.left_box form .category select {
		width: 100%;
		height: 46px;
		box-sizing: border-box;
		line-height: 46px;
		font-size: 1.2rem;
		color: #000;
		border: 1px solid #e8e8e8;
		resize: none;
		padding: 0 40px 0 20px;
		margin-bottom: 10px;
		border-radius: 5px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		background: url(../../assets/ic_select.png) right 20px center no-repeat;
		font-size: inherit;
	}

	.left_box form .search {
		position: relative;
		margin: 0px 20px 20px;
	}

	.left_box form .search input {
		width: 100%;
		height: 46px;
		line-height: 46px;
		padding: 0 50px 0 20px;
		border-radius: 5px;
		border: 2px solid #258cef;
		box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
		font-size: 1.2rem;
		color: #000;
	}

	.left_box form .search input::placeholder {
		font-size: 1rem;
		color: #666;
	}

	.left_box form .search button {
		position: absolute;
		top: 50%;
		right: 20px;
		width: 18px;
		height: 18px;
		transform: translateY(-50%);
		background: url(../../assets/btn_search_blue.png) 50% 50% no-repeat;
		background-size: cover;
	}

	/* 지도 리스트 */
	.web_list {
		flex: 1 1 auto;
		width: 100%;
		display: flex;
		height: 100%;
		flex-direction: column;
	}

	.left_box .list {
		flex: 1 0 0;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		flex-basis: 0;
	}

	.left_box .list ul {
		width: 100%;
	}

	.left_box .list ul li,
	.left_box .list ul li a,
	.left_box .list ul li a .list_table {
		width: 100%;
	}

	.left_box .list ul li a {
		display: inline-block;
		padding: 20px;
		box-sizing: border-box;
		border-bottom: 1px solid #e8e8e8;
		background: #fff;
		transition: all 0.2s;
	}

	.left_box .list ul li a:hover,
	.left_box .list ul li a.on {
		background: #f7f9fa;
	}

	.left_box .list ul li a .list_table {
		display: flex;
		flex-wrap: wrap;
	}

	/* 이미지 */
	.list_table > .img {
		width: 80px;
		height: 80px;
		border-radius: 3px;
		margin-left: 20px;
	}

	.list_table .img_box {
		position: relative;
		width: 100%;
		padding-top: 100%;
		border: 1px solid #f5f6f7;
		border-radius: 3px;
		overflow: hidden;
	}

	.list_table .img_box img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		margin: auto;
	}

	.list_table .img_box .number {
		position: absolute;
		bottom: 0px;
		right: 0px;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top-left-radius: 3px;
		color: #fff;
		font-size: 0.85rem;
		background-color: rgba(0, 0, 0, 0.6);
	}

	/* 글 */
	.list_table .txt {
		position: relative;
		width: calc(100% - 100px);
	}

	.list_table.noimg .txt {
		width: 100%;
	}
	.list_table.noimg .img {
		display: none;
	}

	.list_table .txt p.cate {
		position: relative;
		width: 100%;
		color: #666;
		font-size: 0.85rem;
		margin-bottom: 4px;
	}

	.list_table .txt h5 {
		width: 100%;
		font-size: 1.1rem;
	}

	.non .list_table .txt h5 {
		color: #666;
	}

	.non .list_table .txt h5::before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url(../../assets/ic_non.png) 50% 50% no-repeat;
		margin-bottom: -3px;
		margin-right: 5px;
	}

	.list_table .txt p.address {
		line-height: 1em;
		position: relative;
		display: block;
		width: 100%;
		font-size: 0.85rem;
		color: #666;
		margin-top: 10px;
	}

	.list_table .txt p.info b {
		font-weight: normal;
	}

	.list_table .txt.none p.info {
		display: none;
	}

	#paging1,
	.paging1 {
		padding: 20px;
	}

	#paging1 .numPN a,
	#paging1 p span a {
		width: 30px;
		height: 30px;
	}

	/* 상세 */
	.left_detail_wrap {
		position: relative;
		width: 100%;
	}

	.comm_btn_set_wrap {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.comm_btn_set button.delet {
		border-color: #ed3d3d;
		color: #ed3d3d;
	}

	.comm_detail_cont {
		border: 0;
	}

	.comm_detail_cont p {
		font-size: 1.1rem;
	}
	.comm_detail_cont p.village {
		color: #1d5d9b;
		font-weight: 600;
	}
	.comm_detail_cont p.cate {
		position: relative;
		width: 100%;
		color: #666;
		font-size: 0.85rem;
	}

	.comm_detail_cont p.address {
		line-height: 1em;
		position: relative;
		display: block;
		width: 100%;
		font-size: 0.85rem;
		color: #666;
	}

	.comm_detail_cont img {
		max-width: 100%;
	}

	.comm_box_write textarea {
		font-size: 1rem;
		height: 60px;
	}

	.comm_write_button {
		height: 40px;
		font-size: inherit;
	}

	.info01,
	.info02 {
		gap: 10px;
	}

	.info01 p,
	.info01 a {
		font-size: 0.85rem;
	}

	.comm_comment_list .comm_box_list h5 {
		font-size: 1rem;
	}

	.comm_comment_list .non .comm_box_list h5 {
		color: #666;
	}
	.comm_comment_list .non .comm_box_list h5::before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url(../../assets/ic_non.png) 50% 50% no-repeat;
		margin-bottom: -3px;
		margin-right: 5px;
	}

	#container {
		width: 1500px !important;
	}

	// 미디어쿼리 ~1500 시작
	@media screen and (max-width: 1500px) {
		#container {
			width: 100% !important;
			padding: 0 40px !important;
		}
	}
	// 미디어쿼리 ~1500 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		padding: 0 !important;
		padding-top: 80px !important;

		#container {
			padding: 0 !important;
			height: 100%;
		}
		#content {
			padding-bottom: 0;
			height: 100%;
		}

		.missionVillage_map_wrap {
			margin: 0 !important;
			border: 0;
			height: 100%;
		}

		.mobile_menu_inner .left_detail_box {
			width: 100%;
		}

		/* 레이아웃 */
		.missionVillage_map .left_inner {
			display: none !important;
		}

		.missionVillage_map .map_inner {
			width: 100% !important;
		}

		.missionVillage_map .mobile_menu_inner {
			display: block;
			flex-direction: inherit;
		}

		/* 버튼 */
		.missionVillage_map .btn_list {
			left: 50%;
			right: inherit;
			transform: translateX(-50%);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			bottom: 20px;
		}

		.missionVillage_map .btn_list > a.mobile_btn {
			display: flex;
			margin-right: 10px;
		}

		/* 검색 */
		.mobile_box_wrap form {
			position: relative;
			width: 100%;
		}

		.mobile_box_wrap form .category {
			position: relative;
			margin-bottom: 10px;
		}

		.mobile_box_wrap form .category select {
			width: 100%;
			height: 40px;
			box-sizing: border-box;
			line-height: 40px;
			font-size: 1.2rem;
			color: #000;
			border: 1px solid #e8e8e8;
			resize: none;
			padding: 0 30px 0 10px;
			border-radius: 5px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			outline: none;
			background: url(../../assets/ic_select.png) right 10px center no-repeat;
			font-size: inherit;
		}

		.mobile_box_wrap form .search {
			position: relative;
			margin-bottom: 10px;
		}

		.mobile_box_wrap form .search input {
			width: 100%;
			height: 40px;
			line-height: 40px;
			padding: 0 40px 0 10px;
			border-radius: 5px;
			border: 2px solid #258cef;
			box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
			font-size: 1.2rem;
			color: #000;
		}

		.mobile_box_wrap form .search input::placeholder {
			font-size: 1rem;
			color: #666;
		}

		.mobile_box_wrap form .search button {
			position: absolute;
			top: 50%;
			right: 10px;
			width: 18px;
			height: 18px;
			transform: translateY(-50%);
			background: url(../../assets/btn_search_blue.png) 50% 50% no-repeat;
			background-size: cover;
		}

		/* 지도 리스트 */
		.mobile_box_wrap {
			width: 100%;
			height: calc(100% - 10px);
			padding: 20px 12px;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background: #fff;
			transition: top 0.25s ease-out;
			will-change: top;
			z-index: 10;
			overflow: hidden;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		}

		.mobile_box_wrap.open {
			top: 10px;
		}

		.mobile_box_wrap.close {
			top: calc(100%);
		}

		.mobile_list {
			width: 100%;
			height: calc(100% - 145px);
			overflow-x: hidden;
			overflow-y: auto;
		}

		.mobile_box_wrap .list {
			width: 100%;
		}

		.mobile_box_wrap .list ul {
			width: 100%;
		}

		.mobile_box_wrap .list ul li,
		.mobile_box_wrap .list ul li a {
			width: 100%;
		}

		.mobile_box_wrap .list ul li a .list_table {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			width: 100%;
		}

		.mobile_box_wrap .list ul li a .list_table .txt {
			width: 100%;
		}

		/* .mobile_box_wrap .list ul li{margin-top: 25px;} */
		/* .mobile_box_wrap .list ul li:first-child{margin-top: 0;} */
		.mobile_box_wrap .list ul li a {
			display: inline-block;
			padding: 18px 10px;
			box-sizing: border-box;
			border-bottom: 1px solid #dbdee0;
			background: #fff;
			transition: all 0.2s;
		}

		/* 모바일 지도상세 팝업 */
		.detail_popup {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1999;
		}

		.detail_popup .white_box {
			width: 100%;
			height: 100%;
			max-width: 100%;
			position: relative;
			overflow-x: hidden;
			overflow-y: auto;
		}

		.detail_popup .white_box .detail_popup_close {
			display: block;
			width: 20px;
			height: 20px;
			background: url("data:image/svg+xml,%3Csvg id='' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41.02 38.98'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23222;stroke:%23222;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M201.68,218a1.6,1.6,0,0,1-1.1,2.76,1.57,1.57,0,0,1-1.1-.44L182.17,204,164.86,220.3a1.6,1.6,0,0,1-2.26-.06,1.6,1.6,0,0,1,.06-2.26l17.18-16.23-17.18-16.23a1.6,1.6,0,0,1,2.2-2.32l17.31,16.35,17.31-16.35a1.6,1.6,0,1,1,2.2,2.32L184.5,201.75Z' transform='translate(-161.66 -182.26)'/%3E%3C/svg%3E")
				50% 50% no-repeat;
			background-size: cover;
			z-index: 201;
		}

		/* 모바일지도 팝업버튼 */
		.mobile_menu_inner .map_btn_popup {
			position: absolute;
			top: 15px;
			left: 15px;
			display: flex;
			align-items: center;
			margin-left: auto;
			gap: 10px;
			z-index: 10;
		}

		.mobile_menu_inner .map_btn_popup button {
			width: 40px;
			height: 40px;
			background: #fff;
			border: 1px solid #e8e8e8;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.mobile_menu_inner .map_btn_popup button.btn_back img {
			width: 10px;
		}
	}
	// 미디어쿼리 ~1024 끝

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		padding-top: clamp(45px, 12vw, 75px) !important;

		#content {
			padding: 0 !important;
		}

		.detail_popup .white_box .detail_popup_close {
			margin: 5px;
		}

		/* 모바일지도 팝업버튼 */
		.mobile_menu_inner .map_btn_popup {
			gap: 5px;
		}
	}
	// 미디어쿼리 ~600 끝
}
