@charset "utf-8";

.AdminPage_StoreDialog {

    .comm_popup .comm_popup_wrap {
        max-width: 800px;
    }

    /* title */
    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }

    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
        vertical-align: baseline !important;
        transition: all .2s;
    }

    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
        transform: rotate(180deg);
    }


    /* content */
    .comm_write_wrap .cont-box .select-box select {
        width: 100%;
    }

    /* 주소 */
    .comm_write_wrap .cont-wrap input.address {
        width: calc(100% - 110px);
        margin-right: 10px;
    }

    .comm_write_wrap .cont-wrap .btn_address {
        width: 100px;
        height: 50px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #258cef;
        color: #258cef;
        box-sizing: border-box;
        font-weight: 500;
    }

    .comm_write_wrap .cont-wrap input.detail_address {
        margin-top: 10px;
    }

    // 미디어쿼리 ~1024 시작
    @media (max-width: 1024px) {

        .comm_popup .comm_popup_wrap {
            width: 90%;
        }

    }
    // 미디어쿼리 ~1024 끝

    // 미디어쿼리 ~600 시작
    @media (max-width: 600px) {

        .comm_write_wrap .cont-wrap .btn_address {
            width: 80px;
            height: 40px;
        }

        .comm_write_wrap .cont-wrap input.address {
            width: calc(100% - 90px);
        }
    }
    // 미디어쿼리 ~600 끝
}