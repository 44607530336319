@charset "utf-8";

.state_list {
    position: relative;
    width: 100%;
}
.state_list ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.state_list ul li label {
    display: flex;
    flex-wrap: wrap;
}

.state_list ul li label input[type=radio] {
    width: 25px;
    height: 25px;
    padding: 0;
    margin-right: 15px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
}

.state_list ul li label input[type=radio]:checked {
    border: 8px solid #258cef;
}


.state_list li label p {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 1.15rem;
}

.state_list li label p input[type=text] {
    width: 100%;
    border: 0;
    background-color: #f5f5f5;
    border-radius: 5px;
    font-size: 1.15rem;
}
