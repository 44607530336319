@charset "utf-8";

.List01_ActivePage {
  /* 공통 변경사항 */
  .comm_write_button {
    margin-left: auto;
  }

  .comm_search ul li {
    width: 100%;
  }

  .comm_search .comm_search_plus input {
    width: 100%;
  }

  .village_actList_list {
    position: relative;
    width: 100%;
  }

  .village_actList_list ul {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  .village_actList_list ul li {
    position: relative;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
  }

  /* actList_title */
  .actList_title {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actList_title .title_txt {
    width: calc(100% - 230px);
  }

  .actList_title .title_txt p {
    color: #666;
  }

  .actList_title .title_txt p span {
    color: #666;
    margin-right: 10px;
  }

  .actList_title a {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #258cef;
    color: #258cef;
    font-weight: 600;
  }

  .actList_title a::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 15px;
    background: url(../../../assets/ic_left_blue.png) 50% 50% no-repeat;
    margin-left: 10px;
    margin-bottom: -2px;
  }

  /* actList_content */
  .actList_content {
    border-top: 1px solid #e8e8e8;
  }

  /* non */
  .village_actList_list ul li.non h3,
  .village_actList_list ul li.non h4,
  .village_actList_list ul li.non p {
    color: #666;
  }

  .village_actList_list ul li.non h3::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../../../assets/ic_non.png) 50% 50% no-repeat;
    margin-bottom: -3px;
    margin-right: 5px;
  }

  // 미디어쿼리 ~600 시작
  @media screen and (max-width: 600px) {
    .village_actList_list ul {
      gap: clamp(20px, 4vw, 100px) !important;
    }
  }
  // 미디어쿼리 ~600 끝
}
