@charset "utf-8";

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
	position: absolute;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
}

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
	vertical-align: baseline !important;
	transition: all 0.2s;
}

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
	transform: rotate(180deg);
}

.comm_popup .comm_popup_wrap .loginBox {
	text-align: center;
}
.comm_popup .comm_popup_wrap .loginBox span {
	width: 60px;
	height: 60px;
	display: inline-block;
	margin: 0 15px;
	cursor: pointer;
	text-align: center;
}

.comm_popup .comm_popup_wrap .loginBox span > a {
	display: inline-block;
}

.comm_popup .comm_popup_wrap .loginBox span > a img {
	width: 100%;
	height: 100%;
	display: block;
	padding-bottom: 5px;
	transition: all 0.2s;
}

.comm_popup .comm_popup_wrap .loginBox span > a img:hover {
	opacity: 0.9;
}
