@charset "utf-8";
.VillGiftCardPage {
	.point_wrap {
		width: 100%;
		border-radius: 15px;
		background-color: #1d5d9b;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 30px;
	}
	.point_wrap p,
	.point_wrap p span {
		color: #fff;
		font-size: 1.15rem;
	}
	.point_wrap p span {
		font-weight: 600;
	}

	.gift_wrap {
		position: relative;
		width: 100%;
	}
	.gift_wrap ul {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		margin-right: -30px;
	}
	.gift_wrap ul li {
		width: calc(100% / 2 - 30px);
	}

	.gift_wrap ul li a {
		position: relative;
		display: inline-block;
		width: 100%;
		border: 1px solid #e8e8e8;
		border-radius: 15px;
	}
	.gift_wrap ul li a::after {
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		right: 75px;
		border-left: 1px dashed #e8e8e8;
	}
	.gift_wrap ul li a::before {
		content: '';
		display: block;
		width: 14px;
		height: 25px;
		background: url(../../../assets/mobile_left.png) 50% 50% no-repeat;
		background-size: cover;
		position: absolute;
		top: 50%;
		right: 30px;
		transform: translateY(-50%);
	}

	.gift_wrap ul li a .gift_inner {
		position: relative;
		width: 100%;
		padding-right: 60px;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.gift_wrap ul li a .gift_inner p {
		position: relative;
		color: #666;
	}
	.gift_wrap ul li a .gift_inner p.point {
		display: flex;
		align-items: center;
		color: #000;
	}
	.gift_wrap ul li a .gift_inner p.point::before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../../assets/ic_point.png) 50% 50% no-repeat;
		background-size: cover;
		margin-right: 5px;
	}

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		.gift_wrap ul {
			gap: 0px;
			margin-right: -0px;
		}
		.gift_wrap ul li {
			width: 100%;
		}
		.gift_wrap ul li:not(:last-child) {
			margin-bottom: 25px;
		}
	}

	/* 미디어쿼리 600 */
	@media (max-width: 600px) {
		main {
			padding-bottom: clamp(30px, 5vw, 100px) !important;
		}

		.gift_wrap ul li:not(:last-child) {
			margin-bottom: clamp(15px, 3vw, 100px);
		}
		.gift_wrap ul li a::before {
			right: 20px;
		}
		.gift_wrap ul li a::after {
			right: 55px;
		}
		.gift_wrap ul li a .gift_inner {
			padding-right: 50px;
		}
	}
}
