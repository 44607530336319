@charset "utf-8";

.WithdrawalDialog{
    .comm_popup_content p span {
        color: #ed3d3d;
        font-weight: 500;
    }

    .box_point {
        position: relative;
        width: 100%;
        border-radius: 10px;
        background-color: #1d5d9b;
    }
    .box_point h4,
    .box_point p {
        color: #fff;
    }
    .box_point h4 {
        opacity: .8;
        margin-bottom: 5px;
    }
    .box_point p {
        font-size: 1.15rem;
    }


    .box_check label {
        position: relative;
    }
    .box_check label input[type="checkbox"] {
        background: url(../../assets/ic_check_gray.png) center no-repeat;
        background-size: cover;
        width: 16px;
        height: 13px;
        margin-right: 10px;
        transition: all .2s;
        margin-bottom: 4px;
    }

    .box_check label input[type="checkbox"]:checked {
        background: url(../../assets/ic_check_blue.png) center no-repeat;
    }

    .box_check label span {
        color: #666;
    }
    .box_check label input[type="checkbox"]:checked ~ span {
        color: #000;
    }
}