@charset "utf-8";

.PlatformPage {
    .platformLogo {
        margin-top: 20px;
        width: 450px;
    }

    .platformImg {
        margin-top: 30px;
        width: 700px;
        height: 500px;
    }

    .platformImg img {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);

    }

    // platformInfoDiv start
    .platformInfoDiv {
        text-align: center;
    }

    .platformInfoDiv p {
        font-size: 1.2rem;
    }

    .platformInfoDiv span {
        font-size: 1.25rem;
        color: #1d5d9b;
        font-weight: 600;
    }

    .platformInfoDiv h3 {
        margin-top: 40px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .platformInfoDiv .platformMenuListUl {
        margin-top: 40px;
        border-top: 2px #1d5d9b solid;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 40px;
    }

    .platformInfoDiv .platformMenuListUl li {
        width: 100%;
        border: 2px solid #1d5d9b;
        border-radius: 30px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .platformInfoDiv .platformMenuListUl li p{
        text-align: start;
        font-size: 1.1rem;
        line-height: 1.3;
    }
    .platformInfoDiv .platformMenuListUl li p:nth-child(3){
        margin-top: 10px;
    }

    .platformInfoDiv .platformMenuListUl li h4,.Titleinfo {
        clear: both;
        text-align: start;
        font-weight: 600;
        font-size: 1.3rem;
        color: #000;
    }

    .platformInfoDiv .platformMenuListDiv {
        width: 1000px;
        margin: 0 auto;
    }

    // platformInfoDiv end

    // 미디어쿼리 ~1080 시작
    @media screen and (max-width: 1080px) {
        .platformInfoDiv .platformMenuListDiv {
            width: 100%;
            margin: 0 auto;
        }

        .platformInfoDiv .platformMenuListUl li p {
            float: none;
            margin: 0 20px 10px 20px;
            clear: both;
        }
    }

    // 미디어쿼리 ~500 시작
    @media screen and (max-width: 500px) {
        .platformLogo {
            margin-top: 20px;
            width: 250px;
        }

        .platformImg {
            margin-top: 30px;
            width: 300px;
            height: 400px;
        }

        .platformImg img {
            border-radius: 6px;
            width: 100%;
            height: 350px;
        }
    }
}