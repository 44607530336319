#root,
.App {
	width: 100%;
	height: 100%;
}

.wrapper header {
	margin-bottom: 30px;
}

.wrapper a {
	margin-left: 15px;
	cursor: pointer;
}

.wrapper .selectTab {
	background-color: #808080;
}
