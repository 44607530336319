@charset "utf-8";

.DictionaryPage {
    .writeButton {
        padding: 11px;
        border: 2px #1d5d9b solid;
        width: 80px;
        height: 47px;
        border-radius: 30px;
        margin: 10px 0px 20px 0px;
        position: relative;
        margin-left: 10px;
        bottom: 3px;
        background-color: #fff;
        color: #1d5d9b;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .writeButton:hover {

        background-color: #1d5d9b;
        color: #fff;

    }

    .platformLogo {
        margin-top: 20px;
        width: 450px;
    }

    .dictionaryTitle {
        font-weight: 600;
        // font-size: 2.5rem !important;
    }

    .platformImg {
        margin-top: 30px;
    }

    .platformImg img {
        border-radius: 6px;
    }

    // dictionaryDiv start
    .dictionaryDiv {
        text-align: center;
    }

    .dictionaryDiv p {
        font-size: 1.25rem;
    }

    .dictionaryDiv span {
        font-size: 1.25rem;
        color: #1d5d9b;
        font-weight: 600;
    }

    .dictionaryDiv h3 {
        margin-top: 40px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .dictionaryDiv .dictionaryListUl {
        margin-top: 40px;
        border-top: 2px #3588db solid;
        width: 100%;
    }

    .dictionaryDiv .dictionaryListUl > li {
        // float: left;
        width: 100%;
        margin-top: 30px;
        // border-bottom: 2px solid #7c7b7b;
    }

    .dictionaryDiv .dictionaryListUl li p.menuTitle {
        font-weight: 600;
        font-size: 1.25rem;
    }

    .dictionaryDiv .dictionaryListDiv {
        width: 90%;
        margin: 0 auto;
    }
    .dictionaryListUl li .search_box{
        position: relative;
        width: 500px;
        margin: 0 auto;
    }

    .dictionaryListUl .searchInput {
        padding: 15px 10px;
        width: 500px;
        border: 2px #3588db solid;
        border-radius: 25px;
        font-size: 1rem;
    }

    .dictionaryListUl .searchButton {
        padding: 15px 20px;
        border-radius: 0px 25px 25px 0px;
        position: absolute;
        right: 0;
        background-color: #1d5d9b;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .dictionaryListUl .searchKey {
        display: flex;
        width: 100%;
        position: relative;
    }

    .dictionaryListUl .searchKey ul{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        position: relative;
        justify-content: flex-start;
    }

    .dictionaryListUl .searchKey ul li {
        position: relative;
        border: 2px #3588db solid;
        padding: 10px 0;
        font-weight: 600;
        color: #3588db;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        cursor: pointer;
        width: calc(100% / 17);
        font-size: 1.2rem;
    }

    .dictionaryListUl .searchKey li.selected {
        border: 2px #3588db solid;
        border-radius: 5px 5px 0px 0px;
        border-bottom: 0px;
    }

    .dictionaryListDiv .searchList {
        width: 100%;
        margin-top: 10px;
        height: 500px;
        overflow-y: auto;
    }

    .dictionaryListDiv .searchList li {
        display: flex;
        width: 100%;
        padding: 30px;
        border-bottom: 2px #1d5d9b solid;
    }

    .dictionaryListDiv .searchList li p {
        display: flex;
        width: 100%;
        font-weight: 400;
        text-align: start;
    }

    .dictionaryListDiv .searchList li p.name {
        display: flex;
        width: 340px;
        margin-right: 10px;
        font-size: 1.25rem;
        font-weight: 600;
        color: #1d5d9b;
    }

    // dictionaryDiv end
    // 미디어쿼리 ~1080 시작
    @media screen and (max-width: 1080px) {

        // dictionaryDiv start
        .dictionaryDiv {
            text-align: center;
            overflow-x: auto;
        }

        .dictionaryDiv .dictionaryListUl {
            margin-top: 40px;
            border-top: 2px #3588db solid;
            width: 100%;
        }

        .dictionaryListUl .searchKey ul li{
            width: 70px;
        }
    }
    // 미디어쿼리 ~1080 끝

    // 미디어쿼리 ~800 시작
    @media screen and (max-width: 800px){
        .dictionaryDiv .dictionaryListUl li{
            position: relative;
            margin-top: 20px;

        }
        .dictionaryDiv .dictionaryListUl li .search_box{
            width: 400px;
        }
        .dictionaryListUl li .searchInput{
            width: 400px;
        }
        .dictionaryListUl .searchKey li{
            padding: 5px 0;
        }
    }
    // 미디어쿼리 ~800 끝
    
    // 미디어쿼리 ~600 시작
    @media screen and (max-width: 600px) {

        // dictionaryDiv start
        .dictionaryDiv {
            text-align: center;
            overflow-x: auto;
        }

        .dictionaryDiv .dictionaryListDiv {
            width: 90%;
            margin: 0 auto;
        }

        .dictionaryDiv .dictionaryListUl {
            margin-top: 40px;
            border-top: 2px #3588db solid;
            width: 100%;
        }
        .dictionaryListDiv .dictionaryListUl li .search_box{
            width: 300px;
        }
        .dictionaryListDiv .dictionaryListUl li .search_box .searchInput{
            width: 300px;
        }
        .dictionaryListUl .searchKey {
            width: 100%;
        }

        .dictionaryDiv .dictionaryListUl li {
            // float: left;
            width: 100%;
            // border-bottom: 2px solid #7c7b7b;
        }

        .dictionaryListUl .searchKey ul li {
            width: 60px;
        }

        .dictionaryListDiv .searchList {
            width: 100%;
            margin-top: 10px;
            height: 500px;
            overflow-y: auto;
        }

        .dictionaryListDiv .searchList li{
            flex-direction: column;
            gap: 10px;
            padding: 15px;
            text-align: start;
        }
    }
    // 미디어쿼리 ~600 끝
}