@charset "utf-8";

.comm_popup .comm_popup_wrap {
	max-width: 800px;
}

/* title */
.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
	position: absolute;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
}

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
	vertical-align: baseline !important;
	transition: all 0.2s;
}

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
	transform: rotate(180deg);
}

.villageGift_wrap {
	position: relative;
	width: 100%;
}

/* exchange_inner */
.exchange_inner {
	position: relative;
	width: 100%;
}
.exchange_inner button {
	width: 100%;
	height: 60px;
	line-height: 60px;
	background-color: #eac00f;
	color: #2a1305;
	font-size: 1.25rem;
	font-weight: 600;
	border-radius: 5px;
}

/* detail_inner */
.detail_inner {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.detail_inner .img_box {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	border-radius: 5px;
	overflow: hidden;
}
.detail_inner .img_box img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	min-height: 100%;
}

.detail_inner .txt_box {
	position: relative;
	width: 100%;
}
.detail_inner .txt_box p,
.detail_inner .txt_box h3 {
	position: relative;
	font-size: 1.1rem;
}
.detail_inner .txt_box h3 {
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 5px;
}
.detail_inner .txt_box h3::before {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
}
.detail_inner .txt_box h3.time::before {
	content: '';
	background: url(../../assets/pop_gift01.png) 50% 50% no-repeat;
}
.detail_inner .txt_box h3.care::before {
	content: '';
	background: url(../../assets/pop_gift02.png) 50% 50% no-repeat;
}
.detail_inner .txt_box h3.market::before {
	content: '';
	background: url(../../assets/pop_gift03.png) 50% 50% no-repeat;
}

/* market_table */
.market_table {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.market_table .thead {
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 2px solid #555;
	border-bottom: 1px solid #dedede;
}
.market_table .thead li {
	float: left;
	position: relative;
	text-align: center;
	color: #666;
	padding-left: 10px;
	padding-right: 10px;
}

.market_table .tbody {
	width: 100%;
	display: inline-block;
}
.market_table .tbody li {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	border-bottom: 1px solid #dedede;
}
.market_table .tbody li p {
	position: relative;
	color: #000;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 1.15rem;
}

.market_table .tbody li label {
	display: flex;
	align-items: center;
	justify-content: center;
}

.market_table .tbody li label input[type='radio'] {
	width: 25px;
	height: 25px;
	padding: 0;
	border-radius: 50%;
	background-color: #fff;
	border: 1px solid #ccc;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;
}
.market_table .tbody li label input[type='radio']:checked {
	background: url(../../assets/ic_check.png) 50% 50% no-repeat;
	background-size: 20px;
	border-color: #258cef;
	background-color: #258cef;
}

/* market_table set */
.market_table .thead li:first-of-type,
.market_table .tbody li p:first-of-type {
	width: 25%;
}
.market_table .thead li:nth-of-type(2),
.market_table .tbody li p:nth-of-type(2) {
	width: 35%;
}
.market_table .thead li:nth-of-type(3),
.market_table .tbody li p:nth-of-type(3) {
	width: 40%;
}

// 미디어쿼리 ~1024 시작
@media (max-width: 1024px) {
	.comm_popup .comm_popup_wrap {
		width: 90%;
	}
}
// 미디어쿼리 ~1024 끝

// 미디어쿼리 ~600 시작
@media (max-width: 600px) {
	/* exchange_inner */
	.exchange_inner button {
		height: 50px;
		line-height: 50px;
	}

	/* market_table */
	.market_table .thead li {
		padding-left: 5px;
		padding-right: 5px;
	}
}
// 미디어쿼리 ~600 끝
