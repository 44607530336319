@charset "utf-8";
.PeopleDialog {
	/* comm_popup_title */
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all 0.2s;
	}
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	/* list_table */
	.list_table .thead {
		width: 100%;
		display: inline-block;
		border-top: 2px solid #555;
		border-bottom: 1px solid #dedede;
	}
	.list_table .thead li {
		float: left;
		position: relative;
		text-align: center;
		color: #666;
		font-weight: 600;
		width: 100%;
	}

	.list_table .tbody {
		width: 100%;
		display: inline-block;
	}
	.list_table .tbody li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		border-bottom: 1px solid #dedede;
	}
	.list_table .tbody li p {
		position: relative;
		color: #000;
		width: 100%;
		font-size: 1.15rem;
	}
}
