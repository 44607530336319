@charset "utf-8";

.Main_MyPage {
	/* 레이아웃 변경사항 */
	main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:nth-of-type(2).on > a {
		color: #aaa !important;
		font-weight: normal !important;
	}
	main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:nth-of-type(2).end > a {
		color: #000 !important;
	}
	main #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:nth-of-type(2) > a::after {
		content: '';
		display: none !important;
	}

	main #container {
		width: 1000px !important;
	}

	/* character_wrap */
	.character_wrap {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		border-radius: 15px;
		background-image: linear-gradient(135deg, #258cef, #1d5d9b 60%);
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.character_wrap p {
		color: #fff;
		font-size: 1.15rem;
		font-weight: 600;
	}
	.character_wrap button {
		background: rgba(0, 0, 0, 0.4);
		padding: 10px 20px;
		border-radius: 30px;
		color: #fff;
	}

	/* inform_wrap */
	.inform_wrap {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.inform_wrap p,
	.inform_wrap p span {
		font-size: 1.25rem;
	}
	.inform_wrap p span {
		font-weight: 600;
	}
	.inform_wrap p span.txt_blue {
		color: #258cef;
	}

	.inform_wrap ul {
		position: relative;
		width: 100%;
		border-radius: 15px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: #f5f5f5;
		gap: 30px;
	}
	.inform_wrap ul li {
		width: calc(100% / 3 - 20px);
		display: flex;
	}
	.inform_wrap ul li a {
		position: relative;
		font-size: 1.15rem;
		display: flex;
		align-items: center;
	}
	.inform_wrap ul li a::before {
		content: '';
		display: inline-block;
		width: 25px;
		height: 25px;
		margin-right: 10px;
	}
	.inform_wrap ul li:nth-of-type(1) a::before {
		content: '';
		background: url(../../assets/ic_my01.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.inform_wrap ul li:nth-of-type(2) a::before {
		content: '';
		background: url(../../assets/ic_my02.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.inform_wrap ul li:nth-of-type(3) a::before {
		content: '';
		background: url(../../assets/ic_my03.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.inform_wrap ul li:nth-of-type(4) a::before {
		content: '';
		background: url(../../assets/ic_my04.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.inform_wrap ul li:nth-of-type(5) a::before {
		content: '';
		background: url(../../assets/ic_my05.png) 50% 50% no-repeat;
		background-size: cover;
	}
	.inform_wrap ul li:nth-of-type(6) a::before {
		content: '';
		background: url(../../assets/ic_my06.png) 50% 50% no-repeat;
		background-size: cover;
	}

	/* point_wrap */
	.point_wrap {
		position: relative;
		width: 100%;
	}
	.point_wrap button {
		width: 100%;
		border-radius: 15px;
		background-color: #1d5d9b;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 30px;
	}
	.point_wrap button p {
		color: #fff;
	}
	.point_wrap button p.big,
	.point_wrap button p span {
		color: #fff;
		font-size: 1.15rem;
	}
	.point_wrap button p span {
		font-weight: 600;
	}

	/* energy_title */
	.energy_title {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.energy_title h4 {
		font-size: 1.25rem;
		font-weight: 600;
	}
	.energy_title button {
		width: auto;
		height: 32px;
		border-radius: 5px;
		border: 1px solid #1d5d9b;
		color: #1d5d9b;
		font-size: 1rem;
		padding: 0 10px;
		background: #fff;
	}

	/* energy_table */
	.energy_table {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.energy_table .thead {
		width: 100%;
		display: flex;
		align-items: center;
		border-top: 2px solid #555;
		border-bottom: 1px solid #dedede;
	}
	.energy_table .thead li {
		float: left;
		position: relative;
		text-align: center;
		color: #666;
		font-weight: 600;
		padding-left: 10px;
		padding-right: 10px;
	}

	.energy_table .tbody {
		width: 100%;
		display: inline-block;
	}
	.energy_table .tbody li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		border-bottom: 1px solid #dedede;
	}
	.energy_table .tbody li p {
		position: relative;
		color: #000;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.15rem;
	}
	.energy_table .tbody li p.red {
		color: #e6613e;
		font-weight: 500;
	}

	/* energy_table set */
	.energy_table .thead li:first-of-type,
	.energy_table .tbody li p:first-of-type {
		width: 25%;
	}
	.energy_table .thead li:nth-of-type(2),
	.energy_table .tbody li p:nth-of-type(2) {
		width: 25%;
	}
	.energy_table .thead li:nth-of-type(3),
	.energy_table .tbody li p:nth-of-type(3) {
		width: 25%;
	}
	.energy_table .thead li:nth-of-type(4),
	.energy_table .tbody li p:nth-of-type(4) {
		width: 25%;
	}

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		/* 레이아웃 변경사항 */
		main #container {
			width: 100% !important;
		}

		.inform_wrap ul {
			flex-wrap: wrap;
			gap: 30px;
		}
		.inform_wrap ul li {
			width: calc(100% / 2 - 15px);
		}
	}

	// 미디어쿼리 ~600 시작
	@media screen and (max-width: 600px) {
		.inform_wrap ul {
			flex-direction: column;
			gap: clamp(20px, 4vw, 100px);
		}
		.inform_wrap ul li {
			width: 100%;
		}
	}
}
