@charset "utf-8";

.Write_ProgramPage {

	/* 체크박스 */
	.cont-box ul {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		min-height: 50px;
		align-items: center;
		gap: 20px;
	}

	.cont-box ul li>label {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.cont-box ul li>label span {
		font-size: 1.1rem;
		font-weight: 500;
		transition: all 0.2s;
	}

	.cont-box ul li>label input[type='checkbox'] {
		background: url(../../../assets/ic_check_gray.png) center no-repeat;
		background-size: cover;
		width: 16px;
		height: 13px;
		margin-right: 5px;
		transition: all 0.2s;
	}

	.cont-box ul li>label input[type='checkbox']:checked {
		background: url(../../../assets/ic_check_blue.png) center no-repeat;
	}

	.cont-box ul li>label input[type='radio'] {
		background: url(../../../assets/ic_check_gray.png) center no-repeat;
		background-size: cover;
		width: 16px;
		height: 13px;
		margin-right: 5px;
		transition: all 0.2s;
	}

	.cont-box ul li>label input[type='radio']:checked {
		background: url(../../../assets/ic_check_blue.png) center no-repeat;
	}

	/* 분야 */
	.eduPart-box {
		position: relative;
		width: 100%;
	}

	.eduPart-box p {
		color: #666;
	}

	/* 주소 */
	.comm_write_wrap .cont-wrap input.address {
		width: calc(100% - 110px);
		margin-right: 10px;
	}

	.comm_write_wrap .cont-wrap .btn_address {
		width: 100px;
		height: 50px;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #258cef;
		color: #258cef;
		box-sizing: border-box;
		font-weight: 500;
	}

	.comm_write_wrap .cont-wrap input.detail_address {
		margin-top: 10px;
	}

	// 미디어쿼리 ~600 시작
	@media (max-width: 600px) {

		/* 체크박스 */
		.cont-box ul {
			gap: 15px;
		}

		/* 주소 */
		.comm_write_wrap .cont-wrap .btn_address {
			width: 80px;
			height: 40px;
		}

		.comm_write_wrap .cont-wrap input.address {
			width: calc(100% - 90px);
		}
	}
	// 미디어쿼리 ~600 끝
}