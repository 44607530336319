@charset "utf-8";

.RankDetailDialog{
    .comm_popup .comm_popup_wrap {
        max-width: 800px;
    }

    /* comm_popup_title */
    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
        vertical-align: baseline !important;
        transition: all .2s;
    }
    .comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
        transform: rotate(180deg);
    }

    /* rank_list */
    .rank_list ul {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .rank_list ul li {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .rank_list ul li p {
        font-size: 1.25rem;
        font-weight: 500;
    }
    .rank_list ul li p.num {
        width: 80px;
        font-size: 1.4rem;
        font-weight: 600;
        color: #1d5d9b;
        text-align: center;
    }
    .rank_list ul li p.village {
        padding: 0 20px;
        flex: 1 0;
        flex-basis: 0;
    }

    .rank_list ul li p span {
        display: block;
        width: 100%;
        color: #666;
    }

    // 미디어쿼리 ~1024 시작
    @media (max-width: 1024px) {

            .comm_popup .comm_popup_wrap {
                width: 90%;
            }

    }
    // 미디어쿼리 ~1024 끝

    // 미디어쿼리 ~600 시작
    @media (max-width: 600px) {

            .rank_list ul li p.num {
                width: 50px;
            }

    }
    // 미디어쿼리 ~600 끝

}