@charset "utf-8";

.missionDialog {
	span.explain {
		color: #666;
		font-size: 1rem !important;
	}

	::-webkit-scrollbar {
		height: 6px;
	}

	/* title */
	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
		vertical-align: baseline !important;
		transition: all 0.2s;
	}

	.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
		transform: rotate(180deg);
	}

	/* confirm_box */
	.confirm_box {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
	}
	.confirm_box textarea {
		width: 100%;
		height: 300px;
		background: #fff;
		box-sizing: border-box;
		color: #000;
		border: 1px solid #e8e8e8;
		resize: none;
		padding: 20px;
		border-radius: 5px;
		font-size: inherit;
	}

	/* 첨부 이미지 */
	.confirm_box .imgBox {
		width: 120px;
		height: 120px;
		margin-right: 15px;
	}

	.confirm_box .imgBox .upload-photo {
		display: none;
	}

	.confirm_box .imgBox input[type='file'] {
		position: absolute;
		width: 0;
		height: 0;
		padding: 0;
		overflow: hidden;
		border: 0;
	}

	.confirm_box .imgBox label {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		color: #999;
		vertical-align: middle;
		background-color: #fff;
		cursor: pointer;
		height: 120px;
		width: 120px;
		border: 2px dashed #e8e8e8;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		border-radius: 5px;
	}

	.confirm_box .imgBox label img {
		margin-bottom: 5px;
	}
	.confirm_box .imgBox label span {
		color: #999;
		font-size: 0.8rem;
	}

	.confirm_box .imglistBox {
		width: calc(100% - 135px);
	}
	.confirm_box .imglistBox ul {
		width: 100%;
		height: 100%;
		font-size: 0;
		box-sizing: border-box;
		overflow-x: auto;
		overflow-y: hidden;
		background: #fff;
		white-space: nowrap;
	}
	.confirm_box .imglistBox ul li {
		position: relative;
		box-sizing: border-box;
		width: 120px;
		height: 120px;
		display: inline-block;
		border: 1px solid #e8e8e8;
		overflow: hidden;
		border-radius: 5px;
	}
	.confirm_box .imglistBox ul li:not(:first-child) {
		margin-left: 15px;
	}

	.confirm_box .imglistBox ul li img {
		width: 100%;
		min-height: 100%;
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.confirm_box .imglistBox ul li button {
		position: absolute;
		box-sizing: border-box;
		width: 12px;
		height: 12px;
		background: url(../../assets/ic_close.png) center no-repeat;
		top: 12px;
		right: 12px;
	}

	/* comm_write_wrap */
	.comm_write_wrap {
		border: none;
	}
	.comm_write_wrap .cont-wrap {
		border: none;
		padding: 0;
	}
	.comm_write_wrap .cont-wrap:not(:last-child) {
		margin-bottom: 20px;
	}

	.comm_write_wrap .cont-wrap h3 {
		width: 100%;
		padding-top: 0;
		padding-bottom: 10px;
		height: inherit;
	}
	.comm_write_wrap .cont-wrap .cont-box {
		width: 100%;
	}

	.comm_write_wrap .cont-box .select-box select,
	.comm_write_wrap .cont-wrap input[type='text'] {
		height: 40px;
		line-height: 40px;
	}
	.comm_write_wrap .cont-box .select-box {
		gap: 15px;
	}
	.comm_write_wrap .cont-box .select-box select {
		width: 100%;
		padding: 0 30px 0 10px;
		background: url(../../assets/ic_select.png) right 10px center no-repeat;
	}

	.comm_write_wrap .cont-wrap input[type='text'],
	.comm_write_wrap .cont-wrap textarea {
		padding: 10px;
	}
}
