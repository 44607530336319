@charset "utf-8";
.EnergyStatusPage {
	.graph_updateBtn {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.graph_updateBtn button {
		background-color: #1d5d9b;
		height: 50px;
		padding: 0 20px;
		border-radius: 5px;
		font-size: 1.25rem;
		color: #fff;
		margin-left: auto;
	}

	.energyStatus {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 40px;
	}

	/* energyStatus_map */
	.energyStatus_map {
		position: relative;
		width: 400px;
	}
	.energyStatus_map .map_img {
		position: relative;
		width: 100%;
	}
	.energyStatus_map .map_img img {
		display: block;
		width: 100%;
	}

	.energyStatus_map .map_img p {
		position: absolute;
		transform: translate(-50%, -50%);
	}
	.energyStatus_map .map_img p:nth-of-type(1) {
		left: calc(50% - 80px);
		top: calc(50% - 30px);
	}
	.energyStatus_map .map_img p:nth-of-type(2) {
		left: calc(50% + 15px);
		top: calc(50% + 0px);
	}
	.energyStatus_map .map_img p:nth-of-type(3) {
		left: calc(50% + 70px);
		top: calc(50% - 70px);
	}
	.energyStatus_map .map_img p:nth-of-type(4) {
		left: calc(50% + 20px);
		top: calc(50% + 75px);
	}
	.energyStatus_map .map_img p:nth-of-type(5) {
		left: calc(50% + 115px);
		top: calc(50% + 30px);
	}
	.energyStatus_map .map_img p:nth-of-type(6) {
		position: relative;
		display: block;
		left: inherit;
		top: inherit;
		transform: inherit;
		width: 100%;
		margin-top: 10px;
		text-align: end;
	}

	.energyStatus_map .map_img p input[type='radio'] {
		display: none;
	}
	.energyStatus_map .map_img p input[type='radio'] ~ label {
		position: relative;
		display: block;
		width: 70px;
		height: 70px;
		line-height: 70px;
		border-radius: 50%;
		text-align: center;
		background-color: #999;
		cursor: pointer;
		color: #fff;
	}
	.energyStatus_map .map_img p input[type='radio']:checked ~ label {
		background-color: #258cef;
		font-weight: 500;
	}

	.energyStatus_map .map_img p:nth-of-type(6) input[type='radio'] ~ label {
		width: inherit;
		height: inherit;
		line-height: inherit;
		border-radius: inherit;
		background-color: inherit;
		color: #666;
		font-size: 1rem;
		text-align: end;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.energyStatus_map .map_img p:nth-of-type(6) input[type='radio'] ~ label span {
		display: inline-block;
		width: 25px;
		height: 25px;
		border-radius: 5px;
		background: url(../../assets/ic_check.png) 50% 50% no-repeat;
		background-color: #dedede;
		margin-left: 10px;
	}
	.energyStatus_map
		.map_img
		p:nth-of-type(6)
		input[type='radio']:checked
		~ label {
		color: #000;
		font-weight: 500;
	}
	.energyStatus_map
		.map_img
		p:nth-of-type(6)
		input[type='radio']:checked
		~ label
		span {
		background-color: #258cef;
	}

	.energyStatus_map .map_select {
		position: relative;
		width: 100%;
	}
	.energyStatus_map .map_select select {
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		line-height: 50px;
		color: #000;
		border: 1px solid #e8e8e8;
		resize: none;
		padding: 0 40px 0 20px;
		border-radius: 5px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		background: url(../../assets/ic_select.png) right 20px center no-repeat;
		font-size: 1rem;
	}

	/* energyStatus_graph */
	.energyStatus_graph {
		position: relative;
	}

	.energyStatus_graph .graph_circle {
		position: relative;
		width: calc(100% - 440px);
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 25px;
	}
	.energyStatus_graph .graph_circle .box_circle {
		width: 100%;
		border: 1px solid #e8e8e8;
		border-radius: 10px;
	}
	.energyStatus_graph .graph_circle .box_circle h3 {
		width: 100%;
		font-size: 1.25rem;
		font-weight: 600;
	}
	.energyStatus_graph .graph_circle .box_circle > div {
		display: flex;
		justify-content: center;
	}
	.energyStatus_graph .graph_circle .box_circle > .mt10 div {
		position: absolute;
		right: 30px;
		bottom: 5px;
	}

	.energyStatus_graph .graph_table {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.energyStatus_graph .graph_table span {
		color: #666;
	}

	.energyStatus_graph .graph_table .thead {
		width: 100%;
		display: inline-block;
		border-top: 2px solid #555;
		border-bottom: 1px solid #dedede;
	}
	.energyStatus_graph .graph_table .thead li {
		float: left;
		position: relative;
		text-align: center;
		color: #666;
		padding-left: 10px;
		padding-right: 10px;
	}

	.energyStatus_graph .graph_table .tbody {
		width: 100%;
		display: inline-block;
	}
	.energyStatus_graph .graph_table .tbody li {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		border-bottom: 1px solid #dedede;
	}
	.energyStatus_graph .graph_table .tbody li a {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.energyStatus_graph .graph_table .tbody li p {
		position: relative;
		color: #000;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 1.15rem;
		font-weight: 500;
	}
	.energyStatus_graph .graph_table .tbody li p.red {
		color: #ed3d3d;
	}
	.energyStatus_graph .graph_table .tbody li p.blue {
		color: #258cef;
	}

	/* graph_table set */
	.energyStatus_graph .graph_table .thead li:first-of-type,
	.energyStatus_graph .graph_table .tbody li p:first-of-type {
		width: 24%;
	}
	.energyStatus_graph .graph_table .thead li:nth-of-type(2),
	.energyStatus_graph .graph_table .tbody li p:nth-of-type(2) {
		width: 19%;
	}
	.energyStatus_graph .graph_table .thead li:nth-of-type(3),
	.energyStatus_graph .graph_table .tbody li p:nth-of-type(3) {
		width: 19%;
	}
	.energyStatus_graph .graph_table .thead li:nth-of-type(4),
	.energyStatus_graph .graph_table .tbody li p:nth-of-type(4) {
		width: 19%;
	}
	.energyStatus_graph .graph_table .thead li:nth-of-type(5),
	.energyStatus_graph .graph_table .tbody li p:nth-of-type(5) {
		width: 19%;
	}

	/*미디어쿼리 ~1400 시작*/
	@media screen and (max-width: 1400px){
		.energyStatus{
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.energyStatus_graph {
			flex-direction: column;
			gap: 25px;
			width: 100%;
		}
		.energyStatus_graph .graph_circle{
			width: 100%;
		}
		.graph_circle .box_circle{
			width: 100%;
		}
	}
	// 미디어쿼리 ~1400 끝

	// 미디어쿼리 ~1024 시작
	@media screen and (max-width: 1024px) {
		.energyStatus {
			flex-direction: column;
		}

		.energyStatus_map,
		.energyStatus_graph {
			width: 100%;
		}
		.energyStatus_map .map_img img {
			width: 400px;
			margin: 0 auto;
		}
	}
	// 미디어쿼리 ~1024 끝

	/*미디어쿼리 ~980 시작*/
	@media screen and (max-width: 980px) {
		.graph_circle {
			position: relative;
			width: 100%;
			display: flex;
			flex-direction: column;
		}
		.graph_circle .box_circle{
			width: 100%;
		}
	}
	// 미디어쿼리 ~980 끝

	/*미디어쿼리 ~600 시작*/
	@media screen and (max-width: 600px) {
		.graph_updateBtn {
			display: none;
		}

		.energyStatus {
			gap: clamp(25px, 5vw, 100px);
		}

		.energyStatus_graph .graph_circle {
			flex-direction: column;
			gap: clamp(15px, 3vw, 100px);
		}
		.energyStatus_graph .graph_circle .box_circle {
			width: 100%;
		}
	}
	// 미디어쿼리 ~600 끝

	/*미디어쿼리 ~450 시작*/
	@media screen and (max-width: 450px) {
		.energyStatus_map .map_img img {
			width: 300px;
		}

		.energyStatus_map .map_img p input[type='radio'] ~ label {
			width: 60px;
			height: 60px;
			line-height: 60px;
		}

		.energyStatus_map .map_img p:nth-of-type(1) {
			left: calc(50% - 60px);
			top: calc(50% - 30px);
		}
		.energyStatus_map .map_img p:nth-of-type(2) {
			left: calc(50% + 10px);
			top: calc(50% - 5px);
		}
		.energyStatus_map .map_img p:nth-of-type(3) {
			left: calc(50% + 50px);
			top: calc(50% - 60px);
		}
		.energyStatus_map .map_img p:nth-of-type(4) {
			left: calc(50% + 20px);
			top: calc(50% + 55px);
		}
		.energyStatus_map .map_img p:nth-of-type(5) {
			left: calc(50% + 85px);
			top: calc(50% + 15px);
		}
	}
	// 미디어쿼리 ~450 끝
}
