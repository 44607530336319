@charset "utf-8";

.Write_VillageMissionPage {

    span.explain {
        color: #ed3d3d;
        margin-top: 10px;
    }


    .comm_write_wrap .cont-wrap .cont-box {
        min-height: 50px;
        align-items: center;
    }

    .comm_write_wrap .cont-box .select-box {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .village_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 15px;
    }

    .village_list li label {
        display: flex;
        align-items: center;
    }

    .village_list li label input[type=radio] {
        width: 25px;
        height: 25px;
        padding: 0;
        margin-right: 5px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #e8e8e8;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;
    }

    .village_list li label input[type=radio]:checked {
        border: 8px solid #258cef;
    }

    .village_list li label p {
        color: #666666;
        font-size: 0.9rem;
    }

    /* 파일 */
    .fileBox,
    .filelistBox {
        position: relative;
        width: 100%;
    }
    .fileBox label {
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;
        text-align: center;
        border: 1px solid #258cef;
        color: #258cef;
        background-color: #fff;
        font-weight: 600;
        border-radius: 5px;
    }
    .fileBox input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }

    /* 파일리스트 */
    .filelistBox {
        position: relative;
        width: 100%;
    }
    .filelistBox ul {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .filelistBox ul li {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding: 20px 0;
    }

    .filelistBox ul li p {
        padding: 0 10px;
        width: 100px;
        text-align: center;
    }
    .filelistBox ul li p:nth-of-type(1) {
        width: calc(100% - 100px);
        text-align: start;
    }
    .filelistBox ul li p.delet button {
        padding: 5px 15px;
        border-radius: 5px;
        border: 1px solid #ed3d3d;
        font-size: 0.9rem;
        font-weight: 600;
        background-color: #fff;
        margin: 0 3px;
        color: #ed3d3d;
    }
}