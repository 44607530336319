@charset "utf-8";
.MyAlarm{
    /* 레이아웃 변경사항 */
    #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:nth-of-type(2).on > a {
        color: #aaa;
        font-weight: normal;
    }
    #lnb .lnb_menu .lnb_dep1 .lnb_dep1_li:nth-of-type(2) > a::after {
        content: '';
        display: none;
    }
    #lnb .lnb_menu {
        display: flex !important;
    }
    
    
    /* 삭제버튼 */
    .myAlarm {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .myAlarm button.btn_ma_delet {
        position: relative;
        margin-left: auto;
        background-color: #fff;
        display: flex;
        align-items: center;
    }
    .myAlarm button.btn_ma_delet::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url(../../assets/ic_delet.png)50% 50% no-repeat;
        margin-right: 7px;
    }
    
    
    /* myAlarm_list */
    .myAlarm_list {
        position: relative;
        width: 100%;
    }
    .myAlarm_list ul {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    
    /* box_myAlarm */
    .box_myAlarm {
        position: relative;
        width: 100%;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
    }
    .box_myAlarm p {
        font-size: 1.15rem;
    }
    
    .box_myAlarm .ma_title {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        font-weight: 600;
        color: #000;
    }
    .box_myAlarm .ma_title h3 {
        color: #666;
    }
    
    .box_myAlarm .ma_title .alarm_delet {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .box_myAlarm .ma_title .alarm_delet img {
        vertical-align: baseline !important;
        transition: all .2s;
    }
    
    .box_myAlarm .ma_title .alarm_delet:hover img {
        transform: rotate(180deg);
    }
    
    /* 미디어쿼리 1024 */
    @media (max-width: 1024px){
    
        .myAlarm_list ul {
            gap: clamp(15px, 3vw, 20px);
        }
    
    }
     
}