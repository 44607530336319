@charset "utf-8";
.Write02_CenterInroPage {
	/* 주소 */
	.comm_write_wrap .cont-wrap input.address {
		width: calc(100% - 110px);
		margin-right: 10px;
	}
	.comm_write_wrap .cont-wrap .btn_address {
		width: 100px;
		height: 50px;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #258cef;
		color: #258cef;
		box-sizing: border-box;
		font-weight: 500;
	}
	.comm_write_wrap .cont-wrap input.detail_address {
		margin-top: 10px;
	}

	// 미디어쿼리 ~600 시작
	@media (max-width: 600px) {
		/* 주소 */
		.comm_write_wrap .cont-wrap .btn_address {
			width: 80px;
			height: 40px;
		}
		.comm_write_wrap .cont-wrap input.address {
			width: calc(100% - 90px);
		}
	}
	// 미디어쿼리 ~600 끝
}
