@charset "utf-8";

.comm_popup .comm_popup_wrap {
	max-width: 800px;
}

/* title */
.comm_popup .comm_popup_wrap .comm_popup_title .popup_close {
	position: absolute;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
}

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close img {
	vertical-align: baseline !important;
	transition: all 0.2s;
}

.comm_popup .comm_popup_wrap .comm_popup_title .popup_close:hover img {
	transform: rotate(180deg);
}

/* nickname_talbe */
.nickname_talbe {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.nickname_talbe .thead {
	width: 100%;
	display: flex;
	align-items: center;
	border-top: 2px solid #555;
	border-bottom: 1px solid #dedede;
}
.nickname_talbe .thead li {
	float: left;
	position: relative;
	text-align: center;
	color: #666;
	padding-left: 10px;
	padding-right: 10px;
}

.nickname_talbe .tbody {
	width: 100%;
	display: inline-block;
}
.nickname_talbe .tbody li {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	border-bottom: 1px solid #dedede;
}
.nickname_talbe .tbody li p {
	position: relative;
	color: #000;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
}

.nickname_talbe .tbody li label {
	display: flex;
	align-items: center;
	justify-content: center;
}

.nickname_talbe .tbody li label input[type='radio'] {
	width: 25px;
	height: 25px;
	padding: 0;
	border-radius: 50%;
	background-color: #fff;
	border: 1px solid #ccc;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;
}
.nickname_talbe .tbody li label input[type='radio']:checked {
	background: url(../../assets/ic_check.png) 50% 50% no-repeat;
	background-size: 20px;
	border-color: #258cef;
	background-color: #258cef;
}
.comm_search_plus {
	width: 100px;
}
.comm_search button {
	margin-left: 0;
}

/* nickname_talbe set */
.nickname_talbe .thead li:first-of-type,
.nickname_talbe .tbody li p:first-of-type {
	width: 30%;
}
.nickname_talbe .thead li:nth-of-type(2),
.nickname_talbe .tbody li p:nth-of-type(2) {
	width: 70%;
}

// 미디어쿼리 ~1024 시작
@media (max-width: 1024px) {
	.comm_popup .comm_popup_wrap {
		width: 90%;
	}

	.comm_search .comm_search_plus {
		width: calc(100% - 55px);
	}
	.comm_search .comm_search_plus input {
		width: 100%;
	}
}
// 미디어쿼리 ~1024 끝

// 미디어쿼리 ~600 시작
@media (max-width: 600px) {
	.nickname_talbe .thead li {
		padding-left: 5px;
		padding-right: 5px;
	}
}
// 미디어쿼리 ~600 끝
